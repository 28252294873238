import findLastIndex from 'lodash/findLastIndex';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';

import { Helpers } from '../core/src/helpers';
import { getPromotionMapKey, _configuratorStart, getGlobalPromotions, trackCustomMetric } from './track-params';

import globalAnalytics from './templates/global';
import categoryPageAnalytics from './templates/cat';
import homePageAnalytics from './templates/home';
import vwaPageAnalytics from './templates/vwa';
import configureviewPageAnalytics from './templates/configure';
import pdpAnalytics from './templates/pdp';
import mlpAnalytics from './templates/mlp';
import srpAnalytics from './templates/srp';
import mdpAnalytics from './templates/mdp';
import slpAnalytics from './templates/slp';
import dlpAnalytics from './templates/dlp';
import prpAnalytics from './templates/prp';

export const getGTMClickAttributes = (category, id, value, action) => {
    return {
        ...(category ? { 'data-gtm-category': category } : {}),
        ...(id ? { 'data-gtm-id': id } : {}),
        ...(value ? { 'data-gtm-value': formatMetricValue(value) } : {}),
        ...(action ? { 'data-gtm-action': action } : {}),
    };
};

export const validMetricValues = {
    pageNameL6: product_type => {
        try {
            switch (true) {
                case /laptops/i.test(product_type):
                    return 'laptops';
                case /desktops/i.test(product_type):
                    return 'desktops';
                case /displays/i.test(product_type):
                    return 'displays';
                case /accessories/i.test(product_type):
                    return 'accessories';
                case /printers/i.test(product_type):
                    return 'printers';
                case /supplies/i.test(product_type):
                    return 'supplies';
                case /services/i.test(product_type):
                    return 'services';
                case /solutions/i.test(product_type):
                    return 'solutions';
                case /corp/i.test(product_type):
                    return 'corp';
                default:
                    return 'shared';
            }
        } catch (e) {}

        return 'shared';
    },
};

export const addPromotionsMap = (key, value) => {
    try {
        window.promotionsMap = window.promotionsMap || {};
        window.promotionsMap[key] = value;
    } catch (e) {}
};

export const addAnalyticMap = (key, value, mapName) => {
    if (!mapName) return;
    try {
        window[mapName] = window[mapName] || {};
        window[mapName][key] = value;
    } catch (e) {}
};

let trackedProductImpressions = {};
let trackedPromotionImpressions = {};

let trackImpressionsFunc = type => () => {
    try{
        const { dispatch, getState } = window.store;
        const { slugInfo, metrics, siteConfig } = getState();
        const { enableProductImpressions } = siteConfig || {};
        const { vanityUrl } = slugInfo || {};
        const { pageView } = metrics || {};
        const { vanityUrl: pageViewVanityUrl } = pageView || {};
        if(!enableProductImpressions || !vanityUrl || vanityUrl !== pageViewVanityUrl) return;

        let isPromotionImpression = type === 'promotions';
        let mapKey = isPromotionImpression ? 'promotionsMap' : 'productMap'
        let eventName = isPromotionImpression ? 'promotionImpression' : 'productImpression';
        let dataLayerEventParamName = isPromotionImpression ? 'promoChanged' : 'productsChanged';
        let tracker = isPromotionImpression ? trackedPromotionImpressions : trackedProductImpressions;

        const untrackedKeys = Object.keys(window.productMap).reduce((untrackedImpressionKeys, key) => {
            // don't track impressions for cart products
            if(!tracker[key] && !!window[mapKey][key].price){
                tracker[key] = 1;
                untrackedImpressionKeys.push(key);
                window[isPromotionImpression ? 'trackedPromotionImpressions' : 'trackedProductImpressions'] = tracker;
            }

            return untrackedImpressionKeys;
        }, [])
        untrackedKeys.length && dispatch(trackCustomMetric(eventName, { [dataLayerEventParamName]: untrackedKeys }))
    }catch(e){}
}

export const debouncedTrackProductImpression = debounce(trackImpressionsFunc('products'), 1000);
export const debouncedTrackPromotionImpression = debounce(trackImpressionsFunc('promotions'), 1000);

export const trackPromotionImpressions = debounce((ids, dispatch, trackCustomMetric) => {
    try{
        let validIds = ids.filter(id => window.promotionsMap[id] && !trackedPromotionImpressions[id]);
        if(validIds && validIds.length){
            validIds.forEach(validId => {
                trackedPromotionImpressions[validId] = 1;
                window.trackedPromotionImpressions[validId] = 1;
            })
            dispatch(trackCustomMetric('promotionImpression', { promoChanged: validIds }));
        }
    }catch(e){}
});

export const trackCartQuantityChange = (quantityChange, productIndex, dispatch) => {
    try{
        if(typeof quantityChange === 'number' && productIndex) {
            dispatch(trackCustomMetric('standards.miniCartQuantityChange', { productIndex, quantityChange }))
        }
    }catch(e){}
}
export const getAllTrackedKeys = mapKey => (
    Object.keys(window[mapKey]).reduce((all, key) => {
        all[key] = 1;
        return all;
    }, {})
)

export const trackAllProductImpressions = () => {
    try{
        trackedProductImpressions = getAllTrackedKeys('productMap')
        window.trackedProductImpressions = trackedProductImpressions;
    }catch(e){}
}

export const trackAllPromotionImpressions = () => {
    try{
        trackedPromotionImpressions = getAllTrackedKeys('promotionMap')
        window.trackedPromotionImpressions = trackedPromotionImpressions;
    }catch(e){}
}

export const trackImpressionKeys = (keys, type) => {
    try{
        let isPromo = type === 'promotions';
        let tracker = isPromo ? trackedPromotionImpressions : trackedProductImpressions;
        let trackerName = isPromo ? 'trackedPromotionImpressions' : 'trackedProductImpressions';
        keys.forEach(key => {
            tracker[key] = 1;
            window[trackerName][key] = 1;
        });
    }catch(e){}
}

export const addProductMap = (key, value, options) => {
    try{
        let { oldGtmId } = options || {};
        if(!window.productMap[key] && !!value.price){
            addAnalyticMap(key, value, 'productMap');

            if(oldGtmId){
                trackImpressionKeys([key], 'products')
            }
        }
    }catch(e){}
};

export const addAccessoryMap = (key, value) => {
    addAnalyticMap(key, value, 'accessoryMap');
};

export const getProductMap = key => {
    try {
        return window.productMap[key];
    } catch (e) {}
};

export const getDerivedProductMetrics = product => {
    try {
        let { attributes } = product;
        let { facet_subbrand, pm_producttype, subbrandname, plcode: category, 3211896: tppBrand } = attributes || {};

        // get brand name
        let brand = facet_subbrand || subbrandname || tppBrand || pm_producttype;

        return {
            brand,
            category: `${category}:${brand}`,
        };
    } catch (e) {}

    return {};
};

// used for brand in product impressions/clicks/add-to-cart, and family/pageNameL7 on page views
export const getValidProductBrandMetricValue = (
    facet_subbrand,
    name,
    pm_producttype,
    isNonPdpOrProduct,
    product_type
) => {
    facet_subbrand = typeof facet_subbrand === 'string' ? facet_subbrand : '';
    name = typeof name === 'string' ? name : '';
    pm_producttype = typeof pm_producttype === 'string' ? pm_producttype : '';
    let isNotExcludedProductType =
        !/(Services|Solutions|Printers and Multifunction|Software|Ink\/Toner\/Paper\/Printer Supplies)/i.test(
            pm_producttype
        );
    let isNonPrinterSuppliesProductType =
        !/(Services|Solutions|Laptops and Hybrids|Software|Desktops|Workstations|Tablets|Monitors)/i.test(
            pm_producttype
        );

    try {
        if (
            /Hybrid Work Solutions|Accessories/i.test(pm_producttype) &&
            (/^poly$/i.test(facet_subbrand) || /poly /i.test(name))
        ) {
            return 'poly';
        }
        if (isNotExcludedProductType && (/elite/i.test(facet_subbrand) || /elite/i.test(name))) {
            return 'elite';
        }
        if (isNotExcludedProductType && (/pavilion/i.test(facet_subbrand) || /pavilion/i.test(name))) {
            return 'pavilion';
        }
        if (isNotExcludedProductType && (/hyperx/i.test(facet_subbrand) || /hyperx/i.test(name))) {
            return 'hyperx';
        }
        if (isNotExcludedProductType && (/victus/i.test(facet_subbrand) || /victus/i.test(name))) {
            return 'victus';
        }
        if (isNotExcludedProductType && (/( |^)omen/i.test(facet_subbrand) || /( |^)omen/i.test(name))) {
            return 'omen';
        }
        if (
            (typeof pm_producttype !== 'string' || !/(Services|Solutions|Software)/.test(pm_producttype)) &&
            (/envy/i.test(facet_subbrand) || /envy/i.test(name))
        ) {
            return 'envy';
        }
        if (isNotExcludedProductType && (/spectre/i.test(facet_subbrand) || /spectre/i.test(name))) {
            return 'spectre';
        }
        if (isNotExcludedProductType && (/Compaq/i.test(facet_subbrand) || /Compaq/i.test(name))) {
            return 'compaq';
        }
        if (
            isNotExcludedProductType &&
            (/(thin client|^Zero$|Zero client)/i.test(facet_subbrand) || /thin client/i.test(name))
        ) {
            return 'thin-clients';
        }
        if (
            isNotExcludedProductType &&
            (/((^| )Pro($|;)|ProBook|ProDesk|ProDisplays)/i.test(facet_subbrand) ||
                /(probook|prodesk|proone|prodisplay|pro x2)/i.test(name))
        ) {
            return 'pro';
        }
        if (
            isNotExcludedProductType &&
            (/(^Z$|Z Displays|ZBook|ZDesk)/i.test(facet_subbrand) ||
                /(z display|z vr|zbook|zdesk|dreamcolor|hp z[0-9])/i.test(name))
        ) {
            return 'z';
        }
        if (isNotExcludedProductType && (/chrome/i.test(facet_subbrand) || /chrome/i.test(name))) {
            return 'chrome';
        }
        if (isNonPrinterSuppliesProductType && (/samsung/i.test(name) || /samsung/i.test(facet_subbrand))) {
            return 'samsung';
        }
        if (isNonPrinterSuppliesProductType && (/pagewide/i.test(name) || /pagewide/i.test(facet_subbrand))) {
            return 'pagewide';
        }
        if (isNonPrinterSuppliesProductType && (/deskjet/i.test(name) || /deskjet/i.test(facet_subbrand))) {
            return 'deskjet';
        }
        if (isNonPrinterSuppliesProductType && (/laserjet/i.test(name) || /laserjet/i.test(facet_subbrand))) {
            return 'laserjet';
        }
        if (isNonPrinterSuppliesProductType && (/officejet/i.test(name) || /officejet/i.test(facet_subbrand))) {
            return 'officejet';
        }
        // inkjet is no longer needed
        if (isNonPrinterSuppliesProductType && (/designjet/i.test(name) || /designjet/i.test(facet_subbrand))) {
            return 'designjet';
        }
        if (isNonPrinterSuppliesProductType && (/neverstop/i.test(name) || /neverstop/i.test(facet_subbrand))) {
            return 'neverstop';
        }
        if (isNonPrinterSuppliesProductType && facet_subbrand.toLowerCase() === 'tank') {
            return 'tank';
        }
        if (isNonPrinterSuppliesProductType && (/tango/i.test(name) || /tango/i.test(facet_subbrand))) {
            return 'tango';
        }
        if (/(essential|value|^HP$|series)/i.test(facet_subbrand)) {
            return 'essential';
        }
        if (/^value/i.test(facet_subbrand)) {
            return 'value';
        }
        // if product_type is '3PP', use '3pp'
        if (product_type === '3PP') {
            return '3pp';
        }
    } catch (e) {}

    // if this is not being used for a PDP or a product (Ex: VWAs and search page views) and none of above rules apply, use 'shared'
    if (isNonPdpOrProduct) {
        return 'shared';
    }

    // if none of the above rules apply and this is being used for a PDP or a product, use the facet_subbrand (lowercased and all special characters and spaces replaced with hyphens '-')
    return facet_subbrand && facet_subbrand.length > 0
        ? formatMetricValue(facet_subbrand) //lowercase, replace all special characters and spaces with hyphens '-'
        : undefined; // return nothing if facet_subbrand is missing and none of rules above applies
};

export const getBusinessUnit = product_type => {
    if (/laptops|desktops|displays|accessories/i.test(product_type)) {
        return 'ps';
    }

    if (/printers|supplies|scanners/i.test(product_type)) {
        return 'print';
    }

    // if(/services|solutions/i.test(product_type)){
    //     return 'shared'
    // }

    return 'shared';
};

// used for category in product impressions/clicks/add-to-cart, and product_type/pageNameL6 on page views
export const getValidProductCategoryMetricValue = (pm_producttype, isNonPdpOrProduct) => {
    try {
        if (/supplies|consumable|(^| )ink($| )|toner|paper/i.test(pm_producttype)) {
            return 'supplies';
        } else if (/scanner/i.test(pm_producttype)) {
            return 'scanners';
        } else if (/printer/i.test(pm_producttype)) {
            return 'printers';
        } else if (/calculator|accessories|hyperx/i.test(pm_producttype)) {
            return 'accessories';
        } else if (/Monitor|display/i.test(pm_producttype)) {
            return 'displays';
        } else if (/desktop/i.test(pm_producttype)) {
            return 'desktops';
        } else if (/services|Configurable Modules/i.test(pm_producttype)) {
            return 'services';
        } else if (/(^solutions$)|Point of Sale Systems|Industries|Software/i.test(pm_producttype)) {
            return 'solutions';
        } else if (/(laptop|zbook|mobile thin client|tablets)/i.test(pm_producttype)) {
            return 'laptops';
        } else if (
            /entertainment|projector|References|Gaming Systems|Cameras and Photo Studios|Options/i.test(pm_producttype)
        ) {
            return 'other';
        }
    } catch (e) {}

    // if above rules don't apply for the page, and this is not being used for a PDP or a product, use shared
    if (isNonPdpOrProduct) {
        return 'shared';
    }

    // if using this function for a PDP or a product and none of the rules above applies, use the formatted pm_producttype (lowercased, replace all spaces and special characeters with hyphen)
    const formattedCategory = formatProductMetricValue(pm_producttype); // lowercase and replace all spaces and special characters with hyphen
    return typeof formattedCategory === 'string' ? formattedCategory : undefined; // return nothing if none of above rules apply and pm_producttype is missing.
};

export const encodeProductImpressionMetric = metricValue =>
    typeof metricValue === 'string' ? metricValue.replace(/\//g, '-') : '';

export const getDerivedProductMetricsV1 = (product, prices, config, isNonPdpOrProduct) => {
    let {
        pm_producttype,
        pm_category,
        plcode,
        facet_formfactor,
        form_factor,
        facet_subbrand,
        sku,
        name,
        title,
        attributes,
    } = product || {};
    let {
        pm_producttype: attrPmProductType,
        pm_category: attrPmCategory,
        plcode: attrPlCode,
        form_factor: attrFormFactor,
        facet_subbrand: attrFacetSubbrand,
        facet_formfactor: attrFacetFormFactor,
    } = attributes || {};
    let price = prices && (prices.regularPrice || prices.salePrice) ? prices : prices && prices[sku];
    let { facet_subbrand: productBrand, plcode: productPlcode } = price || {};
    let productPrice = price && (price.salePrice || price.regularPrice);
    let encodedFormFactor = formatProductMetricValue(
        facet_formfactor || form_factor || attrFacetFormFactor || attrFormFactor
    );
    let variantStart = typeof sku === 'string' && sku.indexOf('#');
    let { list, position, xsellMethod, xsellProdInfo, disableBrand, hsResID } = config || {};

    let metricsPlcode = plcode || productPlcode || attrPlCode;
    let metricsBrand = facet_subbrand || productBrand || attrFacetSubbrand;
    let metricsPmProductType = pm_producttype || attrPmProductType;
    let metricsPmCategory = pm_category || attrPmCategory;
    let metricsName = name || title;

    return {
        ...(!disableBrand
            ? {
                  brand: getValidProductBrandMetricValue(
                      metricsBrand,
                      metricsName,
                      metricsPmProductType,
                      isNonPdpOrProduct
                  ),
              }
            : {}),
        category: `${metricsPlcode ? formatProductMetricValue(metricsPlcode) : ''}/${
            metricsPmProductType ? getValidProductCategoryMetricValue(metricsPmProductType) : ''
        }/${metricsPmCategory ? formatProductMetricValue(metricsPmCategory) : ''}${
            encodedFormFactor && encodedFormFactor.length > 0 ? '/' + encodedFormFactor : ''
        }`,
        variant: typeof variantStart === 'number' && variantStart > -1 ? sku.substring(variantStart) : '',
        id: sku,
        name: metricsName,
        ...(productPrice ? { price: productPrice + '' } : { price: '' }),
        ...(list ? { list } : {}),
        ...(position ? { position: position + '' } : { position: '' }),
        ...(xsellMethod ? { xsellMethod } : {}),
        ...(xsellProdInfo ? { xsellProdInfo } : {}),
        ...(hsResID ? { hsResID } : {}),
    };
};

export const getDerivedProductMetricsV2 = (product, prices, config, isNonPdpOrProduct) => {
    let {
        pm_producttype,
        pm_category,
        plcode,
        facet_formfactor,
        form_factor,
        facet_subbrand,
        sku,
        pNum,
        name,
        title,
        attributes,
    } = product || {};
    let metricSku = sku || pNum;
    let {
        pm_producttype: attrPmProductType,
        pm_category: attrPmCategory,
        plcode: attrPlCode,
        form_factor: attrFormFactor,
        facet_subbrand: attrFacetSubbrand,
        facet_formfactor: attrFacetFormFactor,
    } = attributes || {};
    let price = prices && (prices.regularPrice || prices.salePrice) ? prices : prices && prices[metricSku];
    let { prodName, plcode: hpServicesPlCode } = price || {}; // used in cases where ETR API doesn't have name for products, such as service carepack addons
    let { facet_subbrand: productBrand, plcode: productPlcode } = price || {};
    let productPrice = price && (price.salePrice || price.regularPrice);
    let encodedFormFactor = formatProductMetricValue(
        facet_formfactor || form_factor || attrFacetFormFactor || attrFormFactor
    );
    let variantStart = typeof metricSku === 'string' && metricSku.indexOf('#');
    let { list, position, xsellMethod, xsellProdInfo, disableBrand, hsResID } = config || {};

    let metricsPlcode = plcode || productPlcode || attrPlCode || hpServicesPlCode;
    let metricsBrand = facet_subbrand || productBrand || attrFacetSubbrand;
    let metricsPmProductType = pm_producttype || attrPmProductType;
    let metricsPmCategory = pm_category || attrPmCategory;
    let metricsName = name || title || prodName;

    return {
        ...(!disableBrand
            ? {
                  brand: getValidProductBrandMetricValue(
                      metricsBrand,
                      metricsName,
                      metricsPmProductType,
                      isNonPdpOrProduct
                  ),
              }
            : {}),
        pl: metricsPlcode ? formatProductMetricValue(metricsPlcode) : '',
        cat: metricsPmProductType ? getValidProductCategoryMetricValue(metricsPmProductType) : '',
        subCat: metricsPmCategory ? formatProductMetricValue(metricsPmCategory) : '',
        formFactor: encodedFormFactor ? encodedFormFactor : '',
        variant: typeof variantStart === 'number' && variantStart > -1 ? metricSku.substring(variantStart) : '',
        id: metricSku,
        name: metricsName,
        ...(productPrice ? { price: productPrice + '' } : { price: '' }),
        ...(list ? { list } : {}),
        ...(position ? { position: position + '' } : { position: '' }),
        ...(xsellMethod ? { xsellMethod } : {}),
        ...(xsellProdInfo ? { xsellProdInfo } : {}),
        ...(hsResID ? { hsResID } : {}),
    };
};

export const addElicitPromotionsToAnalyticMap = ({
    title,
    ctaText,
    href,
    position,
    id = 'suppliesFinder',
    searchTerm,
}) => {
    let bannerData = {
        title,
        cta: {
            text: ctaText,
            props: {
                href,
            },
        },
    };
    let config = {
        position,
        id: `${id}${searchTerm ? '-' + formatMetricValue(searchTerm) : ''}`,
    };

    let page_level;
    try {
        let { analyticsData } = window.store.getState().slugInfo;
        page_level =
            (analyticsData.derivedAnalyticsData && analyticsData.derivedAnalyticsData.page_level) ||
            analyticsData.page_level;
    } catch (e) {}

    let analyticsData = {
        page_level,
    };

    let gtmData = addGtmPropsToBanner(bannerData, config, analyticsData);

    let { gtmActions } = gtmData;
    return (gtmActions && gtmActions.get && gtmActions.get('ctaClick')) || {};
};

export const addElicitProductsToAnalyticMap = (product, prices, position) => {
    let pos = Number(position || 0) + 1;
    let productData = getDerivedProductMetricsV2(product, prices, { position: pos, list: 'supplies-finder' });
    addProductMap(`supplies-finder-${productData.id}`, productData);
};

export const getCartId = () => {
    try {
        return window.guxAnalytics.cartId;
    } catch (e) {}
};

export const getProductMetrics = product => {
    let { name, sku, price, position } = product;
    let variantIndex = sku && sku.indexOf('#');
    return {
        ...getDerivedProductMetricsV2(product),
        id: sku,
        name,
        price,
        position,
        variant: variantIndex > -1 ? sku.slice(variantIndex) : '',
    };
};

export const getProductClickMetrics = ({ product, position, useAttributes, list }) => {
    // use listPrice for price, similar to the current a2c events
    let { name, sku, variant, listPrice, gtmUniqueId     } = product || {};
    let derivedProductMetrics = getDerivedProductMetrics(product);
    if (useAttributes) {
        return {
            'data-metrics-event-name': 'productClick',
            'data-metrics-event': 'e_productClick',
            'data-metrics-name': name,
            'data-metrics-price': listPrice + '',
            'data-metrics-id': sku,
            ...(position ? { 'data-metrics-position': position + '' } : {}), // starts at 1, can never be 0
            ...(derivedProductMetrics.brand ? { 'data-metrics-brand': derivedProductMetrics.brand } : {}),
            ...(derivedProductMetrics.category ? { 'data-metrics-category': derivedProductMetrics.category } : {}),
            ...(variant ? { 'data-metrics-variant': variant } : {}),
            ...(list ? { 'data-metrics-list': list } : {}),
        };
    }

    return {
        price: listPrice + '',
        name,
        id: sku,
        ...(derivedProductMetrics.brand ? { brand: derivedProductMetrics.brand } : {}),
        ...(derivedProductMetrics.category ? { category: derivedProductMetrics.category } : {}),
        ...(variant ? { variant } : {}),
        ...(position ? { position: position + '' } : {}),
        ...(list ? { list } : {}),
    };
};

/**
 *
 * @param {*} data
 * @param {*} getGtmAttributes
 * @returns Object containing gtm props passed to stellar component
 */
export const addGtmAttributes = (data, getGtmAttributes, config, componentPosition) => {
    if (typeof getGtmAttributes !== 'function') {
        return data;
    }

    return {
        ...(data || {}),
        ...getGtmAttributes(data, config, componentPosition),
    };
};

/**
 *
 * @param {*} prop
 */
export const addGtmAttributesToDataArray = (dataArray, getGtmAttributes, config) => {
    if (!Array.isArray(dataArray) || typeof getGtmAttributes !== 'function') {
        return dataArray;
    }

    return dataArray.map((data, idx) => addGtmAttributes(data, getGtmAttributes, config, idx + 1));
};

export const formatMetricValue = (value, allowedSpecialChars) => {
    let allowedChars = `[^A-Za-z0-9${Array.isArray(allowedSpecialChars) ? allowedSpecialChars.join('') : ''}]`;
    return typeof value === 'string'
        ? // remove any html tags, replace any non-digit and non-alphabets with -, remove trailing non-alphabets and non-digits
          value
              .trim()
              .toLowerCase()
              .replace(/<\/?[a-zA-Z0-9]+>/g, '') // remove html tags
              .replace(new RegExp(`${allowedChars}+`, 'g'), '-')
              .replace(new RegExp(`${allowedChars}$`), '')
              .replace(/(^[\-]+|[\-]+$)/, '')
        : value;
};

export const memoizedFormatMetricValue = Helpers.memoize(formatMetricValue);

export const formatProductMetricValue = value => (typeof value === 'string' ? value.trim().replace(/\//g, '-') : value);

export const getUniquePromotionId = (href, name, positionId) => {
    const queryString = typeof href === 'string' ? href.split('?').pop() : '';
    const { jumpid } = Helpers.getSearch(queryString, true) || {};
    return `us|${jumpid || positionId}-${name}`;
};

/**
 *
 * @param {*} origProp
 * @param {*} config
 * @param {*} analyticsData
 * @returns
 *
 * Adds promotionClicks and promotionImpressions GTM attributes, and then
 * adds it to promnotionsMap
 *
 */
export const setPromotion = (origProp, config, analyticsData) => {
    let { type, position, id, metricConfig, isV1, device } = config || {};
    let { promotionClickType, gtmActionKey } = metricConfig || {};
    let { title, titleDesktop, titleMobile, cta, anchorLinkText } = origProp || {};
    let { text, props } = cta || {};
    let { href } = props || {};

    // Analytics team 10/6: Use desktop texts at all times.
    // let isMobile = device === 'mobile';
    // let titleProp = isMobile
    //             ? titleMobile || title
    //             : title;

    // let ctaText = isMobile
    //                 ? textMobile || text
    //                 : text;
    let { page_level } = analyticsData || {};
    let titleGtmValue = formatMetricValue(title || (device === 'mobile' ? titleMobile : titleDesktop));

    let autoNumber = position || 1;
    let positionId = `${page_level}|${id}|${autoNumber}`;
    let gtmValue =
        promotionClickType === 'cta' || type === 'promotionClick'
            ? formatMetricValue(text || anchorLinkText || 'shop')
            : titleGtmValue || 'shop';
    let promotionId = getUniquePromotionId(href, titleGtmValue, positionId);
    const gtmUniqueId = getPromotionMapKey(promotionId, positionId);
    let gtmProps = {
        gtmCategory: type,
        gtmId: gtmUniqueId,
        gtmValue,
    };

    let promotionsImpressionData = isV1 ? {
        name: titleGtmValue,
        position: positionId,
        id: promotionId,
    } : {
        id: promotionId,
        name: titleGtmValue,
        pageNameL5: page_level,
        placement: id,
        autoNumber
    }
    addPromotionsMap(gtmUniqueId, promotionsImpressionData);

    let gtmActions = new Map([[gtmActionKey || 'ctaClick', gtmProps]]);

    return {
        ...origProp,
        gtmActions,
        gtmUniqueId,
        // promotionsImpressionData
    };
};

export const addBannersToPromotionsMap = banners => {
    banners.forEach( banner => {
        let { gtmUniqueId, promotionsImpressionData } = banner || {};
        try{
            if(gtmUniqueId && promotionsImpressionData){
                window.promotionsMap = window.promotionsMap || {};
                window.promotionsMap[gtmUniqueId] = promotionsImpressionData;
            }
        }catch(e){}
    })
}

const getProductMapValue = (product, config) => {
    let { name, ctaViewDetails, ctaViewDetailsLink, sku, title, price, variant, brand } = product || {};
    let { salePrice, regularPrice } = price || {};
    let { list, position } = config || {};

    let { category } = getDerivedProductMetricsV2(product);

    let productData = {
        name,
        id: sku,
        price: salePrice || regularPrice,
        category,
        position,
        brand,
        variant,
    };

    return productData;
};

export const addGtmProductClick = (origProp, config, prices, options) => {
    let { name, ctaViewDetails, ctaViewDetailsLink, sku, id, customGtmId, title, price, variant, brand, gtmUniqueId, oldGtmId } =
        origProp || {};
    let { salePrice, regularPrice } = price || {};
    let { list, position, gtmIdPrefix } = config || {};
    let gtmIdSuffix = `product-click-${sku}`;
    let gtmId = gtmUniqueId; //list ? `${list}-${gtmIdSuffix}` : customGtmId || gtmIdSuffix;
    // gtmId = gtmIdPrefix ? `${gtmIdPrefix}-${gtmId}` : gtmId;

    let productData = getDerivedProductMetricsV2(origProp, prices, config);
    
    if(oldGtmId) {
        addProductMap(oldGtmId, productData, { oldGtmId });
    }

    let gtmActions = new Map([
        [
            'productClick',
            {
                gtmCategory: 'productClick',
                gtmId,
                gtmValue: list,
            },
        ],
    ]);
    return {
        ...origProp,
        gtmActions,
    };
};

/**
 *
 * @param {*} prd
 * @param {*} config
 * @param {*} prices
 * @returns Either AddToCart
 */
export const addGtmAddToCart = (prd, config, prices, cartId, quantity) => {
    let { sku, gtmUniqueId } = prd || {};
    let {
        list,
        gtmIdPrefix,
        metricKey,
        forInlineAttribute,
        enableCtoAddToCart,
        addons,
        selectedAddonSku,
        addonMetrics,
        removeFromCart
    } = config || {};
    let gtmId = gtmUniqueId
    let priceObj = prices && (prices.regularPrice || prices.salePrice) ? prices : prices && prices[sku];
    let { productType } = priceObj || {};
    let analyticsData =
        !enableCtoAddToCart && /cto/i.test(productType || '')
            ? {
                  gtmCategory: 'productClick',
                  gtmId: gtmUniqueId || 'product',
                  gtmValue: list,
              }
            : {
                  gtmCategory: removeFromCart ? 'removeFromCart' : 'addToCart',
                  gtmId,
                  gtmValue: list,
                  gtmCartId: cartId || undefined,
                  gtmQuantity: quantity || 1,
                  ...(selectedAddonSku ? { gtmAccessories: selectedAddonSku } : {}),
              };
    addons &&
        addons.forEach(addon => {
            try {
                let { sku } = addon;
                addAccessoryMap(sku, { ...getDerivedProductMetricsV2(addon, prices, config), ...(addonMetrics || {}) });
            } catch (e) {}
        }, []);

    // if using forInlineAttributes, because some stellar components don't accept gtmActions prop and instead passes inline attributes.
    if (forInlineAttribute) {
        return {
            'data-gtm-category': analyticsData.gtmCategory,
            'data-gtm-value': analyticsData.gtmValue,
            'data-gtm-id': analyticsData.gtmId,
            ...(cartId ? { 'data-gtm-cart-id': cartId } : {}),
            'data-gtm-quantity': quantity || 1,
            ...(selectedAddonSku ? { 'data-gtm-accessories': selectedAddonSku } : {}),
        };
    }

    let gtmKey = metricKey || 'addToCart';

    // Stellar component will only accept the key "addToCart", and so this key is used even for "Customize & Buy" button
    let gtmAction = new Map([[gtmKey, analyticsData]]);

    return gtmAction;
};

export const addGtmCustomizeAndBuy = (product, metrics, prices, config) => {
    let { forInlineAttribute, gtmUniqueId } = config || {};
    let { sku } = product || {};
    let { list, gtmKey } = metrics || {};

    let gtmId = gtmUniqueId;//gtmKey || `cto-${list ? list + '-' : ''}${sku}-product-click`;
    let gtmValue = list || 'cto';

    // addProductMap(gtmId, getDerivedProductMetricsV2(product, prices, metrics));

    return forInlineAttribute
        ? {
              'data-gtm-category': 'productClick',
              'data-gtm-value': gtmValue,
              'data-gtm-id': gtmId,
          }
        : // Stellar component will only accept the key "addToCart", and so this key is used even for "Customize & Buy" button
          new Map([
              [
                  'addToCart',
                  {
                      gtmCategory: 'productClick',
                      gtmValue,
                      gtmId,
                  },
              ],
          ]);
};

export const addGtmPropsToProductTile = (origProp, config, prices) => {
    let { product_type, prdClass, quantity } = origProp || {};
    let { enableCtoAddToCart, gtmUniqueId } = config || {};
    let productType = product_type || prdClass;
    let isCto = typeof productType === 'string' && /cto/i.test(productType);

    let gtmActions = new Map();
    let gtmProductClick = addGtmProductClick(origProp, config, prices).gtmActions;

    let productClickData = gtmProductClick && gtmProductClick.get('productClick');
    if (gtmProductClick) gtmActions.set('productClick', productClickData);

    // productClick events for 'customize&buy'
    if (isCto && productClickData && !enableCtoAddToCart) {
        gtmActions.set('addToCart', gtmProductClick.get('productClick'));
    } else {
        let { cartId } = config || {};
        let gtmAddToCart = addGtmAddToCart(origProp, config, prices, cartId, quantity);
        gtmActions.set('addToCart', gtmAddToCart ? gtmAddToCart.get('addToCart') : {});
    }

    if (enableCtoAddToCart && productClickData) {
        gtmActions.set('customize', productClickData);
    }

    return gtmActions;
};

export const addLinkClickGtmPropsToBanner = (banner, config) => {
    let { metricConfig, device, bannerModifier } = config || {};
    let { gtmValue, gtmActionKey, allowedChars } = metricConfig || {};
    let { title, titleMobile } = banner || {};

    let isMobile = device === 'mobile';
    let gtmId = formatMetricValue(isMobile ? titleMobile || title : title, allowedChars);

    return {
        ...(bannerModifier ? bannerModifier(banner) : banner || {}),
        gtmActions: new Map([
            [
                gtmActionKey || 'ctaClick',
                {
                    gtmCategory: 'linkClick',
                    gtmValue,
                    gtmId,
                },
            ],
        ]),
    };
};

export const addGtmPropsToBanner = (banner, config, analyticsData) => {
    let { device, position, id, metricConfig } = config || {};
    let { gtmCategory } = metricConfig || {};
    if (gtmCategory === 'linkClick') {
        return addLinkClickGtmPropsToBanner(banner, config);
    }
    return setPromotion(banner, { device, position, id, type: 'promotionClick', metricConfig }, analyticsData);
};

export const addGtmPropsToBanners = (banners, device, analyticsData, id, metricConfig) =>
    // position must start at 1
    banners.map((banner, idx) =>
        addGtmPropsToBanner(
            banner,
            { device, position: idx + 1, id, type: 'promotionClick', metricConfig },
            analyticsData
        )
    );

export const addGtmPropsToContentCard = (prdCard, config, prices) => {
    let { sku, prdClass, product_class, product_type, ctaAddToCart, name } = prdCard || {};
    let productType = prdClass || product_class || product_type;
    let { list, position, gtmCategory } = config || {};
    let isCto = productType === 'CTO' || (typeof ctaAddToCart === 'string' && /customize & buy/i.test(ctaAddToCart));

    let productData = getDerivedProductMetricsV2(prdCard, prices, config);

    let gtmId = `${list ? list + '-' : ''}content-card-${sku}`;
    // addProductMap(gtmId, productData);

    const ctaGtmActions = addGtmAddToCart(prdCard, { list, metricKey: 'productCtaAction', position }, prices);
    // TODO: Check if it might be better to add a parameter specifying which stellar component this will be used on.
    // Right now, the gtmActions can contain the following because no 2 components expect different values for the same Map key.
    let gtmActions = new Map([
        // view details button
        [
            'productViewDetails',
            {
                gtmId,
                gtmValue: list,
                gtmCategory: 'productClick',
            },
        ],
        [
            'ctaClick',
            {
                gtmId: isCto ? 'product' : gtmId,
                gtmValue: isCto ? 'cto' : list,
                gtmCategory: isCto ? 'linkClick' : 'addToCart',
            },
        ],
        // only used for AttachableProductTiles
        [
            'productCtaAction',
            ctaGtmActions && typeof ctaGtmActions.get === 'function' ? ctaGtmActions.get('productCtaAction') : {},
        ],
    ]);

    return {
        ...(prdCard || {}),
        gtmActions,
    };
};

export const addGtmPropsToContentCards = (productCards, config) =>
    Array.isArray(productCards)
        ? productCards.map((prdCard, idx) => addGtmPropsToContentCard(prdCard, { ...config, position: idx + 1 }))
        : productCards;

export const getModelTileGtmAttributes = modelTile => {
    let { name, title, modelDetails } = modelTile || {};
    let viewMoreText = `View All ${name || title}`;

    let newModelDetails =
        Array.isArray(modelDetails) &&
        modelDetails.map(modelDetail => {
            let { name, title } = modelDetail || {};
            let label = name || title;
            if (label) {
                modelDetail.gtmActions = new Map([
                    [
                        'productClick',
                        {
                            gtmValue: formatMetricValue(label),
                            gtmId: 'product',
                            gtmCategory: 'linkClick',
                        },
                    ],
                ]);
            }
            return modelDetail;
        });

    let nameGtmValue = formatMetricValue(name || title);
    return {
        gtmValue: nameGtmValue,
        gtmActions: new Map([
            [
                'productClick',
                {
                    gtmValue: nameGtmValue,
                    gtmId: 'product',
                    gtmCategory: 'linkClick',
                },
            ],
        ]),
        viewMoreText,
        viewMoreLinkGtmAttributes: {
            'data-gtm-value': formatMetricValue(viewMoreText),
            'data-gtm-category': 'linkClick',
            'data-gtm-id': 'product',
        },
        ...(newModelDetails ? { modelDetails: newModelDetails } : {}),
    };
};

export const getClpModelLinkId = ({
    clpType,
    carouselName,
    sectionLabel,
    sectionPosition,
    withTabs,
    tileName,
    tilePos,
}) => {
    let formattedCarouselName = formatMetricValue(carouselName || '');
    let tabSegmentLinkPlacementSegment = withTabs
        ? `${formatMetricValue(sectionLabel || '')}:${sectionPosition || ''}`
        : ':'; // only include these segments if the carousel has tabs.
    return `clp:${clpType || ''}:${formattedCarouselName}:${tabSegmentLinkPlacementSegment}:${
        tileName ? formatMetricValue(tileName) : ''
    }:${tilePos || ''}`;
};

/**
 *
 * @param {*} modelTile - data retrieved from ETR API. The data can optionally have a nested modelDetails array, which indicates the carousel contains tabs
 * @param {*} gtmConfig - contains data used for the linkId including carouselName, clpType and the withTabs option:
 *                        1. withTabs - set to true to add the tab name and tab position in the linkId (gtmValue/data-gtm-value).
 *                                      Not doing so will make the tab segments (<tabName>:<tabPosition> below) blank (":") and use the modelTile's name/title for the tile segment (<tileName>:<tilePosition> below) instead
 *                                      withTabs !== true is intended for the "brand" carousel
 *                                      withTabs === true is intended for the "lifestyle" carousel
 *                        2. carouselName - either "brand" or "lifestyle" currently.
 *                        3. clpType - the name of the in the vanityUrl "cat/<name>"
 *                        4. gtmId - the linkPlacement in the linkClick event which defaults to "carouel". all CLP carousels currently have linkPlacement as carousel
 * @param {*} sectionPosition - either the tab position or the card position if the carousel does not have a tab (if withTab is not true)
 * @returns GTM data with gtmValue === "carousel" and gtmId === "clp:<clpType>:<carouselName>:<tabName>:<tabPosition>:<tileName>:<tilePosition>"
 */
export const getClpCarouselGtmAttributes = (modelTile, gtmConfig, sectionPosition) => {
    let { name, title, modelDetails } = modelTile || {};
    let viewMoreText = `View All ${name || title}`;
    // withTabs=true indicates that the CLP carousel has tabs and the GTM values are generated differently
    let { gtmId = 'carousel', carouselName, clpType, withTabs } = gtmConfig || {};

    // sectionLabel is either the tab name or the product card name if there isn't a tab
    let sectionLabel = name || title;

    let getLinkId = (tileName, tilePos) =>
        getClpModelLinkId({
            withTabs,
            clpType,
            carouselName,
            sectionLabel,
            sectionPosition,
            tileName,
            tilePos,
        });
    let newModelDetails =
        Array.isArray(modelDetails) &&
        modelDetails.map((modelDetail, idx) => {
            let { name, title } = modelDetail || {};
            let label = name || title;
            if (label) {
                modelDetail.gtmActions = new Map([
                    [
                        'productClick',
                        {
                            gtmValue: getLinkId(label, idx + 1),
                            gtmId,
                            gtmCategory: 'linkClick',
                        },
                    ],
                ]);
            }
            return modelDetail;
        });

    let nameGtmValue = withTabs ? getLinkId() : getLinkId(sectionLabel, sectionPosition);
    return {
        gtmValue: nameGtmValue,
        gtmActions: new Map([
            [
                'productClick',
                {
                    gtmValue: nameGtmValue,
                    gtmId,
                    gtmCategory: 'linkClick',
                },
            ],
        ]),
        viewMoreText,
        viewMoreLinkGtmAttributes: {
            'data-gtm-value': getLinkId(viewMoreText),
            'data-gtm-category': 'linkClick',
            'data-gtm-id': gtmId,
        },
        ...(newModelDetails ? { modelDetails: newModelDetails } : {}),
    };
};

export const getContentCardBannerGtmAttributes = (contentCardBanner, config) => {
    let { device, gtmId } = config || {};
    let { title, titleMobile } = contentCardBanner || {};

    let gtmValue = device === 'mobile' ? titleMobile : title;

    return {
        gtmActions: new Map([
            [
                'ctaClick',
                {
                    gtmValue: formatMetricValue(gtmValue),
                    gtmId: gtmId || 'product',
                    gtmCategory: 'linkClick',
                },
            ],
        ]),
    };
};

export const addGtmAttributesToModelTiles = modelTiles =>
    addGtmAttributesToDataArray(modelTiles, getModelTileGtmAttributes);

export const addGtmAttributesToClpModelTiles = (modelTiles, config) =>
    addGtmAttributesToDataArray(modelTiles, getClpCarouselGtmAttributes, config);

export const addGtmAttributesToContentCardBanners = (contentCardBanners, config) =>
    addGtmAttributesToDataArray(contentCardBanners, getContentCardBannerGtmAttributes, config);

export const getMarketSegment = brand => {
    brand = typeof brand === 'string' ? brand : '';
    return /pavilion|hyperx|chrome|victus|omen|envy|deskjet|laserjet|neverstop|tank|tango/gi.test(brand)
        ? 'consumer.home'
        : /elite|spectre|thin-clients|pro|z|pagewide|officejet|designjet/gi.test(brand)
        ? 'commercial.smb'
        : 'segment neutral';
};

export const getDerivedProductPageNameLevels = (product, analyticsData, isNonPdpOrProduct) => {
    try {
        // let {isSMB} = product || {};
        let { family, derivedAnalyticsData } = analyticsData || {};
        let { brand } = getDerivedProductMetricsV2(product, null, null, isNonPdpOrProduct) || {};

        brand =
            (!brand || brand.length < 1) && family
                ? formatMetricValue((derivedAnalyticsData && derivedAnalyticsData.family) || family)
                : brand;
        let pmProductType =
            product &&
            (product.pm_producttype ||
                (product.attributes && product.attributes.pm_producttype) ||
                product.topCategory);
        let category = getValidProductCategoryMetricValue(pmProductType);
        let bu = getBusinessUnit(category);
        return {
            product_type: category,
            family: formatMetricValue(brand),
            bu,
            segment: getMarketSegment(brand),
        };
    } catch (e) {}

    return {};
};

export const getStaticAnalyticsData = pathname => {
    try {
        if (/(configureattach|AccessoryAttachView)/i.test(pathname)) {
            return {
                simple_title: 'cto-accessory-attach',
                page_level: 'cto',
                vanityUrl: 'AccessoryAttachView',
                derivedAnalyticsData: {
                    asyncImpressionComponents: [{ name: 'accessoryAttach', type: 'product' }],
                },
            };
        }
    } catch (e) {}
};

// TODO: CHECK IF THE PRODUCT IDS FOR COMPARE BAR AND THE NAME FOR READREVIEW NEEDS TO BE IN GTM VALUE INSTEAD. CANT FIND DOCUMENTATION ON THIS...
export const getCompareBarGtmProps = skus => {
    let gtmValue = Array.isArray(skus) ? skus.join(', ') : skus;
    return new Map([
        [
            'compareNow',
            {
                gtmCategory: 'compareModels',
                gtmId: 'product',
                gtmValue,
            },
        ],
    ]);
};

export const getReadReviewGtmProps = productName =>
    new Map([
        [
            'readReview',
            {
                gtmCategory: 'readReview',
                gtmId: productName,
                gtmValue: productName,
            },
        ],
    ]);

export const addGtmTabAttributes = tabs =>
    Array.isArray(tabs)
        ? tabs.map(tab => {
              let { title, name } = tab || {};
              let gtmValue = title || name;
              return {
                  ...(tab || {}),
                  gtmValue: gtmValue ? formatMetricValue(gtmValue) : null,
              };
          })
        : tabs;

export const addCategoryTilesGtmActions = (tile, device) => {
    let { title, titleMobile } = tile || {};
    let isMobile = device === 'mobile';

    let gtmValue = formatMetricValue(isMobile ? titleMobile || title : title);

    return {
        gtmId: 'tiles',
        gtmCategory: 'linkClick',
        gtmValue,
    };
};

export const getGtmData = (gtmId, gtmCategory, gtmValue, options) => {
    let { disableFormatting } = options || {};
    return {
        gtmId,
        gtmCategory,
        gtmValue: disableFormatting ? title : formatMetricValue(gtmValue),
    };
};

export const addGtmPropsToCategoryTiles = (tiles, device) =>
    Array.isArray(tiles)
        ? tiles.map(tile => {
              let { title, titleMobile } = tile || {};
              tile.gtmActions = new Map([
                  ['tileClick', getGtmData('tiles', 'linkClick', device === 'mobile' ? titleMobile || title : title)],
              ]);
              return tile;
          })
        : tiles;

export const addGtmPropsToHighlightBannerComponent = ({ component, componentKey, analyticsData, device }) => {
    const { highlightBanner } = component || {};
    if (!highlightBanner) return component;

    const banners = addGtmPropsToBanners(
        [highlightBanner],
        device,
        analyticsData,
        componentKey /*,{promotionClickType: 'cta'}*/
    );
    component.highlightBanner = banners && banners.length > 0 ? banners[0] : component.highlightBanner;
    return component;
};

export const templateAnalytics = {
    global: globalAnalytics,
    cat: categoryPageAnalytics,
    home: homePageAnalytics,
    vwa: vwaPageAnalytics,
    configureview: configureviewPageAnalytics,
    pdp: pdpAnalytics,
    mlp: mlpAnalytics,
    sitesearch: srpAnalytics,
    mdp: mdpAnalytics,
    slp: slpAnalytics,
    dlp: dlpAnalytics,
    reviews: prpAnalytics,
};

export const setAnalytics = ({ vanityUrl, componentKey, component, device, analyticsData, slugInfo }) => {
    if (!vanityUrl) {
        return component;
    }
    //based on the component key apply relevant analytics data. ConfigureView contains the query string in the vanityUrl
    let dir = vanityUrl.split(/[\/\?]/)[0].toLowerCase();
    dir = dir === '' ? 'home' : dir;
    let templateFunction =
        (templateAnalytics[dir] && templateAnalytics[dir][componentKey]) || templateAnalytics.global[componentKey];
    if (templateFunction) {
        return templateFunction({ component, device, analyticsData, slugInfo });
    }

    return component;
};

export const setAnalyticsMemoized = Helpers.memoize(
    setAnalytics,
    ({ vanityUrl, componentKey, component, device, analyticsData, slugInfo }) => {
        const cacheKey = `${vanityUrl}-${slugInfo && slugInfo.revisionNumber}-${componentKey}-${device}-${
            component && component.revisionNumber
        }`;
        return cacheKey;
    }
);

export const setAnalyticsData = ({ vanityUrl, componentKey, component, device, analyticsData, slugInfo, options }) =>
    !component ||
    (options && options.disableMemoization) ||
    (options && options.uncachedAnalytics && options.uncachedAnalytics[componentKey])
        ? setAnalytics({ vanityUrl, componentKey, component, device, analyticsData, slugInfo })
        : setAnalyticsMemoized({ vanityUrl, componentKey, component, device, analyticsData, slugInfo });

export const parseAnalyticsParameters = (eventName, params, state) => {
    let { batchKey, all } = params || {};
    let { metrics } = state || {};
    let { batchedImpressions } = metrics || {};
    if (eventName === 'productImpression') {
        params.productImpressions =
            batchKey && all && batchedImpressions && batchedImpressions[batchKey]
                ? Object.values(batchedImpressions[batchKey]).filter(prd => prd && !prd.tracked)
                : params.productImpressions;
    }

    return params;
};

/**
 *
 * @returns Banner data used to implement promotionImpression and promotionClick for the PayPal banner which
 *          is contained in an iframe.
 */
export const getPaypalBanner = () => [
    {
        title: 'PayPal Credit',
        cta: {
            text: 'Learn more',
        },
    },
];

/**
 *
 * @param {*} sku
 * @param {*} addOns
 * @returns a single Object that contains the product addon. Thsi function takes into account, all possible forms of "addOns" (Array with object, object with array, etc.)
 */
export const getAddon = (sku, addOns) => {
    return addOns && Array.isArray(addOns) && addOns[0]
        ? addOns[0].product || addOns[0]
        : addOns && addOns[sku]
        ? Array.isArray(addOns[sku]) && addOns[sku][0]
            ? addOns[sku][0].product || addOns[sku][0]
            : addOns[sku].product
        : null;
};

// TODO: Check if it's possible to make these changes in ETR's Analytics API
export const productTypeMap = {
    'care-packs': 'services',
};

export const getCartGtmId = product => {
    let { sku, id, pNum } = product || {}; // pNum is for cart items
    let productId = pNum || sku || id;
    return `cart-${productId}`;
};

export const getRemoveFromCartGtmAttributes = (product, cartId, list) => {
    const { qty } = product || {};
    return {
        'data-gtm-category': 'removeFromCart',
        'data-gtm-id': getCartGtmId(product),
        'data-gtm-value': list,
        'data-gtm-cart-id': cartId,
        'data-gtm-quantity': qty,
        'data-gtm-object': 'cartProducts'
    }
}

export const addCartProductMap = (inCartProduct, productInitials, analyticsData) => {
    let { amt, pNum } = inCartProduct || {};
    let { iPRice, nAmt, uPrice } = amt || {};
    let productInitial = Array.isArray(productInitials) ? productInitials.find(p => p.sku === pNum) : productInitials;
    let price = { salePrice: uPrice };
    let product = getDerivedProductMetricsV2(productInitial, price, analyticsData) || {};

    let cartKey = getCartGtmId(product);
    addProductMap(cartKey, product);

    return window.productMap[cartKey];
};

export const derivePageNameValuesFromProductList = products => {
    let currentProductType;
    let currentFamily;
    let currentBu;
    let currentSegment;

    let nonUnique = {};

    try {
        for (let product of products) {
            let { isSMB } = product || {};
            let {
                product_type = 'shared',
                family = 'shared',
                bu = 'shared',
                segment = 'segment neutral',
            } = getDerivedProductPageNameLevels(product, null, true);
            if (currentProductType && currentProductType !== product_type) nonUnique.product_type = true;
            currentProductType = product_type;

            if (currentFamily && currentFamily !== family) nonUnique.family = true;
            currentFamily = family;

            if (currentBu && currentBu !== bu) nonUnique.bu = true;
            currentBu = bu;

            if (currentSegment && currentSegment !== segment) nonUnique.segment = true;
            currentSegment = segment;

            if (nonUnique.product_type && nonUnique.family && nonUnique.bu && nonUnique.segment) break;
        }
    } catch (e) {}

    return {
        family: (!nonUnique.family && currentFamily) || 'shared',
        product_type: (!nonUnique.product_type && currentProductType) || 'shared',
        bu: (!nonUnique.bu && currentBu) || 'shared',
        segment: (!nonUnique.segment && currentSegment) || 'segment neutral',
    };
};

/**
 * TODO: Check if still needed. useProductImpression was updated to account for the priceFetchFailed issue.
 * passed to usePageView and impression hooks
 */
export const getPriceMetricInput = (products, prices) => {
    const hasPrices =
        products &&
        products.find(prd => {
            let { sku, catentryId } = prd || {};
            let priceObj = sku && prices && prices[sku];
            let priceFailure = catentryId && prices && prices[catentryId];
            return (
                (priceObj && !!(priceObj.regularPrice || priceObj.salePrice || priceObj.priceFetchError)) ||
                (priceFailure &&
                    !!(priceFailure.regularPrice || priceFailure.salePrice || priceFailure.priceFetchError))
            );
        });
    return hasPrices ? prices : {};
};

export const getShadowCtoGtmActions = pdpShadowCTO =>
    Array.isArray(pdpShadowCTO)
        ? pdpShadowCTO.map(cto => {
              if (!cto) return cto;

              const { title, value } = cto || {};
              let foundChecked;
              cto.value = Array.isArray(value)
                  ? value.map(option => {
                        const { checked, label, disabled } = option || {};

                        if (checked || disabled || !option) {
                            foundChecked = foundChecked || checked;
                            return option; // no analytics if clicking on a checked or unavailable option.
                        }

                        option.gtmAttributes = {
                            'data-gtm-category': 'linkClick',
                            'data-gtm-id': foundChecked || value.length === 1 ? 'SCTO-upgrade' : 'SCTO-downgrade',
                            'data-gtm-value': `${formatMetricValue(title)}|${formatMetricValue(label)}`,
                        };

                        return option;
                    })
                  : value;

              return cto;
          })
        : pdpShadowCTO;

export const getAvailableConfiguratorStartParams = params => {
    // This function is triggered in eventCallback parameter of the object pushed to dataLayer.
    // the eventCallback may be called multiple times.
    let configuratorStartParams = _configuratorStart(params);
    let stringifiedConfiguratorStartParams = JSON.stringify(configuratorStartParams);
    let lastPageViewIndex = findLastIndex(window.dataLayer, eventObject => eventObject.event === 'e_pageView');
    let lastConfiguratorStartIndex =
        lastPageViewIndex >= 0
            ? findIndex(
                  window.dataLayer,
                  eventObject => {
                      let triggered =
                          eventObject.event === 'e_configuratorStart' ||
                          JSON.stringify(eventObject) === stringifiedConfiguratorStartParams;
                      return triggered;
                  },
                  lastPageViewIndex
              )
            : null;

    return (lastConfiguratorStartIndex === null ||
        lastConfiguratorStartIndex < lastPageViewIndex ||
        lastConfiguratorStartIndex < -1) &&
        lastPageViewIndex > 0
        ? configuratorStartParams
        : null;
};

export const pollPageView = (conditionFn, callback) => {
    let attempts = 0;
    let interval = setInterval(() => {
        let prevPageViewIndex =
            window && window.dataLayer && findLastIndex(window.dataLayer, e => e.event === 'e_pageView');
        let prevPageView =
            typeof prevPageViewIndex === 'number' && prevPageViewIndex > -1 && window.dataLayer[prevPageViewIndex];
        if (attempts > 20) {
            return clearInterval(interval);
        }

        attempts++;
        if (conditionFn(prevPageView)) {
            clearInterval(interval);
            callback();
        }
    }, 400);

    return interval;
};

export const getClearAllFiltersGtmAttributes = () => ({
    'data-gtm-category': 'facet',
    'data-gtm-clear': true,
});

export const PRICE_GTM_ID = 'price';

export const getPriceFacetsGtmActions = (gtmActions, label, gtmChecked) => {
    try {
        return (
            gtmActions ||
            new Map([
                [
                    'checkboxClick',
                    {
                        gtmCategory: 'facet',
                        gtmValue: memoizedFormatMetricValue(label),
                        gtmId: PRICE_GTM_ID,
                        gtmChecked,
                    },
                ],
            ])
        );
    } catch (e) {}

    return gtmActions;
};

export const getAnalyticsVideoName = Helpers.memoize(
    (url, idx) => {
        try {
            let videoId = url;
            let pos = idx + 1;
            let isBrightcove = /^(http(s)?:\/\/)?players\.brightcove\.net/i.test(url);
            let isHpRegex = /^(http(s)?:\/\/)?(www)?\.hp\.com\/wcsstore\/hpusstore\/Treatment\/Video\//i;
            let isHp = !isBrightcove && isHpRegex.test(url);
            let { search: bcQueryStrObj } = (isBrightcove && Helpers.parseUrl(url)) || {};
            if (bcQueryStrObj) {
                let key = Object.keys(bcQueryStrObj).find(key => key.toLowerCase() === 'videoid');
                videoId = bcQueryStrObj[key];
            }

            if (isHp) {
                videoId = url.split(isHpRegex).pop();
            }

            return `${pos}|${videoId}`;
        } catch (e) {}
    },
    (url, idx) => idx + url
);

export const setPagePropertiesData = (params) => {
    try{
        window.pagePropertiesData = {
            ...window.pagePropertiesData || {},
            ...params || {}
        }
    }catch(e){}
}

export const getSearchAutoClickGtmAttributes = (gtmValue, gtmId, gtmAction) => {
    try{
        return {
            'data-gtm-category': 'searchAutoClick',
            'data-gtm-value': gtmValue,
            'data-gtm-id': gtmId,
            'data-gtm-action': memoizedFormatMetricValue(gtmAction)
        }
    }catch(e){}

    return {};
}

export const resetImpressions = () => {
    try{
        window.productMap = {};
        window.promotionsMap = { ...getGlobalPromotions() };
        window.accessoryMap = {};
        window.trackedProductImpressions = {};
    }catch(e){}
}

export const mergeToProductMap = (products, options) => {
    try{
        let { nonImpression } = options || {};
        window.productMap = window.productMap || {};
        Object.keys(products).forEach(key => {
            window.productMap[key] = products[key]
            // used in cases like configuratorStart, where the data is needed in productMap but not in impressions
            if(nonImpression){
                trackedProductImpressions[key] = 1;
                window.trackedProductImpressions[key] = 1;
            }
        })
    }catch(e){}
}

export const mergeToPromotionMap = promotions => {
    try{
        window.promotionsMap = window.promotionsMap || {};
        Object.keys(promotions).forEach(key => {
            window.promotionsMap[key] = promotions[key]
        })
    }catch(e){}
}

/**
 * 
 * @param {*} prices - price object from redux
 * @param {*} sku - product sku
 * @param {*} catentryId - product catentryId
 * @returns boolean indicating whether the price was fetched or not
 */
export const priceWasFetched = (prices, sku, catentryId) => {
    let price = prices[sku] || prices[catentryId] || {};
    let priceFailure = prices[catentryId] || {}; 
    return (price.regularPrice || price.salePrice || price.priceFetchError || priceFailure.priceFetchError);
}

/**
 * 
 * @param {*} product 
 * @param {*} options 
 * 
 * Generates a unique key used for product impression and clicks based on:
 * - sku
 * - analytics list value
 * - array index
 * - component name
 */
export const generateUniqueProductMapKey = (product, options) => {
    const { id, sku } = product || {};
    const { list, componentName, vanityUrl, listPosition } = options || {};
    return `${vanityUrl ? vanityUrl + '-' : ''}${componentName ? componentName + '-' : ''}${sku || id}-${listPosition || 1}`;
}

export const generateUniqueProductMapKeyForProductList = (products, options) => {
    let { key } = options || {};
    return Array.isArray(products) ? products.map( (prd, idx) => {
        try{
            if(!prd.gtmUniqueId){
                prd.gtmUniqueId = generateUniqueProductMapKey(prd, { componentName: key, listPosition: idx + 1 });
            }
        }catch(e){}
        return prd;
    }) : products;
}

export const removeFromProductMap = (componentName) => {
    try{
        const regex = new RegExp(`.*${componentName}.*`);
        Object.keys(window.productMap).forEach( key => {
            if(regex.test(key)){
                delete window.productMap[key];
            }
        })
    } catch (e) {}
}

export const addGtmUniqueIdToProductList = (products, vanityUrl, componentName) => {
    return products.map((prd, idx) => {
        if(!prd.gtmUniqueId){
            prd.gtmUniqueId = generateUniqueProductMapKey(prd, { componentName, vanityUrl, listPosition: idx+1 });
        }
        return prd;
    })
}

export const resetProductImpressions = () => {
    try{
        window.productMap = {};
        window.trackedProductImpressions = {};
        trackedProductImpressions = {};
    }catch(e){}
}

export const resetPromotionImpressions = () => {
    try{
        window.promotionsMap = {};
        window.trackedPromotionImpressions = {};
        trackedPromotionImpressions = {};
    }catch(e){}
}

export const deleteProductMapKey = key => {
    try{
        delete window.productMap[key];
        delete trackedProductImpressions[key]
        delete window.trackedProductImpressions[key]
    }catch(e){}
}

export const setMainSku = productImpressions => {
    try{
        let mainPrd = productImpressions && Object.values(productImpressions)[0];
        if(!mainPrd) return;
        window.pagePropertiesData = window.pagePropertiesData || {};
        window.pagePropertiesData.mainSKU = mainPrd;
    }catch(e){}
}

export const getUniqueVwaProductMapKey = (prd, options) => {
    let { sku } = prd || {};
    let { page, pageIndex } = options || {};
    return `finderResults-${sku}${page ? '-' + page : ''}-${pageIndex}`
}

export const addGtmUniqueIdToVwaProducts = (products, options) => {
    let { page } = options || {};
    return Array.isArray(products) ? products.map((prd, idx) => {
        try{
            if(prd && !prd.gtmUniqueId){
                prd.gtmUniqueId = getUniqueVwaProductMapKey(prd, { page, pageIndex: idx + 1 })
            }
        }catch(e){}
        return prd;
    }) : products;
};

let configuratorStartProduct;
export const setConfiguratorStartProduct = product => {
    let { brand, cat, formFactor, id, list, name, pl, position, price, subCat, variant } = product || {};
    configuratorStartProduct = {
        brand,
        category: `${pl || ''}/${cat || ''}/${subCat || ''}/${formFactor || ''}`,
        id,
        list, 
        name,
        price,
        position,
        ...variant ? { variant } : {}
    };
}

export const trackConfiguratorStartProduct = () => {
    if(configuratorStartProduct) {
        window.dataLayer.push(_configuratorStart(configuratorStartProduct))
        configuratorStartProduct = null;
    }
}

export const getGlobalNavGtm = (groupName, title, level) => {
    return {
        'data-gtm-category': 'globalNavigation',
        'data-gtm-id': `L${level || 1}-${groupName}`,
        'data-gtm-value': title || groupName
    }
}

// let DATA_LAYER_PAGE_VIEW_INDEX;
// let DATA_LAYER_PAGE_VIEW_INTERVALS = [];
// export const executeCallbackAfterPageView = (vanityUrl, cb) => {
//     let attempts = 0;
//     const interval = setInterval(() => {
//         attempts++;
//         let validVanityUrl, pageViewUrl;

//         try{
//             let state = window.store.getState();
//             pageViewUrl = state.metrics.pageView.vanityUrl;
//             validVanityUrl = vanityUrl && vanityUrl === pageViewUrl;
//         }catch(e){}
//         try{
//             const pageViewIndex = Array.isArray(window.dataLayer) && window.dataLayer.findLastIndex( event => event && event.event === 'e_pageView');
//             if(validVanityUrl && pageViewIndex > 0 && pageViewIndex !== DATA_LAYER_PAGE_VIEW_INDEX){
//                 DATA_LAYER_PAGE_VIEW_INDEX = pageViewIndex;
//                 cb();
//                 return clearInterval(interval);
//             }
//         }catch(e){}

//         if(attempts > 4){
//             validVanityUrl && cb();
//             clearInterval(interval);
//         }
//     }, 500);

//     DATA_LAYER_PAGE_VIEW_INTERVALS.push(interval)
// }

// export const clearPageViewIntervals = () => {
//     DATA_LAYER_PAGE_VIEW_INTERVALS.forEach(int => {
//         try{
//             clearInterval(int);
//         }catch(e){}
//     })
// }
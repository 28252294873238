import axios from 'axios';

import Page from './page';
import Person from './person';
import Component from './component';
import CTO from './cto';
import SEO from './seo';
import Product from './product';
import ObligationService from './obligation-service';
import Financing from './financing';
import { useRelativeLink, isNode, contextParameterInterceptor } from '../util';
import { Helpers } from '../helpers';
//domain name for local dev for remote ETR APIs
const DEFAULT_DOMAIN = process.env.DOMAIN || 'www.hp.com';

export const EtrAPIClient = function (domain = DEFAULT_DOMAIN, httpConfig = {}, logger = () => {}) {
    let self = this;
    let config = {
        siteInfo: {
            langId: -1,
            storeId: 10151,
            catalogId: 10051,
        },
    };

    let etrWCSInstance = axios.create({
        baseURL: useRelativeLink() ? '' : `https://${domain}`,
        //set a longer timeout for browser side call
        timeout: isNode ? 3000 : 60000 * 2,
        ...httpConfig,
    });
    etrWCSInstance.interceptors.request.use(httpConfig => {
        const { baseURL, url } = httpConfig;
        const { location } = typeof document !== 'undefined' ? document : {};
        const { origin } = location || {};
        httpConfig = contextParameterInterceptor(httpConfig);
        if (isNode) {
            httpConfig.headers['User-Agent'] = `us-react-app/latest`;
        }
        if ((origin || '').indexOf('localhost') || process.env.NODE_ENV === 'local') {
            const queryString = Helpers.paramsToString(httpConfig.params);
            //console.log('WCS HTTP Config', httpConfig, queryString);
        }

        return httpConfig;
    });

    etrWCSInstance.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            try {
                const { code, request } = error || {};
                const { message } = error.toJSON() || {};
                const { _currentUrl } = request || {};
                if (/^timeout.*exceeded/.test(message)) {
                    logger && logger('error', `ETR API ${message}. Path: ${_currentUrl}`);
                }
            } catch (e) {}
            return Promise.reject(error);
        }
    );

    self.page = new Page(config, etrWCSInstance);
    self.person = new Person(config, etrWCSInstance);
    self.component = new Component(config, etrWCSInstance);
    self.seo = new SEO(config, etrWCSInstance);
    self.product = new Product(config, etrWCSInstance); // TODO: Remove after confirming this is no longer needed. Only used for Hawksearch currently.
    self.cto = CTO(self, config, etrWCSInstance);
    self.obligationService = new ObligationService(config, etrWCSInstance);
    self.financing = new Financing(config, etrWCSInstance);
    //this is needed for unit testing. Pass into mockAdapter to mock request responses
    self.httpInstance = etrWCSInstance;
};

const _EtrAPI = new EtrAPIClient();

//TEMP: for debugging
if (typeof window !== 'undefined' && !isNode) {
    window.EtrAPI = _EtrAPI;
}

export default _EtrAPI;

class Product {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/component/product`;
        this.productDataPath = `/wcs/resources/store/${storeId}/HPProductDataBean`;
    }

    /**
     * Fetch page data
     * @param {string} template - template key of the page
     * @param {string} vanityUrl - the relative path of the page
     * @returns 
     */
    get(template, params) {
        return this.http.get(`${this.path}/${template}`, params)
            .then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data;
                } else {
                    return Promise.reject({})
                }
            });
    }

    // getProductInitial(catEntryId){
    //     const params = {
    //         productId: catEntryId,
    //         responseFormat: 'json'
    //     };

    //     return this.get('product-initial', { params });
    // }

    // getProductInitials(catEntryIds){
    //     return Array.isArray(catEntryIds)
    //     ? Promise.all(catEntryIds.map( catEntryId => this.getProductInitial(catEntryId).catch(() => ({})) ))
    //         .then( data => (data || []).reduce( (map, prd) => {
    //             try{
    //                 map[prd.product.catentryId] = prd.product;
    //             }catch(e){}

    //             return map;
    //         }, {})
    //     )
    //     : Promise.resolve([]);
    // }

    // use this instead if there's a way to get the link url through this api
    getProductInitials(catEntryIds){
        return Array.isArray(catEntryIds)
        ? this.http.get(`${this.productDataPath}/product`, { params: { catentryId: catEntryIds.join(','), responseFormat: 'json', fetchContents: 'productInitial' } })
            .then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data;
                } else {
                    return Promise.reject({})
                }
            })
        : [];
    }
}

export default Product;



import 'core-js/modules/es6.symbol';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import axios from 'axios';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import debounce from 'lodash/debounce';
import { loadableReady } from '@loadable/component';
import { debugPage } from './debug';
import 'core-js/modules/es6.promise';
import 'core-js/modules/es6.array.iterator';
import 'core-js/modules/es6.object.freeze';
import 'core-js/modules/es6.object.assign';
import 'core-js/modules/es6.array.for-each';

import './polyfill';
import '../../../wydr';
import './abtest/init';
import './index.css';

import App from './App';
import historyInterceptor from './utility/history-interceptor';
import { addToCart, launchCartFlyout } from './utility/utility-actions';
import { screenResize } from './ui/ui-action';
import { elementIsOffscreen } from './ui/util';
import configureStore from './store/store';
import ScrollToTop from './shared/components/scroll-to-top';
import { MetricsObserver } from './metrics/event-observer';
import { addElicitProductsToAnalyticMap, getCartId, addElicitPromotionsToAnalyticMap } from './metrics/metrics-helpers';
import getRoutes from './routes';
import { Helpers } from './core/src/helpers';
import getWorker from './worker';
import prefetchHPService, { fetchPrices } from './prefetch-hpservice';
import { getCTOLink } from './core/src/etr-api-client/cto';

const HYDRATION_DELAY = 301;

const loadPolyfill = () => {
    const ua = window.navigator.userAgent;
    const supported =
        typeof Array.prototype.forEach === 'function' &&
        typeof Array.prototype.find === 'function' &&
        typeof Object.entries === 'function' &&
        typeof Object.values === 'function' &&
        typeof String.prototype.startsWith === 'function' &&
        typeof String.prototype.endsWith === 'function';
    return /MSIE|Trident|YandexBot/.test(ua) || !supported;
};

let initCalled = false;
document.addEventListener('readystatechange', event => {
    if (!initCalled && event.target.readyState === 'complete') {
        initCalled = true;
        init();
    }
});

if (document.readyState === 'complete') {
    if (!initCalled) {
        initCalled = true;
        init();
    }
}

setTimeout(() => {
    if (!initCalled) {
        initCalled = true;
        init();
    }
}, HYDRATION_DELAY * 7);

async function init(force) {
    if (loadPolyfill()) await import(/* webpackChunkName: "polyfill" */ './polyfill/ie-polyfill');
    const { location } = window;
    //test delaying hydration for blog pages
    if (!force && location && location.pathname && /\/us\/en\/tech-takes\/[^$]/.test(location.pathname)) {
        setTimeout(() => {
            init(true);
        }, 800);
        return;
    }
    const elem = document.getElementById('data');
    if (!elem) {
        load(undefined, process.env.CLIENT_RENDER);
        return;
    }

    let data = elem.innerHTML;
    data = data.trim();
    if (data.length < 10) {
        load();
        return;
    }
    data = data.slice(5, -4);

    try {
        const worker = getWorker();
        worker.postMessage({ payload: data });
        worker.onmessage = e => {
            load(e.data);
        };
        worker.onerror = e => {
            load(JSON.parse(data));
        };
    } catch (e) {
        load(JSON.parse(data));
    }
}

function load(__data, forceHydration) {
    let initialStore = {};
    const { location = {} } = window;

    window.dependencyVersions = { ...window.dependencyVersions, react: React.version };

    //if server indicates store should be fetch async, fetch it and use that to load and hydate
    if (__data && __data.fetchState) {
        return axios
            .get(__data.fetchState)
            .then(({ data: stateJSON }) => {
                //prevent a loop here, just incase
                !stateJSON.fetchState && load(stateJSON);
            })
            .catch(e => {
                //not sure how you can recover from this????
            });
    }
    //skip this step if we don't want to delay hydration
    if (!forceHydration) {
        try {
            const startTime = new Date().getTime();
            return prefetchHPService(__data, HYDRATION_DELAY * 2)
                .then(() => {
                    //if we have successfully prefetched pricing info, delay hydration
                    //take the delay of the delay time and the time it took for HPservice to response
                    let timeoutRemaining = Math.max(HYDRATION_DELAY - (new Date().getTime() - startTime), 0);
                    setTimeout(() => {
                        load(__data, true);
                    }, timeoutRemaining);
                })
                .catch(e => {
                    load(__data, true);
                });
        } catch (e) {
            load(__data, true);
        }
    }

    if (__data && __data.router) {
        try {
            initialStore = __data;
            // cloudfront doesn't cache based on most query params, we need to update router info with query from browser
            initialStore.router.location = initialStore.router.location || {};
            initialStore.router.location.search = location.search;
            initialStore.router.location.hash = location.hash;
            initialStore.router.location.query = Helpers.getSearch(location.search);
            // initialStore.ui.width = innerWidth;
            // initialStore.ui.height = innerHeight;
        } catch (e) {}
    }

    const history = historyInterceptor(createBrowserHistory(), initialStore.siteConfig);

    init: try {
        if (typeof process === 'undefined') {
            break init;
        }

        if (process.env.CLIENT_RENDER) {
            //grab ab test param to force test on CSR
            const query = Helpers.getSearch(location.search);
            initialStore.testFlags = JSON.parse(query.abtest);
        }
        //set mpulse dimension
        window.testConfig =
            initialStore.slugInfo.templateKey ||
            (initialStore.pdp && initialStore.pdp.pdpConfig && initialStore.pdp.pdpConfig.templateKey);
        const environment = window.storeEnvironment || process.env.NODE_ENV;
    } catch (e) {
        console.log('Error in app initialization', e);
    }

    // inialize store with all available routes, since we only host a subset of urls in react
    // this helps us identify if an internal url can be handled by react router of if needs to direct to ETR
    // this is set on the server, incase SSR is not working initialize it for CSR
    initialStore.routes = initialStore.routes || getRoutes('', true);
    const store = configureStore(history, initialStore);
    const { dispatch, getState } = store;
    window.store = store;
    window.logErrors = debugPage;

    /**
     * Add single event listener for window resize store dimensions in redux
     */
    window.addEventListener(
        'resize',
        debounce(() => {
            store.dispatch(screenResize());
        }, 50),
    );

    window.addEventListener('keyup', e => {
        let charCode = e.keyCode || e.which;
        if (charCode === 9 && elementIsOffscreen(document.activeElement, 0, 200)) {
            document.activeElement.scrollIntoView({ block: 'center' });
        }
    });

    // add event listener for beforeunload to track last known scroll postion
    window.addEventListener('beforeunload', event => {
        Helpers.saveLastScrollPosition();
    });

    // expose redux action in global scope
    window.appActions = window.appActions || {};
    window.appActions.addToCart = (product, qty = 1, blockFlyout) => {
        try {
            const { productType: pt, product_type } = product;
            const productType = pt || product_type;
            const catEntryID = Helpers.getCatEntryId(product);
            if (!productType) {
                return Promise.reject(`Missing required parameter: productType`);
            }
            if (!catEntryID) {
                return Promise.reject(`Missing required parameter: catEntryID`);
            }
            if (productType.toLowerCase() === 'cto') {
                const ctoLink = getCTOLink(catEntryID, qty);
                history.push(ctoLink, getState());
                return Promise.resolve();
            }
            return addToCart(
                product,
                qty,
                blockFlyout,
            )(dispatch, getState).then(resp => {
                !blockFlyout && dispatch(launchCartFlyout({}));
            });
        } catch (e) {
            return Promise.reject(e);
        }
    };
    window.appActions.changeLocation = path => history.push(path, getState());
    window.appActions.fetchPrices = fetchPrices(dispatch, getState);
    window.appActions.getState = deps => {
        try {
            const keys = (deps || []).filter(k => ['userData', 'cartInfo'].includes(k));
            if (keys.length > 0) {
                const state = getState();
                return keys.reduce((r, k) => {
                    r[k] = state[k];
                    return r;
                }, {});
            }
            return {};
        } catch (e) {
            return {};
        }
    };

    window.appActions.events = {
        subscribe: (event, callback) => MetricsObserver.subscribe(event, callback),
        unsubscribe: (event, callback) => MetricsObserver.unsubscribe(event, callback),
    };

    // expose helper function for generating product analytics from external dependencies
    window.guxAnalytics = window.guxAnalytics || {};
    window.guxAnalytics.currencyCode = 'USD';
    window.guxAnalytics.addElicitProductsToAnalyticMap = addElicitProductsToAnalyticMap;
    window.guxAnalytics.getCartId = getCartId;
    window.guxAnalytics.addElicitPromotionsToAnalyticMap = addElicitPromotionsToAnalyticMap;

    const content = (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ScrollToTop>
                    <App dispatch={store.dispatch} suppressHydrationWarning={true} />
                </ScrollToTop>
            </ConnectedRouter>
        </Provider>
    );

    if (process.env.CLIENT_RENDER) {
        createRoot(document.getElementById('root')).render(content);
    } else {
        loadableReady(() => {
            hydrateRoot(document.getElementById('root'), content);
        });
    }

    if (module.hot) {
        module.hot.accept();
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';

class Namogoo extends React.Component {
    constructor(props) {
        super(props);
        this.namogooScript = "//dfapvmql-q.global.ssl.fastly.net/HP7F96O2J.js";
        this.scriptLoaded = false;

        this.handleScriptCreate = this.handleScriptCreate.bind(this);
        this.handleScriptError = this.handleScriptError.bind(this);
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
    }

    componentDidMount() {
        // UDL tracking
        if(this.props.trackName){
            dataLayer.push({
                event:'e_linkClick',
                linkPlacement:'namogoo',
                linkID: this.props.trackName,
            });
            return;
        }
    }

    // add logging ? 
    handleScriptCreate() {
      this.scriptLoaded = false;
    }
     
    handleScriptError() {
      this.scriptLoaded = false;
    }
     
    handleScriptLoad() {
      this.scriptLoaded = true;
    }

    render() {
        return this.props.deploy && <Script 
                                        url={this.namogooScript}
                                        onCreate={this.handleScriptCreate}
                                        onError={this.handleScriptError}
                                        onLoad={this.handleScriptLoad}
                                    />;
    };
}

Namogoo.defaultProps = {
    deploy: false,
    trackName: null,
};

Namogoo.propTypes = {
    deploy: PropTypes.bool,
    trackName: PropTypes.string,
};

export default Namogoo;
import React, { useEffect } from 'react';

/**
 * Dispatches a hydration event so that third-party scripts can execute once hydration is done
 */
export default ({}) => {
    useEffect(() => {
        dispatchEvent(
            new CustomEvent('hydrated', {
                bubbles: true,
                detail: `Page hydrated at ${Date.now()}`,
            })
        );
    }, []);

    return null;
};

import ActionTypes from './action-types';
import {
    addPromotionsMap,
    formatMetricValue,
    getUniquePromotionId,
    getAvailableConfiguratorStartParams,
    getDerivedProductMetricsV2,
    generateUniqueProductMapKey,
    getCartGtmId
} from './metrics-helpers';
import { getVwaDynamicBannerComponent } from '../vwa/util';

// refactor later and move to UDL API
const mapReduxToGTM = {};

export const STORE_TRACK_PREFIX = '-us:hho';
// map redux actions to GTM events
mapReduxToGTM[ActionTypes.ADD_TO_CART] = 'e_addToCart';
mapReduxToGTM[ActionTypes.REMOVE_FROM_CART] = 'e_removeFromCart';
mapReduxToGTM[ActionTypes.SORT_PRODUCTS] = 'e_facet';
mapReduxToGTM[ActionTypes.LOCATION_CHANGE] = 'e_pageView';
mapReduxToGTM[ActionTypes.TOGGLE_COMPARE_MODAL] = 'e_compareModels';
mapReduxToGTM[ActionTypes.RECEIVE_VARIANT_INFO] = 'e_linkClick';
mapReduxToGTM[ActionTypes.UPDATE_CART] = 'updateCart';

export const RECEIVE_PAGE_VIEW_URL = 'RECEIVE_PAGE_VIEW_URL';
export const RECEIVE_IMPRESSIONS = 'RECEIVE_IMPRESSIONS';
export const RECEIVE_DELAYED_IMPRESSION_URL = 'RECEIVE_DELAYED_IMPRESSION_URL';
export const RECEIVE_CUSTOM_METRICS = 'RECEIVE_CUSTOM_METRICS';
export const UPDATE_BATCHED_IMPRESSIONS = 'UPDATE_BATCHED_IMPRESSIONS';
export const RECEIVE_PAGE_VIEW_WITH_DELAYED_METRICS = 'RECEIVE_PAGE_VIEW_WITH_DELAYED_METRICS';

export const GTM_EVENTS = Object.assign({}, mapReduxToGTM);

//map adobe eVar values to UDL parameters https://docs.google.com/document/d/16YPn5Byr7XS6S1A7yDiAPdihWujScyyuU_yE2t2Oix0
const eVarMap = {
    evar75: 'v151',
    evar76: 'v152',
    evar77: 'v153',
    evar78: 'v154',
    evar79: 'v155',
};

const getABTestParams = (testFlags, path) => {
    //for now just scane through redirect test to find the one matching the current URL
    try {
        const { redirects = [], ssrTest } = testFlags;
        let params = {};
        let decodedPath = decodeURIComponent(path);
        let ssrTestKeys = Object.keys(ssrTest);

        //find first match in either target or redstination in all redirects
        let abTest;

        for (let i = 0; i < redirects.length; i++) {
            //find first path match in either target or destination
            let test = redirects[i];
            var testTargets = Object.keys(test.targeting);
            for (let z = 0; z < testTargets.length; z++) {
                let target = decodeURIComponent(testTargets[z]);
                let destination = decodeURIComponent(test.targeting[target]);
                if (target === decodedPath || destination === decodedPath) {
                    abTest = test;
                    break;
                }
            }
            if (abTest) {
                break;
            }
        }

        //only use provided evar tracking for now, could optional use testname and
        //vartion which UDL autmatically sets for eVar25 - 27 (supports three test)
        if (abTest && abTest.action && abTest.action.tracking) {
            const {
                action: { tracking },
            } = abTest;
            //add evar valyes from tracking
            params = Object.keys(tracking).reduce((r, k) => {
                let udlVar = eVarMap[k.toLowerCase()];
                //only use range support by UDL
                if (udlVar) {
                    r[udlVar] = tracking[k];
                }
                return r;
            }, {});
        }

        //currently no URL targeting for SSR test, just run through and apply eVars for active ssrTests
        if (ssrTestKeys && ssrTestKeys.length > 0) {
            for (let i = 0; i < ssrTestKeys.length; i++) {
                const key = ssrTestKeys[i];
                const test = ssrTest[key];
                const { action = {} } = test;
                const { tracking = {} } = action;
                //add evar valyes from tracking
                params = Object.keys(tracking).reduce((r, k) => {
                    let udlVar = eVarMap[k.toLowerCase()];
                    //only use range support by UDL
                    if (udlVar) {
                        r[udlVar] = tracking[k];
                    }
                    return r;
                }, params);
            }
        }

        return params;
    } catch (e) {
        return {};
    }
};

export function _readReview(params) {
    try {
        return {
            event: 'e_readReview',
            productName: params.productName,
        };
    } catch (e) {}
}

export const _searchAutoClick = params => {
    try {
        let { clickedTerm, searchTerm, searchCategory } = params || {};
        return {
            event: 'e_searchAutoClick',
            clickedTerm,
            searchTerm,
            searchCategory,
        };
    } catch (e) {}
};

export const _searchResults = params => {
    try {
        let { numSearchResults, searchTerm, hsResID } = params || {};
        return {
            event: 'e_searchResults',
            numSearchResults: typeof numSearchResults === 'number' ? '' + numSearchResults : numSearchResults,
            searchTerm, 
            ...(hsResID ? { hsResID } : {}),
        };
    } catch (e) {}
};

export const _productReview = params => {
    try {
        let { productName } = params || {};
        return {
            event: 'e_productReviewed',
            productName,
        };
    } catch (e) {}
};

export const _chat = params => {
    try {
        let { chatAction } = params || {};
        return {
            event: 'e_chat',
            chatAction,
        };
    } catch (e) {}
};

export const _videoLoad = params => {
    try {
        let { videoName, videoDuration } = params || {};
        return {
            event: 'e_videoLoad',
            videoName,
            videoDuration,
        };
    } catch (e) {}
};

export function _addToCart(prds, currencyCode, orderId, metricParams, state) {
    let { slugInfo } = state || {};
    let { analyticsData } = slugInfo || {};
    let { page_level } = analyticsData || {};

    try {
        window.guxAnalytics = window.guxAnalytics || {};
        window.guxAnalytics.cartId = orderId;
    } catch (e) {}
    let params = {
        event: GTM_EVENTS[ActionTypes.ADD_TO_CART],
        cartID: orderId,
        ecommerce: {
            currencyCode,
            add: { products: [] },
        },
    };

    // add list of products into add array
    params.ecommerce.add.products = prds.reduce(
        (allPrds, { brand, category, id, name, price, quantity, xsellProdInfo, xsellMethod, derivedCategory } = v) => {
            if (!id) {
                return allPrds;
            }
            allPrds.push({
                brand,
                category: derivedCategory || `${category}:${brand}`,
                id,
                name,
                price,
                quantity,
                xsellProdInfo,
                xsellMethod: xsellMethod || page_level,
            });
            return allPrds;
        },
        []
    );

    try {
        if (metricParams && metricParams.list) {
            params.ecommerce.add.detail = {
                actionField: {
                    list: metricParams.list,
                },
            };
        }
    } catch (e) {}

    return params;
}

export function _removeFromCart(prds, currencyCode) {
    let params = {
        event: GTM_EVENTS[ActionTypes.REMOVE_FROM_CART],
        ecommerce: {
            currencyCode: currencyCode || 'USD',
            remove: { products: [] },
        },
    };

    // add list of products into remove array
    params.ecommerce.remove.products = prds.map(({ id, name, price, quantity, brand, category, variant } = v) => ({
        id,
        name,
        price,
        quantity,
        brand,
        category,
        variant,
    }));

    return params;
}

export function _updateCart(cartData, currencyCode) {
    const { items: cartItems } = cartData;

    let params = {
        event: GTM_EVENTS[ActionTypes.UPDATE_CART],
        ecommerce: {
            currencyCode: currencyCode || 'USD',
        },
        cartItems,
    };
    return params;
}

export function _productClick(params) {
    let _params = {
        event: 'e_productClick',
        ecommerce: {
            click: {
                actionField: {},
                products: [],
            },
        },
    };

    try {
        let { price, name, id, brand, category, position, list } = params;

        _params.ecommerce.click.actionField = { list };
        _params.ecommerce.click.products = [
            {
                price,
                name,
                id,
                brand,
                category,
                ...(position ? { position } : {}),
            },
        ];
    } catch (e) {}

    return _params;
}

export const parseProductImpressions = productImpressions =>
    Array.isArray(productImpressions)
        ? productImpressions.map(prdImpression => {
              let { brand, category, id, list, name, position, price, variant } = prdImpression || {};

              return {
                  brand,
                  category,
                  id,
                  list,
                  name,
                  position,
                  price,
                  variant,
              };
          })
        : [];

export function _productImpression(params) {
    let _params = {
        event: 'e_productImpression',
        ecommerce: {
            currencyCode: 'USD',
            impressions: parseProductImpressions(params && params.productImpressions),
        },
    };

    return _params;
}

export function _promotionImpression(params) {
    if (!params || !params.promotions) return null;

    return {
        event: 'e_promotionImpression',
        ecommerce: {
            promoView: {
                promotions: params.promotions,
            },
        },
    };
}

export function _promotionClick(params) {
    let { promoClickType, id, creative, position, name } = params || {};
    if (!id) return null;

    let promotionParams = {
        id,
        creative,
        position,
        name,
    };

    return {
        event: 'e_promotionClick',
        ...(promoClickType ? { promoClickType } : {}),
        ecommerce: {
            promoClick: {
                promotions: [promotionParams],
            },
        },
    };
}

export function _compare(compare_products) {
    let concatProductIDs = compare_products.map(x => x._id || x.sku).join(',');
    let concatProductNames = compare_products.map(x => x.name).join(',');
    return {
        event: GTM_EVENTS[ActionTypes.TOGGLE_COMPARE_MODAL],
        concatProductIDs,
        concatProductNames,
    };
}

export function _miniCartQuantityChange(params) {
    const { productIndex, quantityChange } = params || {};
    return productIndex && typeof quantityChange === 'number' ? {
        event: 'standards.miniCartQuantityChange',
        productIndex,
        quantityChange
    } : null;
}

export function _configuratorChange(params) {
    let { componentCategory, componentSelection, list, products, configuration } = params || {};
    return {
        event: 'e_configuratorChange',
        componentCategory,
        componentSelection,
        configuration,
        ecommerce: {
            detail: {
                actionField: { list },
                products,
            },
        },
    };
}

/**
 *
 * @param {*} params - derived product analytics object containing configuration
 * @returns
 */
export function _configuratorStart(params) {
    params = Array.isArray(params) ? params[0] : params;
    let { configuration } = params || {};

    let products = {
        ...(params || {}),
    };
    if (products.configuration) delete products.configuration;

    let { brand, category, id, list, name, position, price, variant } = products || {};

    return {
        event: 'e_configuratorStart',
        ...(configuration ? { configuration } : {}),
        ecommerce: {
            detail: {
                actionField: { list: 'cto' },
                products: [{ brand, category, id, list, name, position, price, variant }],
            },
        },
    };
}

export function _configuratorComplete(params) {
    let { product, configuration } = params || {};

    let { brand, category, id, list, name, position, price, variant } = product || {};

    return {
        event: 'e_configuratorComplete',
        ...(configuration ? { configuration } : {}),
        ecommerce: {
            detail: {
                actionField: { list: 'cto' },
                products: [{ brand, category, id, list, name, position, price, variant }],
            },
        },
    };
}

export function _pageViewOld(page, user, products, state = { router: { location: {} } }, dispatch) {
    //add ab test params based on tests and current pathname
    let {
        testFlags,
        router: {
            location: { pathname },
        },
        slugInfo,
    } = state;
    let { vanityUrl } = slugInfo || {};
    let { metrics } = state || {};
    let { pageViewWithDelayedMetrics } = metrics || {};
    let abTest = getABTestParams(testFlags, pathname);
    let { customerSegment } = user || {};
    let {
        pageNameL8,
        lifecycle,
        market_segment,
        target_country,
        promotionImpressions,
        productImpressions,
        postPageViewActionParameters,
    } = page || {};

    let params = {
        event: GTM_EVENTS[ActionTypes.LOCATION_CHANGE], // required
        // pageNameL8: page.pageNameL8,     // page detailed category
        userTypeSession: user.tier,
        loginStatus: user.logged_in,
        pageBusinessUnit: page.businessUnit,
        callCenter: user.isCallCenter,
        pageNameL5: page.pageNameL5, // page type
        pageNameL6: page.pageNameL6, // page category
        pageNameL7: page.pageNameL7, // page sub category
        ...(pageNameL8 ? { pageNameL8 } : {}),
        ...(lifecycle ? { lifecycle } : {}),
        ...(market_segment ? { market_segment } : {}),
        ...(target_country ? { target_country } : {}),
        ...(customerSegment ? { customerSegment } : {}),
    };

    let isPdp = params.pageNameL5 === 'pdp';

    if (promotionImpressions && promotionImpressions.length > 0 && !isPdp) {
        params.ecommerce = params.ecommerce || {};
        params.ecommerce.promoView = {
            promotions: promotionImpressions,
        };
    }

    if (!isPdp && productImpressions && productImpressions.length > 0) {
        params.ecommerce = params.ecommerce || {};
        params.ecommerce.currencyCode = 'USD';
        params.ecommerce.impressions = productImpressions;
    }

    // pdps and cto pages
    if (page && Array.isArray(page.products)) {
        params.ecommerce = params.ecommerce || {};
        params.ecommerce.currencyCode = 'USD';
        params.ecommerce.detail = params.ecommerce.detail || {};
        params.ecommerce.detail.actionField = {
            list: page.pageNameL5 || 'pdp',
        };
        params.ecommerce.detail.products = parseProductImpressions(page.products);

        let eventCallbackTriggered;
        params.eventCallback = id => {
            if (pageViewWithDelayedMetrics !== vanityUrl && !eventCallbackTriggered) {
                eventCallbackTriggered = true;
                setTimeout(() => {
                    dispatch(receivePageViewUrl(vanityUrl, true));
                }, 1000);
            }
        };
    }

    if (user.userID) {
        params.userID = user.userID;
    }

    if (postPageViewActionParameters) {
        params.postPageViewActionParameters = postPageViewActionParameters;
    }

    // re-visit later
    const { urlParams = {} } = page;
    const { blogSearchTerm, nor } = urlParams;
    if (blogSearchTerm) {
        params.searchTerm = 'blog:' + blogSearchTerm;
    }
    if (nor) {
        params.numSearchResults = 'blog:number_of_results:' + nor;
    }

    // legacy
    if (products) {
        params.ecommerce = {
            detail: {
                actionField: { list: `${products[0].name}` },
            },
        };

        // add list of products into pageview array
        params.ecommerce.detail.products = products.map(({ brand, category, id, name, price } = v) => ({
            category: `${category}:${brand}`,
            id,
            name,
            price,
        }));
    }

    // get evar tracking params from Optimizely
    if (
        window.optimizely &&
        typeof window.optimizely.get === 'function' &&
        window.optimizely.get('custom/adobeIntegrator')
    ) {
        window.optimizely.get('custom/adobeIntegrator').assignCampaigns(params);
    }
    //update mPulse dimensions
    if (page.templateKey) {
        window.testConfig = page.templateKey;
    }

    const pageViewData = { ...params, ...abTest };
    return pageViewData;
}

export const getSearchPageData = searchResults => {
    const { numSearchResults, searchTerm, hsResID } = searchResults || {};
    return {
        search: { 
            searchString: searchTerm,
            nrSearchResults: numSearchResults + '', 
            hsResID 
        }
    }
}

export const addTemplateSpecificPageProperties = (page, state) => {
    let { pageNameL5 } = page || {};
    let { slugInfo, metrics } = state || {};
    let { components } = slugInfo || {};
    let { hawksearchResults } = components || {};
    let { customMetrics } = metrics || {};
    let { searchResults } = customMetrics || {};
    const { numSearchResults, searchTerm, hsResID } = searchResults || {};


    const isSrp = pageNameL5 === 'search';
    if(isSrp && hawksearchResults && hawksearchResults.type === 'products'){
        return getSearchPageData(searchResults);
    }

    return {};
}

export function _pageView(page, user, products, state = { router: { location: {} } }, dispatch) {
    //add ab test params based on tests and current pathname
    let {
        testFlags,
        router: {
            location: { pathname },
        },
        slugInfo,
    } = state;
    let { vanityUrl, components } = slugInfo || {};
    let { hawksearchResults } = components || {};
    let { metrics } = state || {};
    let { customMetrics } = metrics || {};
    let { searchResults } = customMetrics || {};
    let { pageViewWithDelayedMetrics } = metrics || {};
    let abTest = getABTestParams(testFlags, pathname);
    let { customerSegment } = user || {};
    let {
        pageNameL8,
        lifecycle,
        market_segment,
        target_country,
        promotionImpressions,
        productImpressions,
        postPageViewActionParameters,
        pageNameL5,
        pageNameL6,
        pageNameL7
    } = page || {};

    let params = {
        // event: GTM_EVENTS[ActionTypes.LOCATION_CHANGE], // required
        // pageNameL8: page.pageNameL8,     // page detailed category
        userTypeSession: user.tier,
        loginStatus: user.logged_in,
        pageBusinessUnit: page.businessUnit,
        callCenter: user.isCallCenter,
        pageNameL5: page.pageNameL5, // page type
        pageNameL6: page.pageNameL6, // page category
        pageNameL7: page.pageNameL7, // page sub category
        ...(pageNameL8 ? { pageNameL8 } : {}),
        ...(lifecycle ? { lifecycle } : {}),
        ...(market_segment ? { market_segment } : {}),
        ...(target_country ? { target_country } : {}),
        ...(customerSegment ? { customerSegment } : {}),
    };

    let isCto = typeof vanityUrl === 'string' && /^\/?ConfigureView/i.test(vanityUrl);
    let isPdp = pageNameL5 === 'pdp';

    
    const parsedProductImpressions = Array.isArray(page.products) && page.products.length > 0 && parseProductImpressions(page.products);
    const mainSKU = parsedProductImpressions && parseProductImpressions[0];
    const { numSearchResults, searchTerm, hsResID } = searchResults || {};
    const isSrp = pageNameL5 === 'search';
    const pagePropertiesData = { 
        pageNameL5, // page type
        pageNameL6, // page category
        pageNameL7, // page sub category
        pageNameL8, 
        loginStatus: user.logged_in, 
        userTypeSession: user.tier, 
        customerSegment, //only if user logged in
        meta_lifecycle: lifecycle,
        meta_segment: market_segment,
        meta_bu: page.businessUnit, 
        callCenter: false, 
        siteType: 'store', 
        siteSubType: 'public', 
        errorPage: false, //for 404 pages set true 
        mainSKU:  window.pagePropertiesData &&  window.pagePropertiesData.mainSKU, // add for pdp
        ecommerce: { 
             currencyCode: 'USD',
        }, 
        ...addTemplateSpecificPageProperties(page, state)
    };

    try{
        if(isCto && !pagePropertiesData.mainSKU) {
            const { ctoProductInitial, ctoPrice } = state.slugInfo.components;
            const price = sku && ctoPrice ? { [sku]: ctoPrice } : {};
            pagePropertiesData.mainSKU = getDerivedProductMetricsV2(ctoProductInitial, price, { position: 1, list: 'cto' })
        }
    }catch(e){}

    try{
        if(isPdp && !pagePropertiesData.mainSKU) {
            const { productInitial } = state.slugInfo.components;
            const price = (state && state.productData && state.productData.productInfo && state.productData.productInfo.productPrices) || {};
            pagePropertiesData.mainSKU = getDerivedProductMetricsV2(productInitial, price, { position: 1, list: 'pdp' })
        }
    }catch(e){}

    // pagePropertiesData should only be set here, and on useHawkSearchActions because of the changing "search" field after the initial page view
    window.pagePropertiesData = undefined; // GTM detects it's a page change when this is set to undefined
    window.pagePropertiesData = pagePropertiesData;

    if (promotionImpressions && promotionImpressions.length > 0 && !isPdp) {
        params.ecommerce = params.ecommerce || {};
        params.ecommerce.promoView = {
            promotions: promotionImpressions,
        };
    }

    if (!isPdp && productImpressions && productImpressions.length > 0) {
        params.ecommerce = params.ecommerce || {};
        params.ecommerce.currencyCode = 'USD';
        params.ecommerce.impressions = productImpressions;
    }

    // pdps and cto pages
    if (page && Array.isArray(page.products)) {
        params.ecommerce = params.ecommerce || {};
        params.ecommerce.currencyCode = 'USD';
        params.ecommerce.detail = params.ecommerce.detail || {};
        params.ecommerce.detail.actionField = {
            list: page.pageNameL5 || 'pdp',
        };
        params.ecommerce.detail.products = parseProductImpressions(page.products);

        let eventCallbackTriggered;
        params.eventCallback = id => {
            if (pageViewWithDelayedMetrics !== vanityUrl && !eventCallbackTriggered) {
                eventCallbackTriggered = true;
                setTimeout(() => {
                    dispatch(receivePageViewUrl(vanityUrl, true));
                }, 1000);
            }
        };
    }

    if (user.userID) {
        params.userID = user.userID;
    }

    if (postPageViewActionParameters) {
        params.postPageViewActionParameters = postPageViewActionParameters;
    }

    // re-visit later
    const { urlParams = {} } = page;
    const { blogSearchTerm, nor } = urlParams;
    if (blogSearchTerm) {
        params.searchTerm = 'blog:' + blogSearchTerm;
    }
    if (nor) {
        params.numSearchResults = 'blog:number_of_results:' + nor;
    }

    // legacy
    if (products) {
        params.ecommerce = {
            detail: {
                actionField: { list: `${products[0].name}` },
            },
        };

        // add list of products into pageview array
        params.ecommerce.detail.products = products.map(({ brand, category, id, name, price } = v) => ({
            category: `${category}:${brand}`,
            id,
            name,
            price,
        }));
    }

    // get evar tracking params from Optimizely
    if (
        window.optimizely &&
        typeof window.optimizely.get === 'function' &&
        window.optimizely.get('custom/adobeIntegrator')
    ) {
        window.optimizely.get('custom/adobeIntegrator').assignCampaigns(params);
    }
    //update mPulse dimensions
    if (page.templateKey) {
        window.testConfig = page.templateKey;
    }

    const pageViewData = { ...params, ...abTest };
    // setPagePropertiesData(pageViewData)
    const eventTypeParam = {
        event: 'e_pageView'
    };
    try{
        return {
            ...pagePropertiesData,
            ...eventTypeParam
        }
    }catch(e){}
    return eventTypeParam;
}

export function _sort(searchSortType) {
    return {
        event: GTM_EVENTS[ActionTypes.SORT_PRODUCTS],
        sortType: searchSortType,
    };
}

export function _variantsSelection(variant) {
    const { sku } = variant;
    const linkId = `variant-selected:${sku}`;
    return {
        event: GTM_EVENTS[ActionTypes.RECEIVE_VARIANT_INFO],
        linkId,
    };
}

export const trackCustomMetric = (eventName, params) => dispatch =>
    dispatch({
        type: ActionTypes.TRACK_CUSTOM_METRIC,
        payload: {
            eventName,
            params,
        },
    });

export const receivePageViewUrl = (vanityUrl, forDelayedMetrics) => ({
    type: forDelayedMetrics ? RECEIVE_PAGE_VIEW_WITH_DELAYED_METRICS : RECEIVE_PAGE_VIEW_URL,
    payload: {
        vanityUrl,
    },
});

/**
 *
 * @param {*} vanityUrl of the page that last triggered delayed impressions
 * @returns - Redux Action object cotnaining the vanityUrl.
 *
 * This function is used to prevent duplicate delayed impressions, which can occur in the useDelayedImpression() hook when
 * switching between different templates, since this causes the root withPage() component to unmount and mount with parameters from the previous page.
 */
export const receiveDelayedImpressionsUrl = vanityUrl => ({
    type: RECEIVE_DELAYED_IMPRESSION_URL,
    payload: {
        vanityUrl,
    },
});

export const parseMetricParams = (eventName, params) => {
    switch (eventName) {
        case 'productClick': {
            return _productClick(params);
        }
        default:
    }

    return params;
};

export const getPromotionMapKey = (promotionId, positionId) => `${promotionId}-${positionId}`;

let globalPromotions = {};
export const setGlobalPromotions = (key, promotion) => {
    try{
        globalPromotions[key] = promotion;
    }catch(e){}
}

export const getGlobalPromotions = () => globalPromotions;
/**
 *
 * @param {*} dataArray
 * @param {*} id
 * @param {*} slugInfo
 * @param {*} state
 * @returns
 *
 * Performs side-effect, updates the window object's promotionMap
 */
export const getPromotionImpression = (dataArray, id, slugInfo, state) => {
    if (!Array.isArray(dataArray) || dataArray.length === 0) return null;

    let { analyticsData } = slugInfo || {};
    let { page_level } = analyticsData || {};
    // Analytics team: Use desktop values at all times.
    let { ui } = state || {};
    let { device } = ui || {};

    window.promotionsMap = window.promotionsMap || {};

    return dataArray.reduce((allImpressions, data, idx) => {
        let { title, titleDesktop, titleMobile, cta } = data || {};
        let { text, props } = cta || {};
        let { href } = props || {};
        let titleGtmValue = formatMetricValue(title || (device === 'mobile' ? titleMobile : titleDesktop));
        let idxPosition = idx + 1;
        let positionId = `${page_level}|${id}|${idxPosition}`;
        // let name = formatMetricValue(titleGtmValue);
        let promotionId = getUniquePromotionId(href, titleGtmValue, positionId); //`${id}-${idx + 1}`;
        let gtmUniqueId = getPromotionMapKey(promotionId, positionId);
        let promotionImpressionData = window.promotionsMap[gtmUniqueId];
        if (!promotionImpressionData) {
            promotionImpressionData = {
                id: promotionId,
                name: titleGtmValue,
                position: positionId,
                placement: id,
                autoNumber: idx + 1
                // creative: device ==='mobile' && mobile ? mobile : desktop // nothing right now. should be identifier for images used in different variations for an ab test.
            };
            addPromotionsMap(gtmUniqueId, promotionImpressionData);
        }

        //TODO Currently id is made up by us. Check if ETR API can assign this value instead.
        allImpressions.push(promotionImpressionData);

        return allImpressions;
    }, []);
};

export const getGlobalPromotionImpressions = (slugInfo, state) => {
    let { components } = slugInfo || {};
    let { valuePropContentSpot } = components || {};
    if (!valuePropContentSpot) return [];

    let { siteConfig } = state || {};
    let { enablePromotionImpressions } = siteConfig || {};

    return enablePromotionImpressions
        ? getPromotionImpression(
              valuePropContentSpot && valuePropContentSpot.highlightBanner
                  ? [valuePropContentSpot.highlightBanner]
                  : [],
              'valuePropContentSpot',
              slugInfo,
              state
          ) || []
        : [];
};

/**
 *
 * @param {*} slugInfo
 * @param {*} state
 * @returns parameters used for page view event, or to trigger other actions in metrics-middleware
 */
export const getDerivedPageViewParameters = (slugInfo, state) => {
    let promotionImpressions, productImpressions, products, configuratorStartProducts; // used for ConfigureView
    try {
        let { vanityUrl, components } = slugInfo || {};
        let { metrics, siteConfig } = state || {};
        let { enablePromotionImpressions, enableProductImpressions } = siteConfig || {};
        let { impressions, batchedImpressions } = metrics || {};
        let pageImpressions = impressions && impressions[vanityUrl];
        let globalPromotionImpressions = getGlobalPromotionImpressions(slugInfo, state);
        let promotions;
        // homepage
        if (vanityUrl === '/') {
            let {
                homeHeroBanner, //id=home-hero
                homeShoppingAssistant, // id=home-hsa
                homeBannerThree, // home-featured
            } = components || {};

            let { secondaryBanner, featuredProducts, homePopularProducts } = pageImpressions || {};

            promotions = enablePromotionImpressions && [
                ...(getPromotionImpression(homeHeroBanner, 'homeHeroBanner', slugInfo, state) || []),
                ...(getPromotionImpression(homeShoppingAssistant, 'homeShoppingAssistant', slugInfo, state) || []),
                ...(getPromotionImpression(homeBannerThree, 'homeBannerThree', slugInfo, state) || []),
                ...(secondaryBanner || []),
            ];

            productImpressions = enableProductImpressions && [
                ...(featuredProducts || []),
                ...(homePopularProducts || []),
            ];
        } else if (/^slp/.test(vanityUrl)) {
            let { productTab } = pageImpressions || {};

            productImpressions = enableProductImpressions && [...(productTab || [])];
        }
        //category landing pages
        else if (/^cat/.test(vanityUrl)) {
            let { categoryHeroBanner, inkTonerExclusives, accessoriesContent } = components || {};

            let { innovationBanner, accessoriesTopSellers } = pageImpressions || {};

            promotions = enablePromotionImpressions && [
                ...(getPromotionImpression(categoryHeroBanner, 'categoryHeroBanner', slugInfo, state) || []),
                ...(getPromotionImpression(inkTonerExclusives, 'inkTonerExclusives', slugInfo, state) || []),
                ...(getPromotionImpression(
                    accessoriesContent &&
                        accessoriesContent.contentCardBanners &&
                        accessoriesContent.contentCardBanners.banners,
                    'accessoriesContent',
                    slugInfo,
                    state
                ) || []),
                ...(innovationBanner || []),
            ];

            productImpressions = enableProductImpressions && [...(accessoriesTopSellers || [])];
        }
        //category landing pages
        else if (/^vwa/.test(vanityUrl)) {
            let { vwaTopBanner, vwaResultsFooterBanner, vwaResultsInlineBanner, productListingHeroBanner } =
                components || {};

            let { finderResults } = pageImpressions;

            promotions = enablePromotionImpressions && [
                ...(getPromotionImpression(
                    getVwaDynamicBannerComponent(vwaResultsInlineBanner),
                    'vwaResultsInlineBanner',
                    slugInfo,
                    state
                ) || []),
                ...(getPromotionImpression(
                    getVwaDynamicBannerComponent(vwaResultsFooterBanner),
                    'vwaResultsFooterBanner',
                    slugInfo,
                    state
                ) || []),
                ...(getPromotionImpression(
                    getVwaDynamicBannerComponent(vwaTopBanner),
                    'vwaTopBanner',
                    slugInfo,
                    state
                ) || []),
                ...(getPromotionImpression(
                    getVwaDynamicBannerComponent(productListingHeroBanner),
                    'productListingHeroBanner',
                    slugInfo,
                    state
                ) || []),
            ];

            productImpressions = enableProductImpressions && [...(finderResults || [])];
        } else if (/^mdp/i.test(vanityUrl)) {
            let { mdpProductList } = pageImpressions;

            productImpressions = mdpProductList instanceof Array ? mdpProductList : null;
        } else if (/^pdp/i.test(vanityUrl)) {
            let { pdpSimilarProducts, productInitial, pdpAccessories, pdpPaypalBanner } = pageImpressions;

            let { pdpSpecialOffers } = components || {};

            productImpressions = enableProductImpressions && [
                ...(Array.isArray(pdpSimilarProducts) ? pdpSimilarProducts : []),
                ...(Array.isArray(pdpAccessories) ? pdpAccessories : []),
            ];

            promotions = enablePromotionImpressions && [
                ...(getPromotionImpression(pdpSpecialOffers, 'pdpSpecialOffers', slugInfo, state) || []),
                ...(Array.isArray(pdpPaypalBanner) ? pdpPaypalBanner : []),
            ];

            products = productInitial;
        } else if (/^sitesearch/i.test(vanityUrl)) {
            let { searchProducts } = pageImpressions;

            productImpressions = enableProductImpressions && [...(Array.isArray(searchProducts) ? searchProducts : [])];
        } else if (/configureview/i.test(vanityUrl)) {
            let { ctoProductInitial } = pageImpressions;

            configuratorStartProducts = ctoProductInitial;
        } else if (/accessoryattachview/i.test(vanityUrl)) {
            let { accessoriesAttach } = pageImpressions || {};

            // include any impressions that are ready to page view event, trigger the rest of the lazy-loaded impressions on separate productImpression events
            productImpressions = enableProductImpressions && [
                ...(Array.isArray(accessoriesAttach) ? accessoriesAttach : []),
            ];
        } else if (/^dlp/i.test(vanityUrl)) {
            let { searchFilter } = pageImpressions || {};

            productImpressions = enableProductImpressions && [...(Array.isArray(searchFilter) ? searchFilter : [])];
        }

        promotionImpressions = enablePromotionImpressions && [
            ...(promotions || []),
            ...(globalPromotionImpressions || []),
        ];
    } catch (e) {}

    return {
        promotionImpressions: promotionImpressions && promotionImpressions.length > 0 ? promotionImpressions : null,
        productImpressions:
            productImpressions && productImpressions.length > 0 ? parseProductImpressions(productImpressions) : null,
        products,
        configuratorStartProducts,
    };
};

export const receiveImpressions = ({
    vanityUrl,
    componentKey,
    impressions,
    batchKey,
    forPageView,
    impressionGroupSize,
    markTrackedProductsCallback,
}) => ({
    type: RECEIVE_IMPRESSIONS,
    payload: {
        impressions,
        vanityUrl,
        componentKey,
        batchKey,
        forPageView,
        impressionGroupSize,
        markTrackedProductsCallback,
    },
});

export const receiveCustomMetrics = ({ customMetrics }) => ({
    type: RECEIVE_CUSTOM_METRICS,
    payload: {
        customMetrics,
    },
});

export const triggerConfiguratorStartEventAfterPageView = params => {
    const configuratorStartParams = getAvailableConfiguratorStartParams(params);
    if (configuratorStartParams) {
        // window.dataLayer.push(configuratorStartParams);
    }
};

export const triggerSearchEvent = params => {
    try {
        if (params) {
            const { hsResID } = params || {};
            // window.dataLayer.push({
            //     event: 'e_searchResults',
            //     searchTerm: params.searchTerm || '',
            //     numSearchResults: params.numSearchResults + '',
            //     ...(hsResID ? { hsResID } : {}),
            // });
        }
    } catch (e) {}
};

export const triggerProductImpressions = productImpressions => {
    try {
        // window.dataLayer.push(_productImpression({ productImpressions }));
    } catch (e) {}
};

export const triggerPromotionImpressions = promotionImpressions => {
    try {
        // window.dataLayer.push(_promotionImpression({ promotions: promotionImpressions }));
    } catch (e) {}
};

export const triggerPostPageViewActions = params => {
    try {
        let { configuratorStartProducts, searchResults, productImpressions, promotionImpressions } = params || {};
        if (configuratorStartProducts) {
            triggerConfiguratorStartEventAfterPageView(configuratorStartProducts);
        }
        if (searchResults) {
            triggerSearchEvent(searchResults);
        }
        if (productImpressions) {
            triggerProductImpressions(productImpressions);
        }

        if (promotionImpressions) {
            triggerPromotionImpressions(promotionImpressions);
        }
    } catch (e) {}
};

const getDiscountAmount = (salePrice, regularPrice) => {
    let discountAmount = Number((Number(regularPrice) - Number(salePrice)).toFixed(2));
    return !isNaN(discountAmount) ? discountAmount : null;
}

export function setCartDataAnalytics(cartInfo, cartId, prices){
    try{
        const { items, sTot } = cartInfo || {};
        let position = 0;
        const cartProducts = items.reduce((products, item) => {
            const { pNum, name, qty, pdpUrl, img, amt } = item || {};
            const { fg, nAmt } = amt || {};
            const isFree = fg && nAmt == 0;
            const priceData = prices && prices[pNum];
            const { salePrice, regularPrice } = priceData || {};
            if(salePrice || regularPrice || isFree){
                const discountAmount = getDiscountAmount(salePrice, regularPrice);
                const cartPrd = {
                    ...getDerivedProductMetricsV2(item, prices, { position: ++position, list: 'cart' }) || {},
                    ...isFree ? { price: 0 } : {}
                };
                products[getCartGtmId(item)] = {
                    ...cartPrd,
                    ...typeof discountAmount === 'number' ? { discountAmount } : {}
                }
            }

            return products;
        }, {})
        const cartTotal = typeof sTot === 'number' ? sTot : typeof sTot === 'string' ? Number(sTot.replace(/\,/g, '')) : null;
        window.cartData = {
            ...typeof cartTotal === 'number' ? { cartTotal } : {},
            cartId,
            cartProducts
        }
    }catch(e){}
}

export function addImpressionsToProductMapAndTrigger(impressions){
    try{
        if(Array.isArray(impressions)){
            let keys = impressions.reduce((allKeys, imp) => {
                let { list, id, position, gtmUniqueId } = imp || {};
                let key = generateUniqueProductMapKey({ id, list, position })
                // let key = `impression-${list}-${id}-${position}-${productMapLength++}`;
                if(window.productMap[gtmUniqueId]){
                    // window.productMap[key] = imp;
                    allKeys.push(gtmUniqueId)
                }
                return allKeys;
            }, [])

            keys.length > 0 && window.dataLayer.push({event: 'standards.productsChange', productsChanged: keys});  
        }
    }catch(e){}
}
import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
    
    componentDidUpdate(prevProps) {
        let leadSlash = new RegExp(/(^\/)|(\/$)/g);
    	let [ currDir, currSlug, currPg ] = prevProps.location.pathname.replace(leadSlash, "").split("/");
    	let [ dir, slug, pg ] = this.props.location.pathname.replace(leadSlash, "").split("/");
        
        if (currDir !== dir || currSlug !== slug) {
           window.scrollTo(0, 0)
        }
    }
  
    render() {
        return this.props.children
    }
}
  
  export default withRouter(ScrollToTop);
/*@flow*/
/* global clearTimeout, setTimeout */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { connect } from 'react-redux';

import { getDeviceType } from '../../../ui';
import ConditionalWrapper from '../conditional-wrapper';

//modified from https://github.com/electrode-io/above-the-fold-only-server-render

/**
A component for configurable skip loading.
@examples
```jsx
<AboveTheFoldOnlyServerRender skip={true}>
  <Footer />
</AboveTheFoldOnlyServerRender>
```
@component AboveTheFoldOnlyServerRender
@import {AboveTheFoldOnlyServerRender}
@playground
AboveTheFoldOnlyServerRender
```
<AboveTheFoldOnlyServerRender skip={true}>
  <Footer />
</AboveTheFoldOnlyServerRender>
```
@returns {ReactElement} The rendered component
*/

class AboveTheFoldOnlyServerRender extends Component {
    constructor(props, context) {
        super(props, context);
        if (props.robots && props.isBot) {
            //always render components on SSR for bots
            this.state = { visible: true };
        } else if (props.skip || props.renderOn) {
            this.state = { visible: false };
        } else if (props.skipEval) {
            //pass deviceType to help make decision about SSR
            this.state = { visible: !props.skipEval(props.deviceType, props.pageOptimization, props.location) };
        } else {
            this.state = { visible: !get(context, props.contextKey, false) };
        }

        this._onShow = this._onShow.bind(this);
    }

    componentDidMount() {
        if (!this.state.visible) {
            const { showTimeout, renderOn, idleBuffer } = this.props;
            if (renderOn === 'load' && typeof document !== 'undefined' && typeof window !== 'undefined') {
                if (document.readyState === 'complete') {
                    this.timeout = setTimeout(this._onShow, idleBuffer);
                } else {
                    window.addEventListener(
                        'load',
                        () => {
                            this.timeout = setTimeout(this._onShow, idleBuffer);
                        },
                        { once: true, passive: true },
                    );
                }
            } else if (renderOn === 'idle' && typeof window !== 'undefined' && window.requestIdleCallback) {
                let bufferTime = idleBuffer;
                try {
                    bufferTime = bufferTime;
                } catch (e) {}
                this.timeout = setTimeout(() => requestIdleCallback(this._onShow), bufferTime);
            } else {
                this.timeout = setTimeout(this._onShow, showTimeout);
            }
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }

    _onShow() {
        this.setState({ visible: true, clientSide: true });
        if (this.props.offset > 0) {
            forceCheck();
        }
    }

    render() {
        const { placeholder, placeholderClassName, placeholderStyle, offset, once, height, overflow, forceLoad } =
            this.props;
        const { visible, clientSide } = this.state;
        let placeHolderElem = placeholder || <div className={placeholderClassName} style={placeholderStyle}></div>;

        if (visible || forceLoad) {
            return (
                <ConditionalWrapper
                    condition={!forceLoad && offset >= 0 && clientSide}
                    wrapper={children => (
                        <LazyLoad
                            once={once}
                            offset={offset}
                            height={height}
                            resize={true}
                            overflow={overflow}
                            placeholder={placeHolderElem}
                        >
                            {children}
                        </LazyLoad>
                    )}
                >
                    {this.props.children}
                </ConditionalWrapper>
            );
        }

        return placeHolderElem;
    }
}

AboveTheFoldOnlyServerRender.propTypes = {
    /**
  Children to render when visible
  */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

    /**
  Tell AboveTheFoldOnlyServerRender to read context in order to skip server side rendering
  */
    contextKey: PropTypes.string,

    /**
  Pass in another element to render when skipping server side rendering
  */
    placeholder: PropTypes.element,

    /**
  Sets the className of the default placeholder
  */
    placeholderClassName: PropTypes.string,

    /**
  Sets the style of the default placeholder
  */
    placeholderStyle: PropTypes.object,

    /**
  Tell AboveTheFoldOnlyServerRender to skip server side rendering
  */
    skip: PropTypes.bool,
    /**
     * Callback function for server to skip rendering, skip prop has precedence over this prop
     */
    skipEval: PropTypes.func,
    /**
     * Timeout for setting visible state
     */
    showTimeout: PropTypes.number,
    /**
     * Render modes
     *  idle - Will use requestIdleCallback if browser supports it, otherwise falls back to timeout
     *  load - after load event
     */
    renderOn: PropTypes.oneOf(['idle', 'load', '']),
    /***
     * Adds a timeout before before invoking the idle callback or after load event
     */
    idleBuffer: PropTypes.number,
    /**
     * Offet dictates render will occur once the component is with the offset distance from the screen
     */
    offset: PropTypes.number,
    /**
     * Component key use with loadLazyComponent hook
     */
    componentKey: PropTypes.string,
    /**
     * Is true load component without waiting
     */
    forceLoad: PropTypes.bool,
    /**
     * True is current user agent is a known bot
     */
    isBot: PropTypes.bool,
    /**
     * If true, will bypass logic and force display for bots
     */
    robots: PropTypes.bool,
};

AboveTheFoldOnlyServerRender.contextTypes = {
    aboveTheFoldOnlyServerRender: PropTypes.object,
};

AboveTheFoldOnlyServerRender.defaultProps = {
    skip: false,
    skipEval: undefined,
    once: true,
    showTimeout: 50,
    idleBuffer: 0,
    isBot: false,
    robots: true,
};

const mapStateToProps = (state, ownProps) => {
    const { componentKey } = ownProps;
    //possible tempory for controlling this optimization from page component
    const { slugInfo, ui, router, lazyLoadComponent } = state;
    const { isBot } = ui;
    //safecheck for CSR, otherwise propTypes throwing typecheck err.
    const deviceType = getDeviceType(ui.device);
    const { components = {} } = slugInfo;
    const { pageOptimization } = components;
    const { location } = router || {};
    const {} = lazyLoadComponent;
    const forceLoad = !!lazyLoadComponent[componentKey];
    return { deviceType, pageOptimization, location, forceLoad, isBot };
};

export default connect(mapStateToProps, null)(AboveTheFoldOnlyServerRender);

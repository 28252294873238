import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ImageWithPlaceholder from '../../../shared/components/image-with-placeholder';
import Link from '../../../shared/components/hyperlink';
import { Helpers } from '../../../core/src/helpers';
import QuantityPicker from '../../../product/components/quantity-picker';
import CartPreviewBadge from './cart-preview-badge';
import SubscriptionPricePer, { isSubscription, QuantityInput } from './subscription-item';
import useUserData from '../../../hooks/useUserData';
import useCartState from '../../../hooks/useCartState';
import DealViolator from '../../../product/components/deals/deal-violator';
import { MAX_QUANTITY, MAX_CALL_CENTER_QUANTITY } from '../../helpers';
import { getCartGtmId, trackCartQuantityChange } from '../../../metrics/metrics-helpers';

import '../../css/cart-preview.less';
import '../../css/new-cart-preview.less';

const EMPTY_ARRAY = [];
const DEFAULT_TRANSLATIONS = {
    dealsMessage: 'offer ends in {timeLeft}',
};

const handleQuantityChange = (handleQtyChange, subsMaxQty) => (qty, item) => {
    if (subsMaxQty && subsMaxQty * 1 < qty && isSubscription(item)) {
        alert(`Maximum limit of items per order for this product is ${subsMaxQty}.`);
        return;
    }
    handleQtyChange(qty, item);
};

const QuantitySection = ({ item, idx, isFree, subsMaxQty, handleQtyChange }) => {
    const { userData } = useUserData();
    const cartState = useCartState();
    const dispatch = useDispatch();
    const { cartId } = cartState || {};
    const isccf = userData?.profileData?.isccf;

    let { pType, subType, pNum } = item || {};
    let isGiftcard = ['PGC', 'VGC'].includes(pType);
    const enabled = true;
    const isSubscriptionSKU = isSubscription(item);
    const isVCP = subType === 'VCP';
    const addSelectGtmAttributes = useCallback((value, currQty) => {
        return pNum ? {
            'data-gtm-category': value < currQty ? 'removeFromCart' : 'addToCart',
            'data-gtm-id': getCartGtmId(item),
            'data-gtm-value': 'cart',
            'data-gtm-quantity': Math.abs(currQty - value),
            'data-gtm-cart-id': cartId,
            'data-gtm-object': 'cartProducts'
        } : {};
    }, [pNum, cartId]);

    const trackQuantityChange = useCallback((quantityChange) => {
        trackCartQuantityChange(quantityChange, getCartGtmId({ pNum }), dispatch);
    }, [dispatch, pNum])

    return (
        <div
            className={`mcprodqty${isFree ? ' free' : ''}${isGiftcard ? ' giftcard-cart-item' : ''}${
                !enabled ? ' disabled' : ''
            }`}
        >
            <span>
                <label htmlFor={'qty_' + idx}> QTY:</label>
            </span>
            {isSubscriptionSKU && !isVCP ? (
                <QuantityInput
                    item={item}
                    handleQtyChange={handleQuantityChange(handleQtyChange, subsMaxQty)}
                    qtyMax={subsMaxQty}
                />
            ) : isFree || isGiftcard ? (
                <div className="no-select-qty">{item.qty}</div>
            ) : (
                <span className="qty-container">
                    <QuantityPicker
                        quantity={item.qty}
                        onPick={handleQuantityChange(handleQtyChange, subsMaxQty)}
                        checkInitialQty={false}
                        updateQuantityFromProps={true}
                        quantityMax={isccf ? MAX_CALL_CENTER_QUANTITY : MAX_QUANTITY}
                        quantityMin={0}
                        args={[item]}
                        enabled={enabled}
                        quantityOptions={subType === 'VCP' && [0, item.qty]}
                        data-metrics-event-name={'linkClick'}
                        data-metrics-event={'e_linkClick'}
                        data-metrics-link-id={'cartPreview:qty-selector:orig'}
                        addSelectGtmAttributes={addSelectGtmAttributes}
                        trackQuantityChange={trackQuantityChange}
                    />
                </span>
            )}
            {isGiftcard && (
                <span className="item-remove remove-giftcard-cart-item" onClick={() => handleQtyChange(0, item)}>
                    Remove
                </span>
            )}
        </div>
    );
};

export const OldCartProducts = ({
    cartInfo,
    handleQtyChange,
    maxCartItems,
    activeDeals,
    translations = DEFAULT_TRANSLATIONS,
}) => {
    const { items = EMPTY_ARRAY, subsMaxQty } = cartInfo;
    let displayItems = items.slice(0, maxCartItems);
    return displayItems.map((item, idx) => {
        let { id, amt, ceId } = item || {};
        const isFree = amt.fg && amt.nAmt == 0;
        const linkType = 'absolute';
        const onsale = Helpers.convertPriceToNumber(item.amt.iPrice) > Helpers.convertPriceToNumber(item.amt.nAmt);
        const activeDeal = (activeDeals || {})[ceId];
        if (!item || !item.id) {
            return null;
        }
        return (
            <div key={id} className={`productrow${idx === 0 ? ' first-prod' : ''}`}>
                <Link to={item.pdpUrl} linkType={linkType} className="mcprodimg">
                    <ImageWithPlaceholder src={Helpers.getCDNUrl('', item.img, 300)} />
                </Link>
                <div className="mcprodname">
                    <CartPreviewBadge cartItem={item} />
                    <Link
                        to={item.pdpUrl}
                        linkType={linkType}
                        dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(item.name))}
                    ></Link>
                    <span>{item.pNum}</span>
                </div>
                <QuantitySection
                    item={item}
                    idx={idx}
                    isFree={isFree}
                    handleQtyChange={handleQtyChange}
                    subsMaxQty={subsMaxQty}
                />
                <div className="mcprodprice">
                    {activeDeal && <DealViolator activeDeal={activeDeal} message={translations.dealsMessage} />}
                    {item.amt.fg || !onsale ? null : (
                        <span className="strike">{item.amt.iPrice ? '$' + item.amt.iPrice : ''}</span>
                    )}
                    {isFree ? <span>Free</span> : <span>{item.amt.nAmt ? '$' + item.amt.nAmt : ''}</span>}
                    <SubscriptionPricePer cartItem={item} />
                </div>
            </div>
        );
    });
};

/*new version, not in use, keep here in case need to revert in the future*/
export const NewCartProducts = ({ cartInfo, handleQtyChange, maxCartItems }) => {
    const { items = EMPTY_ARRAY } = cartInfo;
    let displayItems = items.slice(0, maxCartItems);
    return displayItems.map((item, idx) => {
        let _cItem = Object.assign({}, item);
        let { pType } = item || {};
        let isGiftcard = ['PGC', 'VGC'].includes(pType);
        const isFree = item.amt.fg && item.amt.nAmt == 0;
        const linkType = 'absolute';
        const onsale = Helpers.convertPriceToNumber(item.amt.iPrice) > Helpers.convertPriceToNumber(item.amt.nAmt);
        return (
            <div key={`idx-${item.pNum}`} className={`productrow${idx === 0 ? ' first-prod' : ''}`}>
                <Link to={item.pdpUrl} linkType={linkType} className="mcprodimg">
                    <ImageWithPlaceholder src={Helpers.getCDNUrl('', item.img, 300)} />
                </Link>
                <div className="mcprodname">
                    <Link
                        to={item.pdpUrl}
                        linkType={linkType}
                        dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(item.name))}
                    ></Link>
                    <span>{item.pNum}</span>
                </div>
                <div className={`mcprodqty${isFree ? ' free' : ''}`}>
                    {isFree ? (
                        <div className="no-select-qty">{item.qty}</div>
                    ) : (
                        <QuantityPicker
                            quantity={item.qty}
                            onPick={handleQtyChange}
                            checkInitialQty={false}
                            quantityMax={9}
                            quantityMin={0}
                            args={[item]}
                            enabled={true}
                            data-metrics-event-name={'linkClick'}
                            data-metrics-event={'e_linkClick'}
                            data-metrics-link-id={'cartPreview:qty-selector:var1'}
                        />
                    )}
                    {isGiftcard && (
                        <span
                            className="item-remove remove-giftcard-cart-item"
                            onClick={() => handleQtyChange(0, item)}
                        >
                            Remove
                        </span>
                    )}
                </div>
                <div className="mcprodprice">
                    {item.amt.fg || !onsale ? null : (
                        <span className="strike">{item.amt.iPrice ? '$' + item.amt.iPrice : ''}</span>
                    )}
                    {isFree ? <span>Free</span> : <span>{item.amt.nAmt ? '$' + item.amt.nAmt : ''}</span>}
                    {!isFree && (
                        <span className="remove" onClick={() => handleQtyChange(0, _cItem)}>
                            Remove
                        </span>
                    )}
                </div>
            </div>
        );
    });
};

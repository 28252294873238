import React from 'react';
import PropTypes from 'prop-types';

import { withError } from '../../shared/components/error-boundary';

/**
A component for client side AB testing new components
@examples
```jsx
<ABTest skip={true}>{
 (data) => {
    //use data from AB testing platform to conditionally render component
    return <CTA style={ backgroundColor: data.bgColor} />
 }
}
</ABTest>
```
@component ABTest
@import ABTest
@returns {ReactElement} The rendered component for an AB test
*/
class ABTest extends React.Component {
    // TODO: remove blockOrg after ab test
    static defaultProps = {
        timeout: 300,
        blockOrg: false,
        ssrRender: false,
    };

    static propTypes = {
        /**
         * Key value to map to experiment
         */
        experimentID: PropTypes.string.isRequired,
        /**
         * Maximum time ABTest wrapper will wait for client side AB testing platform to load.
         */
        timeout: PropTypes.number,
        /**
         If AB testing fails to load or is not preset, blockOrg will hide component if true. It otherwise allows you to fallback to original content
        */
        blockOrg: PropTypes.bool,
        /**
         * If true child component will sever side render. This could cause a reflow or change in appearance of page if experiment is being run above the fold and server side AB testing is not enabled
         */
        ssrRender: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            render: props.ssrRender,
        };
        this.setVariation = this.setVariation.bind(this);
    }

    componentDidMount() {
        this.cmpMounted = true;
        try {
            const { experimentID, timeout } = this.props;
            window.optlyUtils.abTestSubscribe(experimentID, this.setVariation);
            // fallback if variation callback is delayed/never made,
            // if timeout is 0 cmponenet childerns will wait for Optimizely data to render
            !!timeout &&
                setTimeout(() => {
                    if (this.state.render === false) {
                        this.setVariation();
                    }
                }, timeout);
        } catch (e) {
            // render original if failed to execute
            // remove this after pdp test
            const { blockOrg } = this.props;
            !blockOrg && this.setVariation();
        }
    }

    componentWillUnmount() {
        //set to false to prevent abTestSubscribe
        //function to call setState on an unmounted component
        this.cmpMounted = false;
    }

    setVariation(data = {}) {
        this.cmpMounted && this.setState({ render: true, ...data });
    }

    render() {
        return this.state.render ? this.props.children(this.state) : null;
    }
}

export const useClientSideABTest = (experimentKey, timeout = 300) => {
    const [variation, setVariation] = React.useState({});
    React.useEffect(() => {
        if (!experimentKey) return;
        try {
            window.optlyUtils.abTestSubscribe(experimentKey, (data = {}) => setVariation({ render: true, ...data }));
            // fallback if variation callback is delayed/never made,
            !!timeout &&
                setTimeout(() => {
                    if (variation === null) {
                        setVariation({});
                    }
                }, timeout);
        } catch (e) {
            // render original if failed to execute
            setVariation({});
        }
    }, [experimentKey]);

    return variation;
};

export default withError(ABTest);

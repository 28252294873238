import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import findLastIndex from 'lodash/findLastIndex';
import { Helpers } from '../core';
import { getSearchAutoClickGtmAttributes } from '../metrics/metrics-helpers';

const getTransformedFacetValues = (facetObject, facetKey, transformFacetValue) =>
    facetObject && Array.isArray(facetObject.Values)
        ? facetObject.Values.map(fv => {
              transformFacetValue && transformFacetValue(fv);
              fv.facetKey = facetKey;
              return fv;
          })
        : [];

const getDefaultFacetObject = (Name, Field) => ({
    FacetType: 'checkbox',
    Field,
    FieldType: 'string',
    Height: 150,
    HiddenValues: [],
    IsCollapsedDefault: false,
    IsCollapsible: true,
    IsCurrency: false,
    IsNumeric: false,
    IsSearch: false,
    IsVisible: true,
    MaxCount: 20,
    MinHitCount: 1,
    Name,
    Values: [],
});

const isNumeric = val => typeof val === 'number' || (typeof val === 'string' && val.length > 0 && !isNaN(val));

const getValidProductBadge = (prdClass, skuType) => {
    let productBadge;
    if (typeof prdClass === 'string') {
        productBadge = /CTO/i.test(prdClass) ? 'CUSTOMIZABLE' : /bundle/i.test(prdClass) ? 'BUNDLE' : null;
    }

    if (!productBadge && /arvato/i.test(skuType)) {
        productBadge = 'DIGITAL';
    }

    return productBadge;
};

const getTopCategory = doc => {
    try {
        let { dte_facet_category } = doc || {};
        let topCategory = dte_facet_category[0];

        if (/laptops/i.test(topCategory)) {
            return 'Laptops';
        }
        return topCategory;
    } catch (e) {}
};

/**
 * Helper function to run through hawksearch results values. Assumes that if array length is 1, it's a single value.
 * @param {*} values
 * @returns
 */
const mapValues = values => {
    return Object.keys(values).reduce((acc, key) => {
        const value = values[key];
        if (Array.isArray(value) && value.length === 1) {
            acc[key] = value[0];
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});
};

export const transformProduct = (doc, basename, options) => {
    let product = {};
    const { includeRest, optimizeImage, pickImage, disableMktDiff, getGtmUniqueId } = options || {};
    if (!doc) return product;

    let {
        sku,
        product_name,
        catentry_id,
        dte_rule_x_ratings,
        bvr_tot_rvw_count,
        bundle_catentry_id,
        bundle_sku,
        mktdiff,
        usertype,
        energyeffcomp,
        isnew,
        dte_eolprinter_message,
        product_class,
        pdp_url,
        skutype,
        pm_category,
        facet_formfactor,
        position, // for analytics
        facet_segment,
        overview,
        loyalty_multiplier,
        carepacktype,
        iseolprinter,
        dte_eolpc_message,
        violatormsg_short,
        sustainability_logo_attribute,
        previewimage,
        productimage,
        specialoffer,
        devdependency,
        product_type,
        gtmUniqueId,
        ...rest
    } = doc;

    // TODO: might change based on Mahesh's reply about how topCategory is determined for VWAs
    product.topCategory = getTopCategory(doc);
    product.pm_producttype = product.topCategory;
    product.pm_category = pm_category && pm_category[0];
    product.facet_formfactor = facet_formfactor && facet_formfactor[0];
    product.usageLabel = Helpers.dedupArray(facet_segment);
    product.loyalty_multiplier = loyalty_multiplier && loyalty_multiplier[0];
    product.position = position; // for analytics
    product.sustainability_logo_attribute = sustainability_logo_attribute && sustainability_logo_attribute[0];
    if (gtmUniqueId) {
        product.gtmUniqueId = gtmUniqueId;
    } else if (typeof getGtmUniqueId === 'function') {
        product.gtmUniqueId = getGtmUniqueId(product);
    }
    if (specialoffer instanceof Array) {
        product.specialOffers = specialoffer.reduce((allOffers, specialOffer) => {
            if (typeof specialOffer === 'string') {
                allOffers.push({
                    title: specialOffer,
                });
            }
            return allOffers;
        }, []);
    }
    if (violatormsg_short && violatormsg_short.length > 0) {
        product.violatorMessage = violatormsg_short;
    }

    // name
    product.name = product_name && product_name[0];
    if (pickImage) {
        const [image] = previewimage || productimage || [];
        product.images = image ? [{ src: optimizeImage ? Helpers.getCDNUrl(image) : image }] : undefined;
    } else {
        try {
            const [image] = previewimage || productimage || [];
            product.defaultProductImage = image
                ? [{ src: optimizeImage ? Helpers.getCDNUrl(image) : image }, {}, {}]
                : [];
        } catch (e) {}
    }

    // bullet points on product card
    product.keyPoints = overview;

    if (isNumeric(dte_rule_x_ratings && dte_rule_x_ratings[0])) {
        product.rating = +dte_rule_x_ratings[0];
    }

    if (isNumeric(bvr_tot_rvw_count && bvr_tot_rvw_count[0])) {
        product.numReviews = +bvr_tot_rvw_count[0];
    }

    if (bundle_catentry_id) {
        product.bundleCatEntryIds = bundle_catentry_id;
    }

    if (devdependency) {
        const [Devdependency] = devdependency;
        product.Devdependency = Devdependency === 'N' ? 'NO' : Devdependency;
    }

    if (bundle_sku) {
        product.bundleSkus = bundle_sku;
    }
    // TODO: Confirm how this should be shown. VWA has it in % only (Ex: 3% back in HP Rewards)
    // if(isNumeric(doc.loyalty_multiplier && doc.loyalty_multiplier[0])){
    //     product.rewardsBadge = {
    //         label: "3% back in HP Rewards",
    //         tooltipBody: "HP Rewards is a loyalty program where users earn HP Rewards points by buying eligible HP Products.",
    //         tooltipTitle: "3% BACK IN HP REWARDS"
    //     }
    // }

    // catentry_id
    // TODO: Check if this logic is required:
    // const isParentPrd = product.prdClass === 'PRT' && product.sku === product.itemId;
    // this.props.viewPrice(isParentPrd ? product.prodId : product.itemId);
    product.itemId = Array.isArray(catentry_id) && catentry_id[0];
    product.catentryId = product.itemId;

    product.sku = sku && sku[0];

    product.prdClass = product_class && product_class[0];
    product.isBundle = product.prdClass?.indexOf('BUNDLE') > 0;
    product.product_type = (product_type && product_type[0]) || (product_class && product_class[0]);
    product.carepacktype = carepacktype && carepacktype[0];

    if (Helpers.isGiftCard(product)) {
        product.sku = Helpers.getGiftCardParentSkuFromChildSku(product);
    }

    let productBadge = getValidProductBadge(product.prdClass, skutype && skutype[0]);
    if (productBadge) {
        product.productBadge = productBadge;
    }

    if (!disableMktDiff && mktdiff && mktdiff[0]) {
        product.mktdiff = mktdiff[0];
    }

    if (doc['is smb'] && typeof doc['is smb'][0] === 'string' && /yes/i.test(doc['is smb'][0])) {
        product.isSmb = true;
    }

    if (energyeffcomp && energyeffcomp[0] && energyeffcomp[0].length) {
        product.energystar = true;
    }

    if (isnew && typeof isnew[0] === 'string' && /yes/i.test(isnew[0])) {
        product.isNew = true;
    }

    // TODO: Bundle components still missing on product cards (ex: OMEN_15_25_Gaming_Kit)

    let pdpUrl = pdp_url && pdp_url[0];
    let basenameStartIndex = typeof pdpUrl === 'string' && pdpUrl.toLowerCase().indexOf(basename);

    // linkUrl should be a relative path if it has the same basename, and all pdpUrls from hawksearch are expected to contain the basename.
    product.productUrl =
        typeof basenameStartIndex === 'number' && basenameStartIndex >= 0
            ? pdpUrl.substring(basenameStartIndex)
            : pdpUrl;

    // Doesn't seem like ETR team can add the supplies link URL, so we're using the logic below instead to generate the suppliesLinkURL.
    if (iseolprinter && iseolprinter[0] === 'true') {
        product.eol = true;
        product.suppliesLinkUrl = product.productUrl
            ? `${basename}/mdp/ink-toner/${product.productUrl.split('/').pop()}`
            : undefined;

        let eolMessage = dte_eolprinter_message && dte_eolprinter_message[0];
        let eolPrinterRegex = /printer has been discontinued/i;
        let eolKspIndex = product.keyPoints && product.keyPoints.findIndex(ksp => ksp && eolPrinterRegex.test(ksp));

        // Rearrange KPS so the discontinued message is the first one.
        let missingEolKsp = !product.keyPoints || product.keyPoints.length === 0 || eolKspIndex < 0;
        product.keyPoints = missingEolKsp ? [eolMessage].concat(product.keyPoints || []) : product.keyPoints;
    }

    let pcDiscontinuedRegex = /this product has been discontinued/i;
    product.eol =
        product.eol ||
        (dte_eolpc_message &&
            overview &&
            typeof overview[0] === 'string' &&
            typeof dte_eolpc_message[0] === 'string' &&
            pcDiscontinuedRegex.test(overview[0]) &&
            pcDiscontinuedRegex.test(dte_eolpc_message[0]));
    if (includeRest && rest) {
        return {
            ...mapValues(rest),
            ...product,
        };
    } else {
        return product;
    }
};

export const transformProducts = (searchResults, basename, options) =>
    searchResults instanceof Array &&
    searchResults.reduce((allProducts, doc) => {
        const transformedProduct = transformProduct(doc && doc.Document, basename, options);
        if (transformedProduct && transformedProduct.name) {
            allProducts.push(transformedProduct);
        }
        return allProducts;
    }, []);

export const STORE_SUPPORT_TILES = {
    orientation: 'vertical',
    variation: 'noImage',
    className: 'suggestion-store-support-category-tiles',
    tiles: [
        {
            gridColumns: 3,
            gridColumnsMobile: 6,
            gridColumnsTablet: 6,
            title: 'MY ACCOUNT - ORDER TRACKING',
            // titleMobile: '',
            cta: {
                props: {
                    href: `${process.env.BASENAME}/MyAccountOrderStatusView`,
                },
            },
            supportPageProps: {
                title: 'My account - Order tracking',
                description: `To get the most up to date information regarding your order, please <a href="${
                    process.env.BASENAME + '/MyAccountOrderStatusView'
                }">Click here</a> to log into the My Orders page where you can check order status, review expected ship dates and get tracking detail for shipments.
                     Please note that some products do not physically ship such as digital software downloads and HP Care Packs. 
                     HP Care Packs are confirmed by email once HP has registered your service or emails you to confirm hardware details.`,
                cta: {
                    text: 'SEE MORE',
                    href: `${process.env.BASENAME}/MyAccountOrderStatusView`,
                },
            },
        },
        {
            gridColumns: 3,
            gridColumnsMobile: 6,
            gridColumnsTablet: 6,
            title: 'FAQS - SHIPPING INFORMATION',
            // titleMobile: '',
            cta: {
                props: {
                    href: `${process.env.BASENAME}/cv/shippingpolicy`,
                },
            },
            supportPageProps: {
                title: 'FAQS - Shipping Information',
                description: `The HP Store offers free standard delivery. Faster shipping service is also available for a small cost. Custom computers ship as soon as they are built. Items on backorder or pre-order ship out by the estimated ship date.`,
                cta: {
                    text: 'SEE MORE',
                    href: `${process.env.BASENAME}/cv/shippingpolicy`,
                },
            },
        },
        {
            gridColumns: 3,
            gridColumnsMobile: 6,
            gridColumnsTablet: 6,
            title: 'FAQS - RETURNS & REFUNDS',
            // titleMobile: '',
            cta: {
                props: {
                    href: `${process.env.BASENAME}/cv/returnsandexchanges`,
                },
            },
            supportPageProps: {
                title: 'FAQS - Returns & refunds',
                description: `We pride ourselves on great customer service and want to make sure you have the best possible purchase experience. In case you should need to return or exchange a product, you can initiate that request within our 30 day return window (for returnable products). Please use our easy online returns to make your request or get more information.`,
                cta: {
                    text: 'SEE MORE',
                    href: `${process.env.BASENAME}/cv/returnsandexchanges`,
                },
            },
        },
    ],
};

export const FAQ_AUTOCOMPLETE_TILES = {
    ...STORE_SUPPORT_TILES,
    tiles: cloneDeep(STORE_SUPPORT_TILES.tiles).map(tile => {
        let cloneTile = cloneDeep(tile);
        cloneTile.gridColumnsTablet = 3;
        return cloneTile;
    }),
};

export const getSupportTileProps = (
    supportTile,
    trackSearchAutoClick,
    sectionTitle,
    searchKey,
    getGtmActions,
    extraProps,
    isAutocomplete,
) => {
    try {
        const { title, url } = supportTile || {};
        const gtmAttributes = isAutocomplete && getSearchAutoClickGtmAttributes(title, searchKey, sectionTitle);
        return {
            title,
            url,
            ...(trackSearchAutoClick && { onClick: trackSearchAutoClick(sectionTitle, searchKey, title) }),
            gtmActions:
                gtmAttributes ||
                (typeof getGtmActions === 'function'
                    ? getGtmActions(title, sectionTitle)
                    : new Map([
                          [
                              'tileClick',
                              {
                                  gtmCategory: null,
                                  gtmId: null,
                                  gtmValue: null,
                              },
                          ],
                      ])),
            className: 'ac-tile',
            ...(extraProps || {}),
            ...(gtmAttributes || {}),
        };
    } catch (e) {}

    return null;
};

export const getFaqAutocompleteTiles = (
    supportTiles,
    trackSearchAutoClick,
    sectionTitle,
    searchKey,
    getGtmActions,
    extraProps,
    isAutocomplete,
) => {
    try {
        return {
            orientation: 'vertical',
            variation: 'noImage',
            className: 'suggestion-store-support-category-tiles',
            tiles: supportTiles.map(support => {
                return getSupportTileProps(
                    support,
                    trackSearchAutoClick,
                    sectionTitle,
                    searchKey,
                    getGtmActions,
                    extraProps,
                    isAutocomplete,
                );
            }),
            // tiles: supportTiles.map(support => {
            //     const { url, title} = support || {}
            //     return {
            //         gridColumns: 3,
            //         gridColumnsMobile: 6,
            //         gridColumnsTablet: 3,
            //         cta: {
            //             props: {
            //                 href: url
            //             }
            //         },
            //         title,
            //         ...trackSearchAutoClick && { onClick: trackSearchAutoClick(sectionTitle, searchKey, title) },
            //         gtmActions: typeof getGtmActions === 'function' ? getGtmActions(title, sectionTitle) : new Map([
            //             [
            //                 'tileClick',
            //                 {
            //                     gtmCategory: null,
            //                     gtmId: null,
            //                     gtmValue: null,
            //                 },
            //             ],
            //         ]),
            //         ...extraProps || {}
            //     }
            // })
        };
    } catch (e) {}

    return null;
};

export const COMBINED_FACETS = {
    // brands
    3211896: 'brands',
    dte_facet_printers_type: 'brands',
    facet_subbrand: 'brands',

    dte_facet_display_touch: 'touchscreen',
    dte_facet_aio_touch: 'touchscreen',
};

/**
 *
 * @param {*} param0
 *
 */
export const aggregateCustomFilterQuery = ({
    facetKey,
    facetValue,
    currentSelections,
    aliasFacetKeys,
    getAliasOnly,
}) => {
    let currentFacets = currentSelections && currentSelections[facetKey];

    // for(let facetValue of facetValues){
    if (!currentFacets) {
        currentSelections = currentSelections || {};
        currentFacets = currentSelections[facetKey] = { [facetValue]: true };
    } else if (currentFacets[facetValue]) {
        delete currentFacets[facetValue];
    } else {
        currentFacets[facetValue] = true;
    }

    let query = getCustomFilterQuery(Object.keys(currentFacets), aliasFacetKeys);

    return {
        query,
        currentSelections,
    };
};

export const getCustomFilterQuery = (facetValues, facetKeys) => {
    let facetValuesQuery = facetValues.join(' OR ');
    let completeQuery = facetKeys.reduce((allQuery, nextFacetKey, idx) => {
        allQuery += `${idx > 0 ? ' OR ' : ''}(${nextFacetKey}: ${facetValuesQuery})`;
        return allQuery;
    }, '');

    return `(${completeQuery})`;
};

export const CUSTOM_FILTER_QUERIES = {
    brands: (filter, currentSelections, getAliasOnly) => {
        let aliases = ['3211869', 'dte_facet_printers_type', 'facet_subbrand'];
        if (getAliasOnly) return aliases;

        return aggregateCustomFilterQuery({
            facetKey: 'brands',
            facetValue: filter,
            aliasFacetKeys: aliases,
            currentSelections,
        });
    },
    touchscreen: (filter, currentSelections, getAliasOnly) => {
        let aliases = ['dte_facet_display_touch', 'dte_facet_aio_touch'];

        if (getAliasOnly) return aliases;

        return aggregateCustomFilterQuery({
            facetKey: 'touchscreen',
            facetValue: filter,
            aliasFacetKeys: aliases,
            currentSelections,
        });
    },
};

export const createFacetValueObject = (Value, Label) => ({ Value, Label, customQuery: true });

/**
 *
 * @param {[Object]} facetValues - Array of objects containing the same fields fromthe facet values in Hawksearch (Facets[idx].Values)
 * @param {String} facetKey - String value of the facetKey to be combined with other facetKeys
 * @param {Object} aliasFacetKey - Object containing the new facet key Field used for queries, and the new Label.
 * @param {Object} facetTracker - Contains mapping of all combined facetKeys and facetValues
 *                                  Ex: {
 *                                          [aliasFacetKeyField]: {
 *                                              facetValues: {
 *                                                  [aliasFacetValueField]: {
 *                                                      Count: _,
 *                                                      Label: <aliasFacetValueLabel>
 *                                                  },
 *                                                  ...
 *                                                  'LaserJet': {
 *                                                      Label: 'LaserJet',
 *                                                      Count: __
 *                                                  }
 *                                              },
 *                                              facetKeys: {
 *                                                  [aliasFacetKEyField1]: 1,
 *                                                  [aliasFacetKeyField2]: 1,
 *                                                  ...
 *                                                  'facet_subbrand': 1
 *                                              },
 *                                              Name: [aliasFacetKeyName] //'Brands'
 *                                              position
 *                                            }
 *                                      }
 */
export const combineCommonFacetData = ({
    facetKey,
    aliasFacetKeyObject,
    aliasFacetValueObject,
    origFacetValueObject,
    facetTracker,
    position,
}) => {
    let { Count, Value: origFacetValue } = origFacetValueObject || {}; // contains original data from the facet value from hawksearch
    let { Name, Field, Tooltip, ExpandSelection } = aliasFacetKeyObject || {}; // contains the label and value of the alias facet key
    let { Value: FacetValue, customQuery } = aliasFacetValueObject || {}; // contains new key and label for the alias facet value

    // initialize the facetValue
    let facetTrackerObj = (facetTracker[Field] = facetTracker[Field] || {});
    let facetValuesObj = (facetTrackerObj.facetValues = facetTrackerObj.facetValues || {});
    let facetKeyObj = (facetTrackerObj.facetKeys = facetTrackerObj.facetKeys || {});
    let aliasFacetValueObj = (facetValuesObj[FacetValue] = facetValuesObj[FacetValue] || aliasFacetValueObject);

    // combine count to the facet value
    aliasFacetValueObj.Count =
        typeof aliasFacetValueObj.Count === 'number' && customQuery ? aliasFacetValueObj.Count + Count : Count;
    facetTrackerObj.Name = Name;

    facetTrackerObj.Tooltip = facetTrackerObj.Tooltip || Tooltip;

    facetTrackerObj.ExpandSelection = facetTrackerObj.ExpandSelection || ExpandSelection;

    // add the facetkey
    facetKeyObj[facetKey] = 1;

    // add position
    facetTrackerObj.position = typeof facetTrackerObj.position === 'number' ? facetTrackerObj.position : position;

    // track the distinct facet values so we can use it for queries.
    facetTrackerObj.facetValueFields = facetTrackerObj.facetValueFields || {};
    facetTrackerObj.facetValueFields[origFacetValue] = 1;

    // customQueryMap will be used to create queries when selecting a specific facet.

    /**
     * when custom clicks, "aliasfacetValue" will be passed, and use it to do the following:
     * 1. get the aliasFacetValue
     * 2. get the query
     * {
     *      [aliasFacetValue]: {
     *           [origFacetKey]: {
     *              [origFacetValue]: 1,
     *              ...
     *           }
     *      }
     *  }
     */
    facetTrackerObj.customQueryMap = facetTrackerObj.customQueryMap || {};
    facetTrackerObj.customQueryMap[FacetValue] = facetTrackerObj.customQueryMap[FacetValue] || {};
    facetTrackerObj.customQueryMap[FacetValue][facetKey] = facetTrackerObj.customQueryMap[FacetValue][facetKey] || {};
    facetTrackerObj.customQueryMap[FacetValue][facetKey][origFacetValue] = 1;
};

// TODO: May need to refactor logic later on.
export const FACET_VALUE_COMBINERS = {
    brands: (facetValues, facet, facetTracker, idx) => {
        let { Field: facetKey, Tooltip, ExpandSelection } = facet || {};
        for (let origFacetValueObject of facetValues) {
            let { Value } = origFacetValueObject || {};
            let aliasFacetValueObject = /^large\-format$/i.test(Value)
                ? createFacetValueObject('large-format', 'Large-Format')
                : /^laser\-?jet$/i.test(Value)
                  ? createFacetValueObject('laserjet', 'LaserJet')
                  : /^officejet$/i.test(Value)
                    ? createFacetValueObject('officejet', 'OfficeJet')
                    : /Buffalo Americas\, Inc/i.test(Value)
                      ? createFacetValueObject('Buffalo Americas, Inc', 'Buffalo Americas, Inc')
                      : /^deskjet$/i.test(Value)
                        ? createFacetValueObject('deskjet', 'DeskJet')
                        : /^officejet pro$/i.test(Value)
                          ? createFacetValueObject('officejet-pro', 'OfficeJet Pro')
                          : origFacetValueObject; // if the facet value shouldn't be combined with another facet value

            let { Value: newValue } = aliasFacetValueObject || {};
            if (newValue) {
                combineCommonFacetData({
                    facetKey,
                    facetTracker,
                    origFacetValueObject,
                    aliasFacetValueObject,
                    aliasFacetKeyObject: {
                        Tooltip: facetKey === 'facet_subbrand' ? Tooltip : null, // use tooltip from facet_subbrand. TODO: Confirm if this is what they want
                        ExpandSelection,
                        Name: 'BRANDS',
                        Field: 'brands',
                    },
                    position: idx,
                });
            }
        }
    },
    touchscreen: (facetValues, facet, facetTracker, idx) => {
        let { Field: facetKey, Tooltip, ExpandSelection } = facet || {};
        for (let origFacetValueObject of facetValues) {
            let { Value } = origFacetValueObject || {};
            let aliasFacetValueObject = /^other$/i.test(Value)
                ? createFacetValueObject('other', 'Other')
                : /^touch$/i.test(Value)
                  ? createFacetValueObject('touch', 'Touch')
                  : /^non\-touch$/i.test(Value)
                    ? createFacetValueObject('non-touch', 'Non-Touch')
                    : origFacetValueObject; // if the facet value shouldn't be combined with another facet value

            let { Value: newValue } = aliasFacetValueObject || {};
            if (newValue) {
                combineCommonFacetData({
                    facetKey,
                    facetTracker,
                    origFacetValueObject,
                    aliasFacetValueObject,
                    aliasFacetKeyObject: {
                        Tooltip,
                        ExpandSelection,
                        Name: 'TOUCHSCREEN',
                        Field: 'touchscreen',
                    },
                    position: idx,
                });
            }
        }
    },
    color: (facetValues, facet, facetTracker, idx) => {},
};
/**
 *
 * @param {*} facets
 * @returns Combined facets based on the HS response
 */
export const combineFacets = facets => {
    if (!facets || facets.length === 0) {
        return facets;
    }
    /**
     *  contains all facets with combine facetKeys and fields
     */
    let facetTracker = {};
    let aggregatedFacets = facets.reduce((combinedFacets, facet, idx) => {
        let { Values, Field, Name } = facet || {};
        let combinedFacetKey = COMBINED_FACETS[Field];
        let combinerFunc = FACET_VALUE_COMBINERS[combinedFacetKey]; // function that transforms the facet object.

        if (combinerFunc) combinerFunc(Values, facet, facetTracker, idx);
        else combinedFacets.push(facet);

        return combinedFacets;
    }, []);

    for (let aliasFacetKey of Object.keys(facetTracker)) {
        let { Name, position, facetValues, facetKeys, ...rest } = facetTracker[aliasFacetKey] || {};
        let newFacetObject = {
            Name,
            Field: aliasFacetKey,
            Values: Object.values(facetValues),
            combinedFacetKeys: Object.keys(facetKeys),
            ...rest,
        };
        aggregatedFacets.splice(typeof position === 'number' ? position : aggregatedFacets.length, 0, newFacetObject);
    }
    return aggregatedFacets;
};

/**
 *
 * @param {*} searchObj - search object constructed from URL
 * @returns search parameters including the "query" param used for searching combined filters
 */
export const transformProductSearchObject = searchObj => {
    let newSearch = cloneDeep(searchObj);
    let facetSelections = (newSearch && newSearch.FacetSelections) || {};

    let selectedCombinedFacets = {};

    let query = '';
    for (let facetKey of Object.keys(facetSelections)) {
        if (FACET_VALUE_COMBINERS[facetKey]) {
            selectedCombinedFacets[facetKey] = selectedCombinedFacets[facetKey] || {};
            facetSelections[facetKey].forEach(fv => {
                selectedCombinedFacets[facetKey][fv] = 1;
            });
        }

        let alias = COMBINED_FACETS[facetKey];
        let customFilterQuery = CUSTOM_FILTER_QUERIES[facetKey] || CUSTOM_FILTER_QUERIES[alias];
        if (customFilterQuery && facetSelections[facetKey].length) {
            let nextStr = getCustomFilterQuery(facetSelections[facetKey], customFilterQuery(null, null, true));
            query += nextStr && nextStr.length > 0 ? `${query.length > 0 ? ' AND ' : ''}${nextStr}` : '';
        }
    }

    if (query.length > 0) {
        newSearch.query = query + ' AND type: product';
    } else {
        newSearch.query = 'type: product';
    }

    return {
        search: newSearch,
        aggregatedFilterSelections: selectedCombinedFacets,
    };
};

/**
 *
 * @param {*} facetKey - facetKey included in query string
 * @returns true if it's an invalid facet key that should not be used in the FacetSelection parameter, false otherwise
 */
export const isNonFacetPersistentQueryString = facetKey => /^(pStoreID|jumpid|aoid)$/i.test(facetKey);

/**
 * Removes fields not intended for FacetSelections
 */
export const parseFacetSelection = facetSelection =>
    facetSelection &&
    Object.keys(facetSelection).reduce((validFacetSelection, facetKey) => {
        if (isNonFacetPersistentQueryString(facetKey)) return validFacetSelection;

        validFacetSelection[facetKey] = facetSelection[facetKey];
        return validFacetSelection;
    }, {});

export const parsePriceFilterSelection = (facetSelections, field) =>
    facetSelections &&
    Array.isArray(facetSelections[field]) &&
    facetSelections[field][0] &&
    facetSelections[field][0].split(',');

export const decodeFilterValue = fv => decodeURIComponent(fv).replace(/::/g, ',');
export const encodeFilterValue = fv => encodeURIComponent(fv).replace(/\,/g, '::');

// TODO: Need to find a better solution later. This is needed to account for invalid filter characters used in query strings (Ex: & and #)
export const getInitialSearchObjFromQueryString = options => {
    // some filters have '#' on them (Ex: ratings)
    let searchObj = {};
    let { disableQueryStringExclusions } = options || {};
    try {
        let isContent;
        const queryStrArr = (location.search + location.hash).replace(/^\?/, '').split('=');
        searchObj = queryStrArr.reduce(
            (searchParams, nextQs, idx) => {
                const qsKeyDelimiterIndex = nextQs && nextQs.lastIndexOf('&');
                const nextIndex = idx + 1;
                const nextSegment = queryStrArr[nextIndex];

                if (!nextSegment) return searchParams;

                // if the next segment is in the final index, get everything
                const nextSegmentIsFinalFacetValue = nextIndex === queryStrArr.length - 1;
                const qsValueDelimiterIndex = !nextSegmentIsFinalFacetValue && nextSegment.lastIndexOf('&');

                const qsValue = nextSegmentIsFinalFacetValue
                    ? nextSegment
                    : nextSegment.substring(0, qsValueDelimiterIndex);
                const qsKey = nextQs.substring(qsKeyDelimiterIndex + 1);

                // allQs[qsKey] = qsValue;

                // parse the query string key and value to determine how it should be used in the HS search
                if (qsKey === 'keyword') {
                    searchParams.Keyword = decodeFilterValue(qsValue);
                    return searchParams;
                }

                if (qsKey === 'sort') {
                    searchParams.SortBy = qsValue;
                    return searchParams;
                }

                if (qsKey === 'type' && qsValue === 'content') {
                    isContent = true;
                    return searchParams;
                }

                // ignored query string parameters - jumpid, aoid and pStoreID. also ignore pagination parameter since it shouldn't fetch pages > 1 on initial page load.
                if (isNonFacetPersistentQueryString(qsKey) || qsKey === 'pg') {
                    return searchParams;
                }

                searchParams.FacetSelections = searchParams.FacetSelections || {};
                searchParams.FacetSelections[qsKey] = qsValue.split(',').map(fv => decodeFilterValue(fv)); // react-hawksearch replaces commas with '::'
                return searchParams;
            },
            {
                query: 'type:product', // replaced with segment:blog if type=content on query string
            },
        );

        if (isContent) {
            searchObj.FacetSelections = searchObj.FacetSelections || {};
            searchObj.FacetSelections.type = ['Content'];
            searchObj.query && delete searchObj.query;
        }
    } catch (e) {}

    return searchObj;
};

const findLastIndexStr = (str, char) => (typeof str === 'string' ? findLastIndex(str.split(''), e => e === char) : -1);

export const getInitialSupportSearchObjFromQueryString = () => {
    // some filters have '#' on them (Ex: ratings)
    let searchObj = {};
    try {
        const queryStrArr = (location.search + location.hash).replace(/^\?/, '').split('=');
        searchObj = queryStrArr.reduce((searchParams, nextQs, idx) => {
            const qsKeyDelimiterIndex = nextQs && findLastIndexStr(nextQs, '&');
            const nextIndex = idx + 1;
            const nextSegment = queryStrArr[nextIndex];

            if (!nextSegment) return searchParams;

            // if the next segment is in the final index, get everything
            const nextSegmentIsFinalFacetValue = nextIndex === queryStrArr.length - 1;
            const qsValueDelimiterIndex = !nextSegmentIsFinalFacetValue && findLastIndexStr(nextSegment, '&');

            const qsValue = nextSegmentIsFinalFacetValue
                ? nextSegment
                : nextSegment.substring(0, qsValueDelimiterIndex);
            const qsKey = nextQs.substring(qsKeyDelimiterIndex + 1);

            // allQs[qsKey] = qsValue;

            // parse the query string key and value to determine how it should be used in the HS search
            if (qsKey === 'keyword') {
                searchParams.q = decodeFilterValue(qsValue);
                return searchParams;
            }

            if (qsKey === 'sort') {
                searchParams.sortBy = qsValue;
                let match = typeof searchParams.sortBy === 'string' && searchParams.sortBy.match(/(desc|asc)$/i);
                match = match && match[0];
                match && (searchParams.sortOrder = match);
                return searchParams;
            }

            return searchParams;
        }, {});
    } catch (e) {}
    return searchObj;
};

export const isBlogQuery = queryObj =>
    queryObj &&
    queryObj.FacetSelections &&
    queryObj.FacetSelections.type &&
    queryObj.FacetSelections.type[0] === 'Content';

export const getInitialSearchObject = url => {
    // some filters have '#' on them (Ex: ratings)
    let searchObj = {};
    try {
        let isBlog;
        const queryStrArr = url.replace(/^\?/, '').split('=');
        searchObj = queryStrArr.reduce(
            (searchParams, nextQs, idx) => {
                const qsKeyDelimiterIndex = nextQs && nextQs.lastIndexOf('&');
                const nextIndex = idx + 1;
                const nextSegment = queryStrArr[nextIndex];

                if (!nextSegment) return searchParams;

                // if the next segment is in the final index, get everything
                const nextSegmentIsFinalFacetValue = nextIndex === queryStrArr.length - 1;
                const qsValueDelimiterIndex = !nextSegmentIsFinalFacetValue && nextSegment.lastIndexOf('&');

                const qsValue = nextSegmentIsFinalFacetValue
                    ? nextSegment
                    : nextSegment.substring(0, qsValueDelimiterIndex);
                const qsKey = nextQs.substring(qsKeyDelimiterIndex + 1);

                // parse the query string key and value to determine how it should be used in the HS search
                if (qsKey === 'keyword') {
                    searchParams.Keyword = decodeFilterValue(qsValue);
                    return searchParams;
                }

                if (qsKey === 'sort') {
                    searchParams.SortBy = qsValue;
                    return searchParams;
                }

                if ((qsKey === 'type' && qsValue === 'content') || (qsKey === 'segment' && qsValue === 'blog')) {
                    isBlog = true;
                    return searchParams;
                }

                // ignored query string parameters - jumpid, aoid and pStoreID. also ignore pagination parameter since it shouldn't fetch pages > 1 on initial page load.
                if (isNonFacetPersistentQueryString(qsKey) || qsKey === 'pg') {
                    return searchParams;
                }

                searchParams.FacetSelections = searchParams.FacetSelections || {};
                searchParams.FacetSelections[qsKey] = qsValue.split(',').map(fv => decodeFilterValue(fv)); // react-hawksearch replaces commas with '::'
                return searchParams;
            },
            {
                query: 'type:product', // replaced with segment:blog if type=content on query string
            },
        );

        if (isBlog) {
            searchObj.FacetSelections = searchObj.FacetSelections || {};
            searchObj.FacetSelections.type = ['Content'];
            searchObj.query && delete searchObj.query;
        }
    } catch (e) {}

    return searchObj;
};

export const getValidBlogRelativePath = blogUrl => {
    const validBlogUrl = blogUrl && typeof blogUrl[0] === 'string';
    const basenameIndex = validBlogUrl && blogUrl[0].indexOf(process.env.BASENAME);
    return basenameIndex >= 0
        ? blogUrl[0].substring(basenameIndex)
        : validBlogUrl
          ? `${process.env.BASENAME}/${blogUrl[0]}`
          : '';
};

const getHsVisitExpiration = () => {
    var d = new Date(); // 4 hours

    d.setTime(d.getTime() + 4 * 60 * 60 * 1000);
    return d.toUTCString();
};

const createGuid = () => {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    // tslint:disable-next-line: no-bitwise
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    const uuid = s.join('');
    return uuid;
};

export const getTrackingData = () => {
    let VisitId = Helpers.getCookie('hawk_visit_id');
    let VisitorId = Helpers.getCookie('hawk_visitor_id');

    if (!VisitId) {
        VisitId = createGuid();
        Helpers.setCookie('hawk_visit_id', VisitId, null, getHsVisitExpiration()); // 4 hours, same as react-hawksearch logic
    }

    if (!VisitorId) {
        VisitorId = createGuid();
        Helpers.setCookie('hawk_visitor_id', VisitorId, 360); // 1 year, same as react-hawksearch logic
    }

    return {
        VisitId,
        VisitorId,
    };
};

export const getClientData = searchObj => {
    try {
        const { VisitorId, VisitId } = getTrackingData();
        return VisitorId || VisitId
            ? {
                  ...(searchObj.ClientData || {}),
                  ...(VisitId && { VisitId }),
                  ...(VisitorId && { VisitorId }),
                  UserAgent: navigator.userAgent,
              }
            : null;
    } catch (e) {}
};

const getAudienceIds = () => {
    try {
        return DY.ServerUtil.getUserAudiences().join(',');
    } catch (e) {}
};

export const getInitialSearchObjWithTrackingData = state => {
    const searchObj = getInitialSearchObjFromQueryString();
    const { router, userData } = state || {};
    const { location } = router || {};
    const { search } = location || {};

    const AOID = Helpers.getCaseInsensitiveQueryStringValue(search, 'aoid');
    const JumpID = Helpers.getCaseInsensitiveQueryStringValue(search, 'jumpid');
    let pStoreID;
    try {
        pStoreID = userData.profileData.personData.pStoreID;
        // TODO: Check if old logic is still needed. we probably can just remove this.
        // let profilePStoreId = userData.profileData.personData.pStoreID;
        // return profilePStoreId.toLowerCase() === location.query.pStoreID.toLowerCase() && profilePStoreId
    } catch (e) {}

    // add visitor tracking data
    const clientData = getClientData(searchObj);
    if (clientData) {
        searchObj.ClientData = clientData;
    }

    let Custom = {};
    const audienceID = getAudienceIds();
    if (AOID) Custom.AOID = AOID;
    if (JumpID) Custom.JumpID = JumpID;
    if (pStoreID) Custom.custom = pStoreID;
    if (audienceID) Custom.audience_id = audienceID;

    searchObj.ClientData = searchObj.ClientData || {};
    searchObj.ClientData.Custom = Custom;
    return searchObj;
};

export const getBlogListSearchParameters = (searchObj, customSearch) => {
    let { Keyword, PageNo, MaxPerPage } = searchObj || {};
    let ClientData = getClientData(searchObj);

    return {
        ...(ClientData ? { ClientData } : {}),
        Keyword,
        PageNo,
        MaxPerPage,
        PaginationSetCode: 'tech-takes',
        FieldOverride: ['product_name', 'thumbnailimage', 'pdp_url', 'blogdate'],
        FacetSelections: { type: ['blog_Content'] },
        ...(customSearch && typeof customSearch === 'object' ? customSearch : {}),
    };
};

const REQUEST_PARAM_TO_QUERY_STRING_MAP = {
    PageNo: 'pg',
    SortBy: 'sort',
    Keyword: 'keyword',
};

const QUERY_STRING_TO_REQUEST_PARAM_MAP = Object.keys(REQUEST_PARAM_TO_QUERY_STRING_MAP).reduce((allKeys, key) => {
    allKeys[REQUEST_PARAM_TO_QUERY_STRING_MAP[key]] = key;
    return allKeys;
}, {});

/**
 *
 * @param {*} searchObj - object used in json body for Hawksearch API call
 * Returns sorted query string. Used to update the url using history.push()
 */
export const getQueryStringFromSearchObj = searchObj => {
    let nonFilterQueryStrings = {};
    try {
        // get non-filter query strings (Ex: jumpid, pstoreid, aoid, etc)
        // TODO: Remove when ETR team removes invalid characters from filters such as # and &.
        // This is currently needed to correctly get the query string values when there are invalid characters on the url
        const { FacetSelections } = getInitialSearchObject(location.search);
        nonFilterQueryStrings =
            FacetSelections &&
            Object.keys(FacetSelections).reduce((allQs, qs) => {
                if (qs === 'type' && FacetSelections[qs] && FacetSlections[qs][0] === 'Content') {
                    allQs.type = 'content';
                } else if (
                    (isNonFacetPersistentQueryString(qs) || ['type', 'keyword'].includes(qs)) &&
                    FacetSelections[qs]
                ) {
                    allQs[qs] = FacetSelections[qs]; //.sort().join(',');
                }
                return allQs;
            }, {});
    } catch (e) {}

    let { SortBy, PageNo, FacetSelections, Keyword } = searchObj || {};

    // blogs should just have the query string type=content
    if (FacetSelections && FacetSelections.type && FacetSelections.type[0] === 'Content') {
        FacetSelections.type = 'content';
    }

    let queryObj = {
        ...(SortBy && { [REQUEST_PARAM_TO_QUERY_STRING_MAP['SortBy']]: SortBy }),
        ...(PageNo && { [REQUEST_PARAM_TO_QUERY_STRING_MAP['PageNo']]: PageNo }),
        ...(Keyword && { [REQUEST_PARAM_TO_QUERY_STRING_MAP['Keyword']]: Keyword }),
        ...(FacetSelections || {}),
        ...(nonFilterQueryStrings || {}),
    };

    const sortFunc = (a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
    };
    let sortedQueryString = Object.keys(queryObj)
        .sort(sortFunc)
        .reduce((allQs, qs) => {
            let searchParam = queryObj[qs];
            let qsSegment;

            if (Array.isArray(searchParam) && searchParam.length) {
                qsSegment = `${qs}=${searchParam.map(encodeFilterValue).sort(sortFunc).join(',')}`;
            }
            if (typeof searchParam === 'string') {
                qsSegment = `${qs}=${encodeFilterValue(searchParam)}`;
            }

            return `${allQs}${qsSegment ? (allQs.length > 0 ? '&' : '') + qsSegment : ''}`;
        }, '');

    return sortedQueryString;
};

/**
 *
 * @returns JSON parameter for Hawksearch API call
 * - used after initial page load to make queries when the url changes
 */
export const getSearchObjFromQueryString = () => {
    return getInitialSearchObject(window.location.href);
};

export const isSupportTabUrl = url => typeof url === 'string' && /(^|\&|\?|\/)type\=support($|\&)/i.test(url);

export const constructRelativeSrpPath = queryStr =>
    `${process.env.BASENAME}/sitesearch${queryStr ? (queryStr[0] === '?' ? queryStr : '?' + queryStr) : ''}`;

export const constructRelativeSrpUrlFromSearchObj = (searchObj, type) =>
    constructRelativeSrpPath(getQueryStringFromSearchObj(searchObj, type));

export const ssrValidQueryStrings = {
    keyword: 1,
    jumpId: 1,
    jumpID: 1,
    aoid: 1,
    type: 1,
    // debugging params
    _seo: 1,
    _nc: 1,
    __deviceType: 1,
};

export const getSrpSeoData = (defaultSeo, props) => {
    let { slugInfo, storeDomain } = props || {};
    let { components, seo } = slugInfo || {};
    let { srpSettings, hawksearchResults } = components || {};
    let { type, query } = hawksearchResults || {};
    let { currentSearch, Results } = (hawksearchResults && hawksearchResults[type]) || {};
    let { Keyword } = currentSearch || {};
    if (srpSettings && Array.isArray(srpSettings.searchKeywordSettings)) {
        let isCanonicalSetKeyword;
        let seoData = srpSettings.searchKeywordSettings.find(s => {
            if (!s) return false;
            let { canonicalSet, searchKeyword } = s || {};
            isCanonicalSetKeyword = Array.isArray(canonicalSet) && canonicalSet.includes(Keyword);
            return isCanonicalSetKeyword || searchKeyword === Keyword;
        });
        let { title, description, canonicalSet, searchKeyword } = seoData || {};
        let lowerCasedKeyword =
            isCanonicalSetKeyword && searchKeyword
                ? searchKeyword.toLowerCase()
                : typeof Keyword === 'string' && Keyword.toLowerCase();
        let keywordText = lowerCasedKeyword && startCase(lowerCasedKeyword);
        let { title: defaultTitle, description: defaultDescription } = seo || {}; // default title
        let srpTitle =
            title && title.length > 0
                ? title
                : keywordText
                  ? type === 'products'
                      ? `${keywordText} Product Results - Shop HP.com U.S.`
                      : type === 'content'
                        ? `${keywordText} Content Results - Shop HP.com U.S.`
                        : defaultTitle // default title
                  : defaultTitle; // default title

        let validSsrQueryStringsOnly =
            !query || Object.keys(Helpers.getSearch(query)).filter(k => !ssrValidQueryStrings[k]).length === 0;
        // if there's a keyword, only index if it's configured
        let indexedQueryString =
            validSsrQueryStringsOnly &&
            (!Keyword || isCanonicalSetKeyword || Keyword === searchKeyword) &&
            type !== 'support' &&
            Results &&
            Results.length > 9;
        // noindex if filter is included or if it's the support tab
        let robots = !indexedQueryString;
        let srpCanonical =
            (searchKeyword && searchKeyword === Keyword) || isCanonicalSetKeyword
                ? `https://${storeDomain}${process.env.BASENAME}/sitesearch?${
                      type === 'content' ? `type=content&` : ''
                  }keyword=${isCanonicalSetKeyword ? searchKeyword : Keyword}`
                : seo && seo.canonical;
        let srpDescription =
            description && description.length > 0
                ? description
                : lowerCasedKeyword
                  ? type === 'products'
                      ? `Shop HP.com U.S. for ${lowerCasedKeyword} in product results. Enjoy Low Prices, Free & Fast Delivery when you buy now online.`
                      : type === 'content'
                        ? `Explore HP.com U.S. for ${lowerCasedKeyword} in content results. Enjoy Low Prices, Free & Fast Delivery when you buy now online.`
                        : defaultDescription
                  : defaultDescription;
        return {
            title: srpTitle,
            robots,
            canonical: srpCanonical,
            description: srpDescription,
        };
    }

    return defaultSeo;
};

export const setDynamicProductData = (product, price, options) => {
    const { optimizeImage = true } = options || {};
    let { sku, bundleSkus, bundleCatEntryIds, itemId } = product || {};
    let { plcode, facet_subbrand, items, stock, productId } = price || {};
    //when price is available replace placeholder image with images from HPServices
    product.images = price && price.fullImages ? Helpers.parseHpServicesImages(price, optimizeImage) : product.images;
    product.images = product.images && product.images.filter(e => e.src);

    // TODO: These were removed with the visid product tile changes
    // product.plcode = plcode;
    // product.facet_subbrand = facet_subbrand;
    // product.isOOS = stock === 0;

    product.productId = productId;
    if (!product.images || product.images.length === 0) delete product.images;

    if (Array.isArray(bundleCatEntryIds) && items) {
        product.bundleComponents = bundleCatEntryIds.reduce((allBundleSkus, bundleCatEntryId) => {
            let { fullImages, prodName, itemId, sku } = (items && items[bundleCatEntryId]) || {};
            if (fullImages && prodName && itemId) {
                let bundleImages = Helpers.parseHpServicesImages({ fullImages }, optimizeImage);
                allBundleSkus.push({
                    imageUrl: bundleImages && bundleImages[0] && bundleImages[0].src,
                    name: prodName,
                    sku,
                    catentryId: bundleCatEntryId,
                });
            }
            return allBundleSkus;
        }, []);
    }
    return product;
};

export const attachDynamicData = (products, prices) =>
    products instanceof Array &&
    products.map(product => setDynamicProductData(product, prices && prices[product?.sku]));

export const consructSupportUrl = (seoFriendlyName, productId) =>
    `https://support.hp.com/us-en/product/details/${seoFriendlyName}/${productId}`;

export const parseSupportData = data => {
    try {
        return data.data.matches.reduce((allSupportData, supportData) => {
            const { name, seoFriendlyName, productId } = supportData || {};

            if (name && seoFriendlyName && productId) {
                allSupportData.push({
                    title: name,
                    url: consructSupportUrl(seoFriendlyName, productId),
                });
            }

            return allSupportData;
        }, []);
    } catch (e) {
        return [];
    }
};

export const SUPPORT_RESULT_PER_PAGE = 24;

export const getInitialSupportResultsState = ({ resp, queryStr, queryObj }) => {
    const supportPages = parseSupportData(resp);
    return {
        supportPages,
        query: queryStr,
        currentSearch: queryObj,
    };
};

export const getSupportSrpSortParam = sortValue => {
    searchParams.sortBy = qsValue;
    try {
        let regex = /(desc|asc)$/i;
        let sortOrder = sortValue.match(regex);
        sortOrder = sortOrder && sortOrder[0];
        let sortBy = sortValue.replace(regex, '');
        return {
            sortBy,
            sortOrder,
        };
    } catch (e) {}
    return null;
};

export const getSupportSrpParamsFromQueryString = query => {
    const { keyword, sort } = query || {};
    let params = {
        resultLimit: SUPPORT_RESULT_PER_PAGE,
    };

    const { sortOrder, sortBy } = getSupportSrpSortParam(sort) || {};
    if (sortOrder) {
        params.sortOrder = sortOrder;
    }

    if (sortBy) {
        params.sortBy = sortBy;
    }

    if (keyword) {
        params.q = keyword;
    }

    return params;
};

export const NUM_SUPPORT_RESULTS_PER_PAGE = 24;

export const getSrpLinkData = (to, pStoreID, type) => {
    if (type === 'product') {
        return { to };
    }
    let validPstoreID = typeof pStoreID === 'string' && pStoreID.length > 0;

    try {
        return {
            to: validPstoreID ? Helpers.mergeQueryStrings(to, `?pStoreID=${pStoreID}`) : to,
            ...(validPstoreID ? { target: '_blank' } : {}),
        };
    } catch (e) {}

    return { to };
};

const AUTOCOMPLETE_RECENT_SEARCHES_LOCAL_STORAGE_KEY = 'autocompleteRecentSearches';
export const replaceRecentSearchesInLocalStorage = recentSearchArray => {
    try {
        window.localStorage.setItem(AUTOCOMPLETE_RECENT_SEARCHES_LOCAL_STORAGE_KEY, JSON.stringify(recentSearchArray));
    } catch (e) {}
};
export const getRecentSearches = () => {
    try {
        return JSON.parse(window.localStorage.getItem(AUTOCOMPLETE_RECENT_SEARCHES_LOCAL_STORAGE_KEY));
    } catch (e) {}
};

export const addRecentSearch = searchKey => {
    let currRecentSearches;

    if (!searchKey || (typeof searchKey === 'string' && searchKey.trim().length === 0)) {
        return;
    }

    try {
        currRecentSearches = getRecentSearches();
    } catch (e) {}

    try {
        if (!Array.isArray(currRecentSearches)) {
            return replaceRecentSearchesInLocalStorage([searchKey]);
        }

        let currSearchIndex = currRecentSearches.indexOf(searchKey);
        if (currSearchIndex > -1) {
            currRecentSearches.splice(currSearchIndex, 1);
        } else if (currRecentSearches.length > 4) {
            currRecentSearches = currRecentSearches.slice(0, 4);
        }

        currRecentSearches.unshift(searchKey);
        replaceRecentSearchesInLocalStorage(currRecentSearches);
    } catch (e) {}
};

export const getSrpTypeFromUrl = () => {
    try {
        let isSrp = /us-en\/shop\/sitesearch/i.test(window.location.pathname);
        return isSrp
            ? /type=content/i.test(window.location.search)
                ? 'content'
                : /type=support/i.test(window.location.search)
                  ? 'support'
                  : null
            : null;
    } catch (e) {}
};

export const getViewAllLink = (searchKey, redirectLocation, singleProductUrl, seeAllLinkKeyword) => {
    try {
        let srpType = getSrpTypeFromUrl();
        let searchKeyQs =
            typeof seeAllLinkKeyword === 'string' && seeAllLinkKeyword.length > 0
                ? seeAllLinkKeyword
                : typeof searchKey === 'string' && searchKey.length > 0
                  ? searchKey
                  : null;
        let keywordQs = searchKeyQs ? `keyword=${searchKeyQs}` : '';
        let validKeywordQs = keywordQs && keywordQs.length > 0;
        let fullQs = `${validKeywordQs ? keywordQs : ''}${srpType ? `${validKeywordQs ? '&' : ''}type=${srpType}` : ''}`;
        fullQs = fullQs && fullQs.length > 0 ? `?${fullQs}` : '';

        return redirectLocation && redirectLocation.length > 0
            ? redirectLocation
            : singleProductUrl || `${process.env.BASENAME}/sitesearch${fullQs}`;
    } catch (e) {}
};

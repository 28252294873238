import { useSelector } from 'react-redux';

import { Helpers } from '../core/src/helpers';
import { isDealActive } from '../product/components/deals';

/**
 * @typedef {Object} Deals
 */

/**
 * Returns any active deals assocciated with the provided products and returns the deal that expires the soonest
 * @param {*} poducts
 * @param {*} deals
 */
export const selectDeals = (poducts, deals) => {
    let expiresSoon;
    let minDate;
    const activeDeals = (poducts || []).reduce((r, prd) => {
        try {
            const catId = Helpers.getCatEntryId(prd);
            const deal = deals[catId] || {};
            const { activeDeal } = deal;
            if (!isDealActive(deal)) {
                return r;
            }
            const { endDate } = activeDeal;
            //find the deal that expires the soonest
            if (!minDate || new Date(endDate) < minDate) {
                minDate = new Date(endDate);
                expiresSoon = activeDeal;
            }
            r[catId] = activeDeal;
        } catch (e) {}

        return r;
    }, {});
    return { activeDeals, expiresSoon };
};

/**
 * Returns redux connected page functions
 * @name useDeals
 * @returns {Deals}
 */
export default function useDeals(products) {
    const deals = useSelector(state => state.deals);
    return selectDeals(products, deals);
}

import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useLocation } from 'react-router-dom';

import AddedToCartFlyout from './added-to-cart-flyout';
import useCartState from '../../../hooks/useCartState';
import EtrAPIClient from '../../../core/src/etr-wcs-api-client';
import { getCatEntryId } from '../../../hooks/useProductPrice';
import { addAnalyticsDataToPdpAccessoriesComponent } from '../../../pdp/analytics';
import { Helpers } from '../../../core';

const CrossSellModel = loadable(() => Helpers.retryFunc(() => import('../../../xsell/components/xsell-modal-v2')));

const NO_XSELL = 404;

const handleCrossSellClose =
    (closeCartFlyout, setXSellData, clearRecentlyAdded = () => {}) =>
    () => {
        closeCartFlyout();
        setXSellData(null);
        clearRecentlyAdded();
    };

export default ({ recentlyAddedCartItem, closeCartFlyout, updateCart, pageType, showPreview, isNewVersion }) => {
    const { cartInfo, clearRecentlyAdded } = useCartState();
    const { pathname } = useLocation();
    const [xSellData, setXSellData] = useState(null);
    const { addToCartFlyoutVisible, recentlyAdded, analyticsData } = cartInfo || {};
    const [cartItem] = recentlyAddedCartItem;
    const { product: recentlyAddedProduct = {} } = recentlyAdded || {};
    useEffect(() => {
        //if item recently added and xsell modal isn't already open
        if (addToCartFlyoutVisible && !xSellData) {
            const productId = getCatEntryId(recentlyAddedProduct);
            EtrAPIClient.component
                .getAccessories({ productId })
                .then(accessories => {
                    if (accessories) {
                        setXSellData({
                            accessories: addAnalyticsDataToPdpAccessoriesComponent(accessories),
                            productInitial: recentlyAddedProduct,
                        });
                    } else {
                        setXSellData(NO_XSELL);
                    }
                })
                .catch(() => {
                    setXSellData(NO_XSELL);
                });
        }
    }, [addToCartFlyoutVisible, recentlyAddedProduct.sku]);
    useEffect(() => {
        if (xSellData) {
            setXSellData(null);
        }
    }, [pathname]);

    if (!xSellData && (!cartItem || !addToCartFlyoutVisible)) {
        return null;
    }

    return (
        <>
            {xSellData === NO_XSELL && addToCartFlyoutVisible && (
                <AddedToCartFlyout
                    cartInfo={cartInfo}
                    recentlyAdded={recentlyAddedCartItem}
                    addToCartFlyoutVisible={addToCartFlyoutVisible}
                    isNewCartPreview={isNewVersion}
                    closeCartFlyout={handleCrossSellClose(closeCartFlyout, setXSellData, clearRecentlyAdded)}
                    updateCart={updateCart}
                    pageType={pageType}
                    showPreview={showPreview}
                />
            )}
            {xSellData && xSellData !== NO_XSELL && (
                <CrossSellModel
                    accessories={xSellData.accessories}
                    productInitial={xSellData.productInitial}
                    onModalClose={handleCrossSellClose(closeCartFlyout, setXSellData, clearRecentlyAdded)}
                    analyticsData={analyticsData}
                />
            )}
        </>
    );
};

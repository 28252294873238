import React from 'react';
import { Violator } from '@hpstellar/core';

import DealCallout from './deal-callout';

import './deal-violator.less';

export default ({ variation = 'primary', small = true, noViolator, ...dealProps }) => {
    if (noViolator) {
        return <DealCallout {...dealProps} />;
    }
    return (
        <Violator className={`deal-violator ${variation}`} small={small} variation="offers">
            <DealCallout {...dealProps} />
        </Violator>
    );
};

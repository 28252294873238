import React, { useEffect, useState } from 'react';
import { Helpers } from '../../../core/src/helpers';

export const isSubscription = Helpers.isSubscription;

const getSubscriptionPricePer = cartItem => {
    const { subFrequency } = cartItem;
    if (Helpers.isSubscription(cartItem) && subFrequency) {
        return Helpers.getSubscriptionPricePer(subFrequency);
    }
    return null;
};

export default ({ cartItem }) => {
    const pricePer = getSubscriptionPricePer(cartItem);
    if (!pricePer) {
        return null;
    }
    return <span>/{pricePer}</span>;
};

const onInputBlur = (handleQtyChange, item) => e => {
    const { value } = e.target;
    if (value * 1 !== item.qty * 1) {
        handleQtyChange(value, item);
    }
};

const updateQuantity = setQuantity => e => {
    setQuantity(e.target.value);
};

const onKeyDown = (handleQtyChange, item) => e => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        const { value } = e.target;
        if (value * 1 !== item.qty * 1) {
            handleQtyChange(value, item);
        }
    }
};

export const QuantityInput = ({ item, handleQtyChange, qtyMax = '1000' }) => {
    const { qty } = item;
    const [quantity, setQuantity] = useState(qty);
    useEffect(() => {
        if (qty !== quantity) {
            setQuantity(qty);
        }
    }, [qty]);
    return (
        <input
            onBlur={onInputBlur(handleQtyChange, item)}
            type="number"
            className="input-qty"
            value={quantity}
            aria-label="Update quantity"
            onKeyPress={onKeyDown(handleQtyChange, item)}
            onChange={updateQuantity(setQuantity)}
            max={qtyMax}
        />
    );
};

import { useDispatch, useSelector } from 'react-redux';

import {
    updatePageComponents,
    dispatchGraphQLResponse,
    dispatchAsyncComponents,
    fetchAsyncComponents,
    updateSEO,
} from '../page/page-actions';

import { trackCustomMetric } from '../metrics/track-params';

/**
 * @typedef {Object} PageFunctions
 * @property {function(): promise} updateComponent - Update a page component in redux
 */

/**
 * Returns redux connected page functions
 * @name usePage
 * @returns {PageFunctions}
 */
export default function usePage() {
    const dispatch = useDispatch();

    return {
        updateComponent: (component, mergeFunction) => dispatch(updatePageComponents(component, mergeFunction)),
        updateSeo: (seo, mergeFunction) => dispatch(updateSEO(seo, mergeFunction)),
        dispatchGraphQLResponse: (response, clearSlugInfo) =>
            dispatchGraphQLResponse(response, dispatch, clearSlugInfo),
        dispatchAsyncComponents: response => dispatchAsyncComponents(response, dispatch),
        trackCustomMetric: (eventName, params) => dispatch(trackCustomMetric(eventName, params)),
        fetchAsyncComponents: (slug, hash, options) => dispatch(fetchAsyncComponents(slug, hash, options)),
    };
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { location } = useSelector(state => state.router);
    return location.query;
};

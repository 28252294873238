import React, { useEffect } from 'react';
import axios from 'axios';

import withPageComponents from '../with-page-components';
import { MetricsObserver } from '../../../metrics/event-observer';
import useCartState from '../../../hooks/useCartState';
import { getCTOLink } from '../../../core/src/etr-api-client/cto';
import { Helpers } from '../../../core';

const resolveCartUrl = cartUrl => {
    //gets redirect location url
    return axios.get(cartUrl).then(response => {
        return response.data;
    });
};

const prefetchPage = (urls, rule = 'prefetch', as) => {
    try {
        if (HTMLScriptElement.supports && HTMLScriptElement.supports('speculationrules') && !as) {
            //remove existing speculation rules
            document.querySelectorAll('script[type="speculationrules"]').forEach(script => script.remove());
            const specScript = document.createElement('script');
            specScript.type = 'speculationrules';
            const specRules = {
                [rule]: [
                    {
                        source: 'list',
                        urls,
                    },
                ],
            };
            specScript.textContent = JSON.stringify(specRules);
            document.body.append(specScript);
        } else {
            //remove existing prefetch links
            document.querySelectorAll('link[data-prefetch]').forEach(link => link.remove());

            urls.forEach(href => {
                const linkElem = document.createElement('link');
                linkElem.dataset.prefetch = 'true';
                linkElem.rel = rule;
                linkElem.href = href;
                if (as) {
                    linkElem.as = as;
                }
                document.head.append(linkElem);
            });
        }
    } catch (e) {
        console.log(e);
    }
};

const useOnAddToCart = (cartUrl, enablePrePagePrefetch) => {
    useEffect(() => {
        if (!enablePrePagePrefetch) {
            return () => {};
        }
        const onAddToCart = event => {
            //prefetch the cart page
            if (crtUrl) {
                resolveCartUrl(crtUrl).then(cartUrl => {
                    prefetchPage([cartUrl], 'prefetch', 'document');
                });
            }
        };
        MetricsObserver.subscribe('e_addToCart', onAddToCart);
        return () => {
            MetricsObserver.unsubscribe('e_addToCart', onAddToCart);
        };
    }, [cartUrl, enablePrePagePrefetch]);
};

const vanityUrlMap = {};

/***
 * Performance optimzation function for prefetching likely next pages
 */
export default withPageComponents(
    ({ siteConfig, vanityUrl, productTab, productInitial, isClientGQLLoading }) => {
        const { enablePrePagePrefetch = true } = siteConfig;
        const { catentryId, product_type } = productInitial || {};
        useEffect(() => {
            const [dir, slug] = vanityUrl?.split('/') || [];
            const baseUrl = `/${dir}/${slug}`;
            //if the vanityUrl is already in the map, dont perform any prefetching
            if (!(baseUrl in vanityUrlMap) && enablePrePagePrefetch && !isClientGQLLoading) {
                vanityUrlMap[baseUrl] = true;

                if (vanityUrl?.indexOf('slp') > -1) {
                    prefetchSLPUrls(productTab);
                }
                //if ConfigureView prefetch accories attach API
                /*
                //doesn't work right now because we dont know how to get the attach url ahead of add to cart
                if (vanityUrl?.indexOf('configure') > -1) {
                    const accessoriesUrl = `/wcs/resources/store/10151/component/cto/accessoriesattach?langId=-1&storeId=10151&catalogId=10051&catEntryId=3074457345620773822&configCatentryId=3074457345620773827&responseFormat=json`;
                    prefetchPage([accessoriesUrl]);
                }*/
                //if productIntial productType is CTO, prefetch CTO page
                if (product_type === 'CTO' && catentryId) {
                    const ctoUrl = getCTOLink(catentryId, 1);
                    prefetchPage([ctoUrl], 'prefetch', 'document');
                }

                if (!('pdp' in vanityUrlMap)) {
                    //if on a listing page and a PDP hasn't been prefetched, prefetch first product page
                    const firstProduct = productTab?.productGroups?.find(pg => pg.active)?.productsList?.[0];
                    if (firstProduct) {
                        const pdpUrl = Helpers.getRelativePath(firstProduct.linkUrl);
                        prefetchPage([pdpUrl], 'prefetch', 'document');
                        vanityUrlMap['pdp'] = true;
                    }
                }
            }

            return () => {};
        }, [vanityUrl, catentryId, product_type, productTab, isClientGQLLoading]);

        return null;
    },
    { siteConfig: true, components: ['productTab', 'productInitial'], gqlClientLoadStatus: true },
);

//
const prefetchSLPUrls = productTab => {
    //loop through productGroups within productTab and construct API urls, ignoring the active productGroup
    const urls = productTab.productGroups.reduce((agg, { key, active }) => {
        if (!active) {
            agg.push(`/us-en/shop/app/api/web/product-group/${encodeURIComponent(key)}?merged=1`);
        }
        return agg;
    }, []);

    prefetchPage(urls);
};

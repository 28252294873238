export const ProductAPI = function (apiConfig, http, helpers) {
    let self = this;
    let path = `${process.env.BASENAME}/app/api/web/product`;
    let esPath = `${process.env.BASENAME}/app/api/es/product`;

    self.get = (sku, select, skipCache) => {
        let q = {};
        sku = encodeURIComponent(sku);
        select && (q.select = select);
        skipCache && (q._nc = 1);

        return http.get(`${path}/${sku}`, {
            params: q,
        });
    };

    self.related = (skus, attrPath, primarySku) => {
        //if no skus provided send na to server
        const encodedSkus = encodeURIComponent(skus.join(';')) || 'na';
        return http.get(`${path}/related/${encodedSkus}/${attrPath}${primarySku ? '/' + primarySku : ''}`);
    };

    self.recommended = category => {
        return http.get(`${path}/recommended/${category}`);
    };

    self.xsell = (sku, limit = 4, skipCache, source) => {
        let q = { limit, source };
        sku = encodeURIComponent(sku);
        skipCache && (q._nc = 1);

        return http.get(`${path}/${sku}/xsell`, {
            params: q,
        });
    };

    self.ugcCount = sku => {
        sku = encodeURIComponent(sku);

        return http.get(`${path}/${sku}/ugc`);
    };

    self.search = query => {
        return http.get(`${path}`, {
            params: query,
        });
    };

    self.variants = skus => {
        const skuIds = encodeURIComponent(skus.sort().join(';'));

        return http.get(`${path}/variants/${skuIds}`);
    };

    self.pdpData = (sku, query) => {
        sku = encodeURIComponent(sku);
        query = query ? '/' + encodeURIComponent(JSON.stringify(query)) : '';
        return http.get(`${path}/pdp/${sku}${query}`);
    };

    self.compare = skus => (skipCache, maxSpecs) => {
        if (!skus) {
            return Promise.reject('Invalid request');
        }

        let q = {
            sku: skus.join(','),
        };
        skipCache && (q._nc = 1);
        maxSpecs && (q.maxSpecs = maxSpecs);

        return http.get(`${path}/compare`, {
            params: q,
        });
    };

    self.compareV2 = ids => () => {
        if (!ids) {
            return Promise.reject('Invalid request');
        }
        return http.get(`${path}/compare-v2/${encodeURIComponent(ids.join(','))}`);
    };

    self.details = (sku, type, query) => {
        if (!sku || !type) {
            return Promise.reject('Invalid request');
        }
        query = query ? encodeURIComponent(JSON.stringify(query)) : '';

        if (Array.isArray(sku)) {
            const skuList = encodeURIComponent(sku.join(';'));
            return http.get(`${path}/details/list/${type}/${skuList}`).then(({ data }) => {
                //TODO: figure out why this returns a string instead of an array from cache on the server
                return typeof data === 'string' ? JSON.parse(data) : data;
            });
        }

        return http.get(`${path}/details/${sku}/${type}/${query}`).then(({ data }) => {
            //TODO: change this on the backend
            return Array.isArray(data) ? data[0] : data;
        });
    };

    //elastic search
    self.es = {
        search: query => {
            let path = `${esPath}/search`;
            //if query is to long for get request, hit the POST endpoint
            if (helpers.getUrlLength(path, query) >= 2000) {
                return http.post(path, query);
            } else {
                query = helpers.sortKeys(query);
                return http.get(path, {
                    params: query,
                });
            }
        },
        search_v2: query => {
            let path = `${esPath}/v2/search`;
            //if query is to long for get request, hit the POST endpoint
            if (helpers.getUrlLength(path, query) >= 2000) {
                return http.post(path, query);
            } else {
                query = helpers.sortKeys(query);
                return http.get(path, {
                    params: query,
                    paramsSerializer: helpers.paramsToString,
                });
            }
        },
        search_v3: query => {
            let path = `${esPath}/v3/search`;
            //if query is to long for get request, hit the POST endpoint
            if (helpers.getUrlLength(path, query) >= 2000) {
                return http.post(path, query);
            } else {
                query = helpers.sortKeys(query);
                //put page as part of path
                if (query.page) {
                    path = `${path}/${encodeURIComponent(query.page)}`;
                    delete query.page;
                }
                return http.get(path, {
                    params: query,
                    paramsSerializer: helpers.paramsToString,
                });
            }
        },
        productGroup: (pg, query) => {
            let path = `${esPath}/${encodeURIComponent(pg)}/search`;

            //temp hack for preview, this should be from page component
            //once slp starts use page component
            if (typeof document !== 'undefined' && document.location) {
                let search = helpers.getSearch(document.location.search);
                let { _preview } = search;
                if (_preview) {
                    query._preview = _preview;
                }
            }

            //if query is to long for get request, hit the POST endpoint
            if (helpers.getUrlLength(path, query) >= 2000) {
                return http.post(path, query);
            } else {
                query = helpers.sortKeys(query);
                return http.get(path, {
                    params: query,
                    paramsSerializer: helpers.paramsToString,
                });
            }
        },
    };
};

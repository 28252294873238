import React from 'react';

import { isSubscription } from './subscription-item';
import './cart-preview-badge.less';

const getBadgeText = cartItem => {
    const { subFrequency } = cartItem;
    if (isSubscription(cartItem) && subFrequency) {
        return `${subFrequency} Subscription`;
    }
};

export default ({ cartItem }) => {
    const badgeText = getBadgeText(cartItem);
    if (!badgeText) {
        return null;
    }
    return <div className="cart-preview-badge ">{badgeText}</div>;
};

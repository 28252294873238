export const PageAPI = function (apiConfig, http, helpers) {
    let self = this;
    let path = `${process.env.BASENAME}/app/api/web/page`;
    let elasticSearchPath = `${process.env.BASENAME}/app/api/es/page`;

    self.get = (slug, options = {}, httpConfig = {}) => {
        const { pageKey } = options;
        const apiPath = `${path}/` + (pageKey ? `${slug}/${pageKey}` : slug);

        //remove special options prop
        delete options.pageKey;
        return http.get(apiPath, {
            params: options,
            ...httpConfig,
        });
    };

    self.search = (query = {}) => {
        return http.get(`${path}/search`, {
            params: query,
        });
    };

    self.elasticSearch = (
        filters,
        post_filters,
        aggregates,
        aggregateFilters,
        sort,
        size,
        from,
        bucketSize,
        _source
    ) => {
        return http.post(`${elasticSearchPath}/search`, {
            filters,
            post_filters,
            aggregates,
            aggregateFilters,
            sort,
            size,
            from,
            bucketSize,
            _source,
        });
    };

    self.getPageComponent = (slug, component, options = {}) => {
        const encodedOptions = options ? `/${encodeURIComponent(JSON.stringify(options))}` : '';
        const apiPath = `${path}/page-component/${slug}/${component}${encodedOptions}`;
        return http.get(apiPath);
    };
};

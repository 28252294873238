import React from 'react';
import { MetricsElement } from 'react-metrics';
import '../css/quantity-picker.less';

class QuantityPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = { quantity: this.props.quantity <= this.props.quantityMax ? this.props.quantity : 1 };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { args, checkInitialQty } = this.props;
        const { quantity } = this.state;
        if (checkInitialQty && quantity !== 1 && this.props.onPick) this.props.onPick(quantity, ...args);
    }

    componentDidUpdate(prevProps) {
        const { updateQuantityFromProps, quantity } = this.props;
        const { quantity: lastQty } = prevProps;
        if (updateQuantityFromProps && quantity !== lastQty) {
            this.setState({ quantity });
        }
    }

    handleChange(ev) {
        const { args, trackQuantityChange } = this.props;
        const quantity = (ev.target.value || 1) * 1;
        this.setState({ quantity });
        if (this.props.onPick) this.props.onPick(quantity, ...args);

        try{
            trackQuantityChange && trackQuantityChange(quantity - this.state.quantity)
        }catch(e){}
    }

    render() {
        const { quantityMin, quantityOptions, addSelectGtmAttributes } = this.props;
        const { quantity } = this.state;
        let options = [];
        let metricsProps = {};

        if (this.props['data-metrics-event-name']) {
            metricsProps = {
                'data-metrics-event-name': this.props['data-metrics-event-name'],
                'data-metrics-event': this.props['data-metrics-event'],
                'data-metrics-link-id': this.props['data-metrics-link-id'],
            };
        }

        if (quantityOptions && quantityOptions.length > 0) {
            options = quantityOptions.map((n, idx) => (
                <option 
                    key={idx} 
                    value={n}  
                    {...typeof addSelectGtmAttributes === 'function' ? addSelectGtmAttributes(n, quantity) : {}}>
                    {n}
                </option>
            ));
        } else {
            for (let i = quantityMin; i <= this.props.quantityMax; ++i) {
                options.push(
                    <option 
                        key={i} 
                        value={i}
                        {...typeof addSelectGtmAttributes === 'function' ? addSelectGtmAttributes(i, quantity) : {}}
                    >
                        {i}
                    </option>
                );
            }
        }
        return (
            <MetricsElement>
                <select
                    disabled={!this.props.enabled}
                    onChange={this.handleChange}
                    value={quantity}
                    className={`quantity-picker ${this.props.className ? this.props.className : ''}`}
                    aria-label={`Quantity ${quantity}`}
                    onMouseLeave={e => e.stopPropagation()}
                    {...metricsProps}
                >
                    {options}
                </select>
            </MetricsElement>
        );
    }
}

QuantityPicker.defaultProps = {
    quantityMax: 6,
    quantityMin: 1,
    quantity: 1,
    args: [],
    checkInitialQty: true,
    updateQuantityFromProps: false,
};

export default QuantityPicker;

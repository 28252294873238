import { fetchSlugInfo } from '../../page/page-actions';
import { urlParser, slpSearch } from '../../sale-page/sale-page-filters';
import { doSearch } from '../../search-filter/search-filter-actions';
import { dispatchMenuState } from '../../left-menu/left-menu-actions';
import Main from './main';
import { Helpers } from '../../core/src/helpers';
import withPage from '../../page';

const loadData = async (urlParams, store, context) => {
    const { query } = context;
    const { dir, slug, filters } = urlParams;
    const filterObj = Helpers.parseFilters(filters);
    const { pg } = filterObj;
    const fullSlug = `${dir}/${slug}`;
    const options = pg ? { ...query, pageKey: pg } : query;

    // same as withPage's fetchSlugInfo
    const promise = await store.dispatch(fetchSlugInfo(fullSlug, options));
    const storeData = store.getState();
    const needRedirect = !!storeData.slugInfo.error || storeData.redirects.count !== 0;
    const { destination, status = 301 } = storeData.redirects;
    if (needRedirect)
        return Promise.resolve({ status, destination: destination || `${storeData.basename}/slp/weekly-deals` });

    //check if page has filter enabled, if so do initial search
    const { components = {}, templateKey } = storeData.slugInfo;
    const { ssrTest } = storeData.testFlags || {};
    const { giftGuideFilters } = ssrTest || {};
    const { productTab = {} } = components;
    const { productGroups = [], initialTab } = productTab;
    const currentTab = productGroups[initialTab] || {};
    const { filterDisplayType = 'disabled', categoryFacets } = currentTab.metaData || {};
    const giftGuideFiltersEnabled =
        templateKey === 'gift-guide' && (categoryFacets || []).length > 0 && (giftGuideFilters || {}).enabled;
    if (filterDisplayType !== 'disabled' || giftGuideFiltersEnabled) {
        const initialQuery = urlParser({ params: urlParams });
        const { disableAutoHide, autoHideType } = Helpers.getAutoHideComponent(storeData.slugInfo, currentTab);
        return Promise.all([
            ['toggle-expanded', 'expanded'].includes(filterDisplayType)
                ? store.dispatch(dispatchMenuState(true))
                : Promise.resolve(),
            store.dispatch(
                doSearch(
                    currentTab.key,
                    () =>
                        slpSearch(
                            initialQuery,
                            currentTab.key,
                            currentTab,
                            disableAutoHide,
                            autoHideType,
                            query && query._preview
                        ),
                    initialQuery,
                    undefined,
                    undefined,
                    1,
                    (currentTab.productsList || []).length
                )
            ),
        ]);
    }
    return promise;
};

export default {
    component: withPage(Main, { wrapperClassName: 'main-container' }),
    loadData,
};

import React from 'react';
import { NewCartProducts, OldCartProducts } from './cart-preview-products';
import { Helpers } from '../../../core/src/helpers';
import useDeals from '../../../hooks/useDeals';
import DealViolator from '../../../product/components/deals/deal-violator';

import '../../css/cart-preview.less';
import '../../css/new-cart-preview.less';

const DEFAULT_TRANSLATIONS = {
    dealsOfferMessage: 'Your offer expires in {timeLeft}',
};

export const OldCartContent = ({
    cartInfo,
    showPreview,
    handleQtyChange,
    changingQty,
    maxCartItems,
    translations = DEFAULT_TRANSLATIONS,
}) => {
    const { hMsg, crtUrl, labels, items } = cartInfo;
    const { activeDeals, expiresSoon } = useDeals(items);
    return (
        <div className="minicart_contents">
            <div className="minicart_title">
                <h3 dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(hMsg))}></h3>
                <a href={Helpers.decodeHtml(crtUrl)}>{`${labels.cartTxt} >`}</a>
            </div>
            <div className="mcprodcont">
                <OldCartProducts
                    cartInfo={cartInfo}
                    showPreview={showPreview}
                    handleQtyChange={handleQtyChange}
                    maxCartItems={maxCartItems}
                    activeDeals={activeDeals}
                />
            </div>
            <div className="mccarttotal">
                {expiresSoon && <DealViolator message={translations.dealsOfferMessage} activeDeal={expiresSoon} />}
                <div className="mcfreeshipping">
                    <span dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(cartInfo.fShMsg))}></span>
                </div>
                <div className="mcsubtotal">
                    <div className="mcpricelabel">
                        <span className="title">{labels.cartSubtotal}</span>
                        <span
                            className="tax"
                            dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(labels.cartTaxFee))}
                        ></span>
                    </div>
                    <div className="mctotalprice">{'$' + cartInfo.sTot}</div>
                </div>
            </div>
            <div className="mccta">
                <a
                    href={Helpers.decodeHtml(cartInfo.crtUrl)}
                    className="btn btn-primary"
                    dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(cartInfo.labels.cartView))}
                ></a>
            </div>

            <div className="shadowcartcontainer">&nbsp;</div>
            <div className={`minicart-overlay${changingQty ? ' show' : ''}`}></div>
        </div>
    );
};

/*new version, not in use, keep here in case need to revert in the future*/
export const NewCartContent = ({ cartInfo, showPreview, handleQtyChange, changingQty, maxCartItems }) => {
    const { crtUrl, labels, tQty, iCount } = cartInfo;

    return (
        <div className="minicart_contents">
            <div className="minicart_title">
                <h3>Your Cart</h3>
                <a href={Helpers.decodeHtml(crtUrl)}>{`View ${tQty} item${tQty > 1 ? 's' : ''}`}</a>
            </div>
            <div className="mcprodcont">
                {
                    <div className="headline">
                        <span className="qty">Qty</span>
                        <span className="total">Total</span>
                    </div>
                }
                <NewCartProducts
                    cartInfo={cartInfo}
                    showPreview={showPreview}
                    handleQtyChange={handleQtyChange}
                    maxCartItems={maxCartItems}
                />
            </div>
            {iCount > maxCartItems && (
                <div className="total-count">
                    <a href={Helpers.decodeHtml(cartInfo.crtUrl)}>{`View all items (${tQty})`}</a>
                </div>
            )}
            <div className="mccarttotal">
                <div className="mcsubtotal">
                    <div className="mcpricelabel">
                        <span className="title">{labels.cartSubtotal}</span>
                        <span className="tax">{'Before tax & fees'}</span>
                    </div>
                    <div className="mctotalprice">{'$' + cartInfo.sTot}</div>
                </div>
            </div>
            {<div className="fs-indicator">Free shipping!</div>}
            <div className="mccta">
                <a
                    href={Helpers.decodeHtml(cartInfo.crtUrl)}
                    className="btn btn-primary"
                    dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(cartInfo.labels.cartView))}
                ></a>
            </div>

            <div className="shadowcartcontainer">&nbsp;</div>
            <div className={`minicart-overlay${changingQty ? ' show' : ''}`}></div>
        </div>
    );
};

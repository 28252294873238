import React from 'react';
import { withError } from '../../../shared/components/error-boundary';
import { Helpers } from '../../../core/src/helpers';
import ImageWithPlaceholder from '../../../shared/components/image-with-placeholder';
import Link from '../../../shared/components/hyperlink';
import withPrices from '../../../product/components/product-price/with-product-price';

import '../../css/added-to-cart-flyout.less';
import '../../css/cart-preview.less';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

class AddedToCartFlyout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            beingHovered: false,
        };
        this.cartTimeout;
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        const { closeCartFlyout } = this.props;
        window.removeEventListener('click', this.handleOutsideClick, false);
        clearTimeout(this.cartTimeout);
        typeof closeCartFlyout === 'function' && closeCartFlyout();
    }

    componentDidUpdate() {
        const { showPreview, closeCartFlyout, addToCartFlyoutVisible } = this.props;
        const { beingHovered } = this.state;
        if (this.cartTimeout) {
            clearTimeout(this.cartTimeout);
        }
        if (showPreview && addToCartFlyoutVisible) {
            closeCartFlyout();
            if (this.cartTimeout) {
                clearTimeout(this.cartTimeout);
            }
        }
        if (addToCartFlyoutVisible && !beingHovered) {
            this.handleTimeout();
        }
        return;
    }

    handleTimeout = () => {
        const { closeCartFlyout } = this.props;
        this.cartTimeout = setTimeout(function () {
            closeCartFlyout();
        }, 3000);
    };

    handleOutsideClick = e => {
        const { closeCartFlyout, addToCartFlyoutVisible } = this.props;
        if (this.node && this.node.contains(e.target) && !addToCartFlyoutVisible) {
            return;
        } else if (this.node && !this.node.contains(e.target) && addToCartFlyoutVisible) {
            closeCartFlyout();
            if (this.cartTimeout) {
                clearTimeout(this.cartTimeout);
            }
        }
    };

    generatePriceProps(priceInfo) {
        const { regularPrice, salePrice } = priceInfo;
        return {
            price: { regularPrice, salePrice },
            spClassName: 'sale-price',
            showListPrice: regularPrice > salePrice,
            showSalePrice: true,
        };
    }

    changeHoverState = hovering => {
        this.setState({ beingHovered: hovering }, clearTimeout(this.cartTimeout));
    };

    handleQtyChange = cQty => {
        const { recentlyAdded } = this.props;
        const item = recentlyAdded[0];
        let _cItem = Object.assign({}, item);
        _cItem.amt.nAmt = Helpers.convertPriceToNumber(_cItem.amt.nAmt);
        this.props.updateCart(_cItem, cQty).then(() => {
            const { cartInfo } = this.props;
            const { tQty } = cartInfo;
            this.setState({ qty: tQty });
        });
    };

    closeClick = e => {
        const { closeCartFlyout } = this.props;
        e.preventDefault();
        closeCartFlyout();
    };

    render() {
        const { cartInfo, recentlyAdded = EMPTY_ARRAY, showPreview, addToCartFlyoutVisible } = this.props;
        const displayProd = recentlyAdded[0];
        if (!displayProd) {
            return null;
        }
        return (
            !showPreview &&
            addToCartFlyoutVisible && (
                <div className={'flyout-wrapper old-version'} ref={node => (this.node = node)}>
                    <div className="original-mini-cart-wrapper minicart-container recentlyAdded visible">
                        <div className="top_corner">&nbsp;</div>
                        <div className="minicart_contents">
                            <div className="minicart_title">
                                <h3>{cartInfo.labels.addMsg}</h3>
                                <a href={Helpers.decodeHtml(cartInfo.crtUrl)}>
                                    {cartInfo.labels.cartTxt}
                                    {' >'}
                                </a>
                            </div>
                            <div className="mcprodcont">
                                <div className="productrow recentlyAdded">
                                    <Link to={displayProd.pdpUrl} className="mcprodimg">
                                        <ImageWithPlaceholder src={Helpers.getCDNUrl('', displayProd.img, 300)} />
                                    </Link>
                                    <div className="mcprodname">
                                        <Link
                                            to={displayProd.pdpUrl}
                                            dangerouslySetInnerHTML={Helpers.createMarkup(
                                                Helpers.decodeHtml(displayProd.name)
                                            )}
                                        ></Link>
                                        <span>{displayProd.pNum}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="" className="hf_close_btn" onClick={this.closeClick}></a>
                    </div>
                </div>
            )
        );
    }
}

export default withError(withPrices(AddedToCartFlyout));
